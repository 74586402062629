import React from "react";
import { useFieldArray } from "react-hook-form";

function Foods({
  foodListRemove,
  control,
  register,
  setValue,
  getValues,
  errors,
  groupIndex,
  foodGroupIndex,
  setFoodsList,
}) {
  const { fields, append, remove, prepend, update } = useFieldArray({
    control,
    name: `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`,
  });
  return (
    <>
      {fields.map((item, foodIndex) => {
        return (
          <div className="food-item-row" key={foodIndex}>
            <div className="food-items">
              <h2>{item.name}</h2>
              <p>{item.servingSize}</p>
            </div>
            <a href="javascript:void(0)" className="delete-food-item">
              <i
                className="far fa-trash-alt"
                onClick={() => {
                  const groupOption = getValues(
                    `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`
                  );
                  const store = groupOption.filter(
                    (x, ind) => ind != foodIndex
                  );
                  console.log(groupOption);

                  setFoodsList(store);
                  setValue(
                    `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`,
                    store
                  );
                }}
              ></i>
            </a>
          </div>
        );
      })}
    </>
  );
}

export default Foods;
