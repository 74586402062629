import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart, Bar, Line, Scatter } from "react-chartjs-2";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Keyboard } from "swiper";
import moment from "moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  getServingEatenDataById,
  getUserVitalsDataById,
  getUserWeightDataById,
} from "./api";
import dietSetupApi from "../dietManagement/service/dietSetup";
function DietReport({ singleUserInfo, noOfWeek, bmi }) {
  const [servingEaten, SetServingEaten] = useState([]);
  const [weekIndex, setWeekIndex] = useState(0);
  const [weekVitalData, setWeekVitalData] = useState(null);

  const [dietSetup, setDietSetup] = useState(null);
  const location = useLocation();

  const getPregnancyWeekNDay = () => {
    if (singleUserInfo) {
      let isDueDateKnown = singleUserInfo["is_due_date_known"];
      if (isDueDateKnown && singleUserInfo["due_date"]) {
        let dueDate = moment(singleUserInfo["due_date"], "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
        let todayDate = moment().format("DD-MM-YYYY"),
          addTodayDate = moment(todayDate, "DD-MM-YYYY").add(280, "days");
        let weeks = moment(addTodayDate, "DD-MM-YYYY").diff(
          moment(dueDate, "DD-MM-YYYY"),
          "weeks"
        );
        let days = moment(addTodayDate).diff(
          moment(dueDate, "DD-MM-YYYY"),
          "days"
        );
        return {
          week: weeks.toString().length > 0 ? parseInt(weeks) : 0,
          days: days ? days : 0,
          day: weeks.toString().includes(".")
            ? parseInt(weeks.toString().split(".")[1][0])
            : 0,
          start: moment()
            .subtract(days - 1, "days")
            .format("DD-MM-YYYY"),
        };
      } else {
        let isPeriodDate = singleUserInfo["is_period_date"];
        if (isPeriodDate && singleUserInfo["is_period_date"]) {
          let periodDate = moment(
            singleUserInfo["period_date"],
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY");
          let days = moment().diff(moment(periodDate, "DD-MM-YYYY"), "days");
          let weeks = moment.duration(days, "days").asWeeks();
          return {
            week: weeks.toString().length > 0 ? parseInt(weeks) : 0,
            days: days ? days : 0,
            day: weeks.toString().includes(".")
              ? parseInt(weeks.toString().split(".")[1][0])
              : 0,
            start: moment()
              .subtract(days - 1, "days")
              .format("DD-MM-YYYY"),
          };
        } else {
          let coceptionDate = moment(
            singleUserInfo["coception_date"],
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY");
          let todaydate = moment().format("DD-MM-YYYY");
          let addTodayDate = moment(todaydate, "DD-MM-YYYY").add(14, "D");
          let weeks = moment(addTodayDate, "DD-MM-YYYY").diff(
            moment(coceptionDate, "DD-MM-YYYY"),
            "weeks"
          );

          let days = moment(addTodayDate, "DD-MM-YYYY").diff(
            moment(coceptionDate, "DD-MM-YYYY"),
            "days"
          );
          return {
            week: weeks.toString().length > 0 ? parseInt(weeks) : 0,
            days: days ? days : 0,
            day: weeks.toString().includes(".")
              ? parseInt(weeks.toString().split(".")[1][0])
              : 0,
            start: moment()
              .subtract(days - 1, "days")
              .format("DD-MM-YYYY"),
          };
        }
      }
    } else {
      return { week: 0, days: 0, day: 0, start: null };
    }
  };

  const storeEatenData = (element) => {
    let weeks = {};
    let weekDa = getPregnancyWeekNDay();
    let startDate = weekDa.start;
    let startMonth = moment(startDate, "DD-MM-YYYY").month();
    let endDate = moment(startDate, "DD-MM-YYYY")
      .add(weekDa.days, "days")
      .format("DD-MM-YYYY");
    for (let i = startMonth; i < startMonth + 10; i++) {
      let weekIndex = 1;
      weeks[weekIndex] = [];
      let weekStart = moment(startDate, "DD-MM-YYYY").format("DD-MM-YYYY");
      let weekEnd = moment(weekStart, "DD-MM-YYYY")
        .add(6, "days")
        .endOf("date")
        .format("DD-MM-YYYY");
      for (let j = 0; j < 7; j++) {
        if (j == 0) {
          weeks[weekIndex].push({
            date: moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY"),
            day: moment(weekStart, "DD-MM-YYYY").format("dddd").slice(0, 3),
            servingCount:
              servingEaten
                .find(
                  (x) =>
                    x["date"] ==
                    moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY")
                )
                ?.arr_Eaten.filter((x) => x.foodGroupId == element.id)
                ?.length || 0,
          });
        } else {
          weeks[weekIndex].push({
            date: moment(weekStart, "DD-MM-YYYY")
              .add(j, "days")
              .format("DD-MM-YYYY"),
            day: moment(weekStart, "DD-MM-YYYY")
              .add(j, "days")
              .format("dddd")
              .slice(0, 3),
            servingCount:
              servingEaten
                .find(
                  (x) =>
                    x["date"] ==
                    moment(weekStart, "DD-MM-YYYY")
                      .add(j, "days")
                      .format("DD-MM-YYYY")
                )
                ?.arr_Eaten.filter((x) => x.foodGroupId == element.id)
                ?.length || 0,
          });
          if (j == 6) {
            weekStart = moment(weekEnd, "DD-MM-YYYY")
              .add(1, "day")
              .format("DD-MM-YYYY");
            weekEnd = moment(weekStart, "DD-MM-YYYY")
              .add(6, "days")
              .endOf("date")
              .format("DD-MM-YYYY");

            if (
              moment(weekStart, "DD-MM-YYYY").isBetween(
                moment(startDate, "DD-MM-YYYY"),
                moment(endDate, "DD-MM-YYYY")
              )
            ) {
              weekIndex = weekIndex + 1;

              weeks[weekIndex] = [];
              j = -1;
            }
          }
        }
      }
    }
    return weeks;
  };
  const get = async () => {
    try {
      if (singleUserInfo.id) {
        const getDiet = await dietSetupApi.getDietById(singleUserInfo.id);
        console.log(getDiet);
        if (getDiet && getDiet.groups && Array.isArray(getDiet.groups)) {
          console.log(getDiet.groups);
          let a = getDiet.groups.find(
            (x) => x.name == "Weekly Groups"
          ).foodGroups;
          if (a && Array.isArray(a)) setDietSetup(a);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        if (singleUserInfo) {
          get();
          const servingEaten = await getServingEatenDataById(singleUserInfo.id);
          console.log(servingEaten);
          if (Array.isArray(servingEaten)) {
            SetServingEaten(servingEaten);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    if (
      servingEaten &&
      dietSetup &&
      Array.isArray(dietSetup) &&
      dietSetup.length
    ) {
      let aa = [];
      dietSetup.forEach((element) => {
        aa.push({ ...element, weeks: storeEatenData(element) });
      });
      setWeekVitalData(aa);
      console.log(aa);
    }
  }, [servingEaten, dietSetup]);

  return (
    <>
      {dietSetup && dietSetup.length ? (
        <>
          {Math.ceil(getPregnancyWeekNDay().days / 7) ?? 0 ? (
            <div className="card h-100">
              <div className="card-header">
                <h2>Diet Reports </h2>
              </div>
              <div className="card-body">
                <div className="row my-5">
                  <Swiper
                    style={{ width: "200px", textAlign: "center" }}
                    keyboard={{
                      enabled: true,
                    }}
                    initialSlide={
                      Math.ceil(getPregnancyWeekNDay().days / 7) ?? 0
                    }
                    modules={[Keyboard, Navigation]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={true}
                    onSlideChange={(x) => setWeekIndex(x.activeIndex)}
                    onSwiper={(swiper) => {}}
                  >
                    {Array.from(
                      {
                        length: Math.ceil(getPregnancyWeekNDay().days / 7) ?? 0,
                      },
                      (_, i) => (
                        <SwiperSlide key={i + 1}>
                          <h5>
                            <b>Week {i + 1}</b>
                          </h5>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>

                {weekVitalData &&
                Array.isArray(weekVitalData) &&
                weekVitalData.length ? (
                  <div className="row">
                    <h6 className="text-center">
                      {weekVitalData[0].weeks[weekIndex + 1]
                        ? weekVitalData[0].weeks[weekIndex + 1][0].date
                        : ""}{" "}
                      To{" "}
                      {weekVitalData[0].weeks[weekIndex + 1]
                        ? weekVitalData[0].weeks[weekIndex + 1][6].date
                        : ""}
                    </h6>
                    {weekVitalData.map((x, index) => (
                      <div className="col-md-6">
                        <div className="chart-card">
                          <h2>{x.name}</h2>
                          <Bar
                            data={{
                              labels:
                                Object.keys(x.weeks).length &&
                                x.weeks[weekIndex + 1]
                                  ? x.weeks[weekIndex + 1].map(
                                      (x) => x.day ?? 0
                                    )
                                  : [],
                              datasets: [
                                {
                                  label: "Users Gained ",
                                  data:
                                    Object.keys(x.weeks).length &&
                                    x.weeks[weekIndex + 1]
                                      ? x.weeks[weekIndex + 1].map(
                                          (x) => x.servingCount + index
                                        )
                                      : [],
                                  backgroundColor: "#D98669",
                                  borderColor: "#D98669",
                                  borderRadius: 8,
                                },
                              ],
                            }}
                            options={{
                              scales: {
                                y: {
                                  position: "right",
                                  max: 10,
                                  min: 0,
                                },
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div className="card h-100">
              <div className="card-header">
                <h2>Diet Reports</h2>
              </div>
              <div className="card-body p-0 text-center pt-5">
                <h3>No Data Found</h3>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="card h-100">
          <div className="card-header">
            <h2>Diet Reports</h2>
          </div>
          <div className="card-body p-0 text-center pt-5">
            <h3>No Diet Plan Set Yet</h3>
          </div>
        </div>
      )}
    </>
  );
}

export default DietReport;
