import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Loader from "react-js-loader";
import {
  create,
  deleteAll,
  deleteById,
  getAll,
  update,
} from "./exercises-service";
function AllExercises() {
  const [exercises, setExercises] = useState([]);
  const [startLoader, setStartLoader] = useState(true);
  useEffect(() => {
    getAllExercises();
  }, []);

  const getAllExercises = () => {
    setStartLoader(true);
    getAll()
      .then((x) => {
        if (Array.isArray(x)) {
          setExercises(x);
          setStartLoader(false);
        }
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
      });
  };
  return (
    <>
      <main id="main">
        <div className="page-title">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">Exercise Management</a>
              </li>
              <li className="breadcrumb-item active">All Exercises</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h2>All Exercises</h2>
                </div>
                <div className="card-body p-0">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Exercise</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Description</th>
                        {/* <th scope="col">Repetitions</th>
                        <th scope="col">User Type</th>
                        <th scope="col">Trimester</th>
                        <th scope="col">Exercise Type</th> */}
                        <th scope="col" className="text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    {/* <tbody className={exercises.length ? "":"text-center"}> */}
                    <tbody>
                      {exercises.length > 0 ? (
                        exercises.map((x) => (
                          <tr key={x.id}>
                            <td>
                              <div className="tdExerciseTitle">
                                {/* <img src={x.thumbUrl} /> */}
                                <ReactPlayer
                                  url={x.videoUrl}
                                  className="thumb"
                                />
                                <h2 className="ps-3"> {x.title}</h2>
                              </div>
                            </td>
                            <td>{x.time} min</td>
                            <td>
                              {x.description
                                ? x.description.substring(0, 50)
                                : ""}
                              {x.description.length > 50 ? "..." : ""}
                            </td>
                            {/* <td>{x.repetitions}</td>
                          <td>
                            <div className="tdTypeList">
                              {x.normalUser && <p>Normal</p>}
                              {x.diabetesUser && <p>Diabetes</p>}
                              {x.hypertensionUser && <p>Hypertension</p>}
                              {x.obeseUser && <p>Obese</p>}
                            </div>
                          </td>
                          <td>
                            <div className="tdTypeList">
                              {x.trimester1 && <p>trimester1</p>}
                              {x.trimester2 && <p>trimester2</p>}
                              {x.trimester3 && <p>trimester3</p>}
                              {x.cesarean && <p>cesarean</p>}
                              {x.vaginalBirth && <p>vaginalBirth</p>}
                            </div>
                          </td>
                          <td>
                            <div className="tdTypeList">
                              {x.warmUp && <p>Warm Up</p>}
                              {x.circuit1 && <p>Circuit1</p>}
                              {x.circuit2 && <p>Circuit2</p>}
                              {x.cooldown && <p>CoolDown</p>}
                            </div>
                          </td> */}
                            <td>
                              <div className="tdAction">
                                <Link
                                  to="/single-exercise"
                                  state={x}
                                  className="btn-round btn-view"
                                >
                                  <i className="fa-regular fa-eye"></i>
                                </Link>
                                <Link
                                  to="/add-exercise"
                                  state={x}
                                  className="btn-round btn-edit"
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4} className="p-5 text-center">
                            {startLoader ? (
                              <Loader
                                type="spinner-circle"
                                bgColor={"#D98669"}
                                color={"#D98669"}
                                size={100}
                              />
                            ) : (
                              <>No data</>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AllExercises;
