import React from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase-config";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
function Login() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const notify = (message, type) => {
    switch (type) {
      case 1:
        toast(message, {
          style: {
            border: "1px solid #badbcc",
            padding: "10px 15px",
            color: "#0f5132",
            backgroundColor: "#d1e7dd",
          },
        });
        break;
      case 2:
        toast.success(message, {
          style: {
            border: "1px solid #badbcc",
            padding: "10px 15px",
            color: "#0f5132",
            backgroundColor: "#d1e7dd",
          },
        });
        break;
      default:
        toast.error(message);
    }
  };
  const onSubmit = async (values) => {
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        window.location.reload(); 
        // navigate("/");
        localStorage.setItem("authUser", JSON.stringify(user));
        notify("Welcome", 1);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        notify("Username or Password Wrong", 0);
        console.log(errorCode, errorMessage);
      });
  };
  return (
    <div className="Login">
      <main>
        <div className="container">
          <section className="min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 login-section">
            <div className="container">
              <Toaster />
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <a
                      href="javascript:void(0)"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="assets/images/9moons_dark_logo.svg" alt="" />
                    </a>
                  </div>

                  <div className="py-4">
                    <h2>Sign In</h2>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          {...register("email", {
                            required: "Email Address is required",
                          })}
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                        />
                        <p className="text-danger">{errors.email?.message}</p>
                      </div>
                      <div className="col-12 mb-5">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          {...register("password", {
                            required: "Password Address is required",
                          })}
                          type="password"
                          name="password"
                          className="form-control"
                          id="password"
                        />
                        <p className="text-danger">
                          {errors.password?.message}
                        </p>
                      </div>

                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Sign In
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Login;
