import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import Select from "react-select";
import Groups from "../templateManagement/Groups";
import {
  getFileByName,
  getUserById,
  nineMoonsLogin,
  getFileByUrl,
  getTratis,
} from "./api";
import toast, { Toaster } from "react-hot-toast";
import dietSetupApi from "../dietManagement/service/dietSetup";
import templateApi from "../templateManagement/api";
function DietSetup() {
  const defaultValue = {
    name: "",
    ouncesPerGlass: "",
    totalGlass: "",
    groups: [
      {
        name: "Daily Groups",
        foodGroups: [],
      },
      {
        name: "Weekly Groups",
        foodGroups: [],
      },
    ],
  };
  const location = useLocation();
  const notify = (message) =>
    toast(message, {
      style: {
        border: "1px solid #badbcc",
        padding: "10px 15px",
        color: "#0f5132",
        backgroundColor: "#d1e7dd",
      },
    });
  const fruits = [
    { label: "Mango", value: "mg" },
    { label: "Guava", value: "gv" },
    { label: "Peach", value: "pc" },
    { label: "Apple", value: "ap" },
  ];
  const [singleUserInfo, setSingleUserInfo] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [fruitsList, setFruitsList] = useState(1);
  const [dietSetup, setDietSetup] = useState(null);
  const [makeTemplate, setMakeTemplate] = useState(0);
  const [groups, setGroups] = useState(1);
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    getValues,
    resetField,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });
  const getTemplateList = async () => {
    try {
      const data = await templateApi.getAll();
      console.log(data);
      if (data && Array.isArray(data)) {
        setTemplateList(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getTemplateList();
    get();
  }, []);
  useEffect(() => {
    reset(defaultValue);
  }, [makeTemplate]);
  const addTemplate = async (values) => {
    try {
      if (values.name) {
        const temp = { ...values };
        if (temp && typeof temp.name == "string" && temp.name.length > 0) {
          delete temp.id;
          await templateApi.create(values);
          notify("template has been added successfully.");
          resetField("name", "");
          getTemplateList();
          await get();
        }
      }
    } catch (err) {
      notify("template not add.");
    }
  };
  const add = async (values) => {
    try {
      if (dietSetup) {
        if (values.name) {
          addTemplate(values);
        }
        const temp = { ...values };
        delete temp.name;
        await dietSetupApi.update({
          ...temp,
          id: location.state.uid,
        });
        notify("dietSetup has been update successfully.");
        await get();
      } else {
        if (values.name) {
          addTemplate(values);
        }
        const temp = { ...values };
        delete temp.name;
        await dietSetupApi.create({
          ...temp,
          id: location.state.uid,
        });
        notify("dietSetup has been added successfully.");
        await get();
      }
    } catch (err) {
      if (dietSetup) notify("dietSetup not add.");
      else notify("dietSetup not update.");
    }
  };
  const get = async () => {
    try {
      if (location.state.uid) {
        const getDiet = await dietSetupApi.getDietById(location.state.uid);
        console.log(getDiet);
        if (getDiet && getDiet.groups && Array.isArray(getDiet.groups)) {
          setDietSetup(getDiet);
          reset(getDiet);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const { fields, append, remove, prepend, update } = useFieldArray({
    control,
    name: "groups",
  });

  return (
    <div className="card h-100">
      <div className="card-header">
        <h2>Diet Setup</h2>
      </div>
      <div className="card-body">
        <Toaster />
        <div className="row">
          <div className="col-12">
            <div className="section-check">
              <div className="text-center">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    defaultChecked={makeTemplate == 1 ? true : false}
                    name="radioDietTemplate"
                    id="radioMakeTemplate"
                    onChange={(e) => {
                      setMakeTemplate(1);
                    }}
                    value="1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radioMakeTemplate"
                  >
                    Do you want to make a template
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    defaultChecked={makeTemplate == 2 ? true : false}
                    name="radioDietTemplate"
                    id="radioUseTemplate"
                    onChange={(e) => {
                      setMakeTemplate(2);
                    }}
                    value="2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radioUseTemplate"
                  >
                    Do you want to use existing template
                  </label>
                </div>
              </div>

              {makeTemplate == 1 && (
                <div className="row justify-content-center">
                  <div className="col-md-6 mt-4">
                    <label htmlFor="txtDietTemplateName" className="form-label">
                      Template Name
                    </label>
                    <input
                      {...register("name")}
                      type="text"
                      className="form-control"
                      name="name"
                      id="txtDietTemplateName"
                      placeholder="Enter Template Name"
                    />
                  </div>
                </div>
              )}
              {makeTemplate == 2 && (
                <div className="row justify-content-center">
                  <div className="col-md-6 mt-4">
                    <label
                      htmlFor="txtDietSelectTemplate"
                      className="form-label"
                    >
                      Select Existing Template
                    </label>
                    <select
                      className="form-select form-control"
                      name="txtDietSelectTemplate"
                      id="txtDietSelectTemplate"
                      onChange={(e) => {
                        if (
                          templateList &&
                          Array.isArray(templateList) &&
                          templateList.length
                        ) {
                          const idx = templateList.findIndex(
                            (x) => x.name == e.target.value
                          );
                          if (idx != -1)
                            reset({
                              name: "",
                              ouncesPerGlass: templateList[idx].ouncesPerGlass,
                              totalGlass: templateList[idx].totalGlass,
                              groups: templateList[idx].groups,
                            });
                          else reset(defaultValue);
                        }
                      }}
                    >
                      <option selected="">Select Diet Template</option>
                      {templateList &&
                        Array.isArray(templateList) &&
                        templateList.map((x, index) => {
                          return (
                            <option value={x.name} key={index}>
                              {x.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="user-dietform">
          <h2>User Diet Plan</h2>

          <div className="diet-radioGroup">
            <div className="form-radio-item">
              <input
                type="radio"
                name="radDietGroup"
                id="radDietDailyGroup"
                defaultChecked={groups == 1 ? true : false}
                onChange={(e) => {
                  setGroups(1);
                }}
                value="1"
              />
              <label htmlFor="radDietDailyGroup">
                <h2>Daily Groups</h2>
              </label>
            </div>
            <div className="form-radio-item">
              <input
                type="radio"
                name="radDietGroup"
                id="radDietWeeklyGroup"
                defaultChecked={groups == 2 ? true : false}
                onChange={(e) => {
                  setGroups(2);
                }}
                value="2"
              />
              <label htmlFor="radDietWeeklyGroup">
                <h2>Weekly Groups</h2>
              </label>
            </div>
          </div>
          <Groups
            {...{
              selectedGroupId: groups,
              control,
              register,
              errors,
              update,
              setValue,
              getValues,
            }}
          />
        </div>

        <div className="text-center mt-5">
          <button
            className="w-25 btn btn-primary"
            type="submit"
            onClick={() => {
              trigger();
              if (isValid) add({ ...getValues() });
              console.log(errors);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default DietSetup;
