import { app, db, storage ,functions} from "../firebase-config";
import {
  collection,
  addDoc,
  Timestamp,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { httpsCallable,httpsCallableFromURL, connectFunctionsEmulator } from "firebase/functions";
import { ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
export const getAllModels = async () => {
  const getAll1 = await getDocs(collection(db, "models"));
  let models = [];
  getAll1.forEach((doc) => {
    models.push({ ...doc.data(), id: doc.id });
  });

  return models;
};
export const getAllUsers = async (id) => {
  const callable = httpsCallable(functions,"getAllUser")
  const obs = callable({ data: "c" });
  return await obs;
};
export const getModelById = async (id) => {
  // const getAll1 = await getDocs(collection(db, "models"));
  let models = [];
  // getAll1.forEach((doc) => {
  //   models.push({ ...doc.data(), id: doc.id });
  // });
  const docRef = doc(db, "models", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};
const url =
  "https://firebasestorage.googleapis.com/v0/b/dev-ninemoons.appspot.com/o/";
export const createModels = async (value, id) => {
  try {
    const docRef = doc(db, "models", id);
    setDoc(docRef, value)
      .then(() => {})
      .catch((error) => {});
    // await setDoc(collection(db, "models","normalUser"), {
    //   ...value
    // });
  } catch (err) {
    alert(err);
  }
};
export const updateModels = async (key, value) => {
  try {
    return await updateDoc(doc(db, "models", key), {
      ...value,
    });
  } catch (err) {
    alert(err);
  }
};
export const deleteByIdModels = (key) => {
  return db.child(key).remove();
};
export const deleteAllModels = () => {
  return db.remove();
};
