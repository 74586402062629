import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";
function Navigation() {
  return (
    <div className="Navigation">
      <Header />
      <SideBar />
    </div>
  );
}

export default Navigation;
