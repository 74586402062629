/* Selector Helper */
const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

/* Event Listner Function */
const on = (type, el, listener, all = false) => {
  if (all) {
    select(el, all).forEach((e) => e.addEventListener(type, listener));
  } else {
    select(el, all).addEventListener(type, listener);
  }
};

/* On Scroll event listener */
const onscroll = (el, listener) => {
  el.addEventListener("scroll", listener);
};

/* Nav Sidebar toggle */
export const sideBarToggle = function (e) {
  select("body").classList.toggle("toggle-sidebar");
};

/* Header Fixed */
let selectHeader = select("#header");
if (selectHeader) {
  const headerScrolled = () => {
    if (window.scrollY > 100) {
      selectHeader.classList.add("header-scrolled");
    } else {
      selectHeader.classList.remove("header-scrolled");
    }
  };
  window.addEventListener("load", headerScrolled);
  onscroll(document, headerScrolled);
}

/* Back to top button */
// let backtotop = select(".back-to-top");
// if (backtotop) {
//   const toggleBacktotop = () => {
//     if (window.scrollY > 100) {
//       backtotop.classList.add("active");
//     } else {
//       backtotop.classList.remove("active");
//     }
//   };
//   window.addEventListener("load", toggleBacktotop);
//   onscroll(document, toggleBacktotop);
// }

// export const toggleBacktotop = () => {
//     if (window.scrollY > 100) {
//         backtotop.classList.add('active')
//     } else {
//         backtotop.classList.remove('active')
//     }
// }

/* Hide & Show Exercise Video Field */

export const exerciseVideoField = function (value) {
  let videoURL = document.getElementsByClassName(".video-url");;
  let videoFile = document.getElementsByClassName(".video-file");;
  
  if(value == 0) {
    videoURL.style.display = "none";
    videoFile.style.display = "none";
  }
  if (value == 1) {
    videoURL.style.display = "block";
    videoFile.style.display = "none";
  } else {
    videoURL.style.display = "none";
    videoFile.style.display = "block";
  }
};

/* Add Exercise Side Panel */
export const addExerciseSidePanel = function (e) {
  select("body").classList.toggle("show-exercise-sidebar");
  let bodyOverlay = select("#body-overlay");
  if (window.getComputedStyle(bodyOverlay).display != "none") {
    fadeOut(bodyOverlay, 500);
    bodyOverlay.style.display = "none";
  } else {
    fadeIn(bodyOverlay, 500);
    bodyOverlay.style.display = "block";
  }
};

// Only Number Accept
export const isNumberKey = function isNumberKey(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    return false;
  return true;
}

// FadeIn FadeOut Effect
const fadeIn = (element, duration) => {
  (function increment(value = 0) {
    element.style.opacity = String(value);
    if (element.style.opacity !== "1") {
      setTimeout(() => {
        increment(value + 0.1);
      }, duration / 10);
    }
  })();
};

const fadeOut = (element, duration) => {
  (function decrement() {
    (element.style.opacity -= 0.1) < 0
      ? (element.style.display = "none")
      : setTimeout(() => {
          decrement();
        }, duration / 10);
  })();
};
