import React from "react";
import { Link } from "react-router-dom";

function AllModels() {
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
            <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Model Management</a>
            </li>
            <li className="breadcrumb-item active">All Models</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h2>All Models</h2>
              </div>
              <div className="card-body p-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Model</th>
                      <th scope="col">User Type</th>
                      <th scope="col">Trimester</th>
                      <th scope="col">Week</th>
                      <th scope="col" className="text-end">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="tdTitle">Model 1</div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Normal</p>
                          <p>Diabetes</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Trimester 1</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Week - A</p>
                          <p>Week - B</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdAction">
                          <a href="edit-model.php" className="btn-round btn-edit">
                            <i className="fas fa-pencil-alt"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tdTitle">Model 2</div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Normal</p>
                          <p>Diabetes</p>
                          <p>Hypertension</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Trimester 1</p>
                          <p>Trimester 2</p>
                          <p>Trimester 3</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Week - A</p>
                          <p>Week - B</p>
                          <p>Week - C</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdAction">
                          <a href="edit-model.php" className="btn-round btn-edit">
                            <i className="fas fa-pencil-alt"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tdTitle">Model 3</div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Normal</p>
                          <p>Diabetes</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Trimester 1</p>
                          <p>Trimester 2</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdTypeList">
                          <p>Week - A</p>
                          <p>Week - B</p>
                          <p>Week - C</p>
                        </div>
                      </td>
                      <td>
                        <div className="tdAction">
                          <a href="edit-model.php" className="btn-round btn-edit">
                            <i className="fas fa-pencil-alt"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AllModels;
