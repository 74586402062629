const react_select_style = {
  control: (base) => ({
    ...base,
    borderColor: "#979797 !important",
    fontSize: "14px",
    padding: "1px 16px",
    borderRadius: "20px !important",
    boxShadow: "none",
  }),
};

export default {
  react_select_style,
};
