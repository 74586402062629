import React, { forwardRef, useImperativeHandle } from "react";
import { useFieldArray } from "react-hook-form";
import { addExerciseSidePanel, isNumberKey } from "../theme";
import { timeValidator} from "../timeValidator"
import ReactPlayer from 'react-player'
const NestedFieldArray = forwardRef(
  ({ nestIndex, control, register, errors, videoListRemove }) => {
    const { fields, remove, append } = useFieldArray({
      control,
      name: `exercises.${nestIndex}.videoList`,
    });
    useImperativeHandle(videoListRemove, () => ({
      removeVideo(id) {
        remove(id);
      },
      addVideo(data) {
        append(data);
      },
    }));
    return fields.map((item, k) => {
      return (
        <div key={nestIndex + k + 1}>
          <li className="list-group-item">
            <div className="row-exercise">
              <div className="exercise-image">
              <ReactPlayer url={item.video.videoUrl} width="50px" height="50px" />
              </div>
              <div className="exercise-name">
                <h2>{item.video.title}</h2>
                <p>{item.video.time} Min</p>
              </div>
            </div>
            <div className="exercise-meta">
              <div className="d-flex">
                <label className="form-label">REPS</label>
                <input
                  key={item.id}
                  {...register(`exercises.${nestIndex}.videoList.${k}.reps`, {
                    required: "Reps is required",
                  })}
                  type="text"
                  className="form-control"
                  name={`exercises.${nestIndex}.videoList.${k}.reps`}
                  onKeyDown={(e) => isNumberKey(e)}
                  maxLength="2"
                />
                <p className="text-danger">
                  {errors &&
                  errors.exercises &&
                  errors.exercises[nestIndex] &&
                  errors.exercises[nestIndex].videoList
                    ? errors.exercises[nestIndex].videoList[k]?.reps?.message
                    : ""}
                </p>
              </div>
              <div className="d-flex">
                <label className="form-label">ORDER</label>
                <input
                  key={item.id}
                  name={`exercises.${nestIndex}.videoList.${k}.order`}
                  {...register(`exercises.${nestIndex}.videoList.${k}.order`, {
                    pattern: {
                      value: /^([0-5]?[0-9])$/,
                      message: "Enter only number",
                    },
                    required: "Order is required",
                  })}
                  type="text"
                  className="form-control"
                  onKeyDown={(e) => isNumberKey(e)}
                  maxLength="2"
                />
                <p className="text-danger">
                  {errors &&
                  errors.exercises &&
                  errors.exercises[nestIndex] &&
                  errors.exercises[nestIndex].videoList
                    ? errors.exercises[nestIndex].videoList[k]?.order?.message
                    : ""}
                </p>
              </div>
              <a
                href="javascript:void(0)"
                className="delete-exercise"
                onClick={(e) => {
                  remove(k);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </a>
            </div>
          </li>
          {fields.length - 1 != k && (
            <li className="list-group-item list-rest-time">
              <div className="exercise-meta">
                <div className="d-flex">
                  <label className="form-label">Rest Time</label>
                  <input
                    {...register(
                      `exercises.${nestIndex}.videoList.${k}.resttime`,
                      {
                        required: "Rest Time is required",
                        pattern: {
                          value:
                            /^([0-5]?[0-9]):([0-5]?[0-9])$/,
                          message: "Rest Time is required",
                        },
                      }
                    )}
                    onKeyUp={timeValidator}
                    maxLength="5"
                    type="text"
                    placeholder="--:--"
                    className="form-control"
                    name={`exercises.${nestIndex}.videoList.${k}.resttime`}
                  />
                  <p className="text-danger">
                    {errors &&
                    errors.exercises &&
                    errors.exercises[nestIndex] &&
                    errors.exercises[nestIndex].videoList
                      ? errors.exercises[nestIndex].videoList[k]?.resttime
                          ?.message
                      : ""}
                  </p>
                </div>
              </div>
            </li>
          )}
        </div>
      );
    });
  }
);
export default NestedFieldArray;
