import { db } from "../../firebase-config";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";

const getAll = async () => {
  const getAll1 = await getDocs(collection(db, "foodGroups"));
  let foodGroups = [];
  getAll1.forEach((doc) => {
    foodGroups.push({ ...doc.data(), id: doc.id });
  });

  return foodGroups;
};
const create = async (value) => {
  try {
    await addDoc(collection(db, "foodGroups"), {
      ...value,
      completed: false,
    });
  } catch (err) {
    console.log(err);
  }
};
const update = async (value) => {
  try {
    let tempValue = { ...value };
    delete tempValue.id;
    await updateDoc(doc(db, "foodGroups", value.id), {
      ...tempValue,
    });
    return { ...tempValue, id: value.id };
  } catch (err) {
    console.log(err);
  }
};
const deleteById = (key) => {
  return db.child(key).remove();
};
const deleteAll = () => {
  return db.remove();
};

export default {
  getAll,
  create,
  update,
  deleteById,
  deleteAll,
};
