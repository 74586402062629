import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import Select from "react-select";
import FoodGroups from "./FoodGroups";
import toast, { Toaster } from "react-hot-toast";
import Groups from "./Groups";
import templateApi from "./api";
function TemplateManagement({ singleUserInfo }) {
  const location = useLocation();
  const defaultValue =
    location.state != null
      ? { ...location.state }
      : {
          name: "",
          ouncesPerGlass: "",
          totalGlass: "",
          groups: [
            {
              name: "Daily Groups",
              foodGroups: [],
            },
            {
              name: "Weekly Groups",
              foodGroups: [],
            },
          ],
        };
  const notify = (message) =>
    toast(message, {
      style: {
        border: "1px solid #badbcc",
        padding: "10px 15px",
        color: "#0f5132",
        backgroundColor: "#d1e7dd",
      },
    });
  const [fruitsList, setFruitsList] = useState(1);
  const [template, setTemplateSetup] = useState(1);
  const [makeTemplate, setMakeTemplate] = useState(1);
  const [groups, setGroups] = useState(1);
  const {
    trigger,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors, isValidating, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });
  useEffect(() => {
    get();
  }, []);
  useEffect(() => {
    reset(defaultValue);
  }, [makeTemplate]);
  const data = async () => {};
  const add = async (values) => {
    try {
      if (values.id) {
        await templateApi.update({
          ...values,
        });
        notify("template has been update successfully.");
        await get();
      } else {
        await templateApi.create(values);
        notify("template has been added successfully.");
        await get();
      }
    } catch (err) {
      if (template && Array.isArray(template)) notify("template not add.");
      else notify("template not update.");
    }
  };
  const get = async () => {
    try {
      const data = await templateApi.getAll();
      console.log(data);
      if (data && Array.isArray(data)) {
        console.log(data);
        setTemplateSetup(data);
      }
      // reset(data);
    } catch (err) {
      console.log(err);
    }
  };
  const { fields, append, remove, prepend, update } = useFieldArray({
    control,
    name: "groups",
  });

  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/all-templates">Template</Link>
            </li>
            <li className="breadcrumb-item active">Template Details</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="user-details">
          <div className="card h-100">
            <div className="card-header">
              <h2>Template Setup</h2>
            </div>
            <div className="card-body">
              <Toaster />
              <div className="row">
                <div className="col-12">
                  <div className="section-check">
                    {makeTemplate == 1 ? (
                      <div className="row justify-content-center">
                        <div className="col-md-6 mt-4">
                          <label
                            htmlFor="txtTemplateTemplateName"
                            className="form-label"
                          >
                            Template Name
                          </label>
                          <input
                            {...register("name", {
                              required: "Name Address is required",
                            })}
                            type="text"
                            className="form-control"
                            name="name"
                            id="txtTemplateTemplateName"
                            placeholder="Enter Template Name"
                          />
                          <p className="text-danger">{errors.name?.message}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="row justify-content-center">
                        <div className="col-md-6 mt-4">
                          <label
                            htmlFor="txtTemplateSelectTemplate"
                            className="form-label"
                          >
                            Select Existing Template
                          </label>
                          <select
                            {...register("name", {
                              required: "Name Address is required",
                            })}
                            className="form-select form-control"
                            name="txtTemplateSelectTemplate"
                            id="txtTemplateSelectTemplate"
                            onChange={(e) => {
                              if (
                                template &&
                                Array.isArray(template) &&
                                template.length
                              ) {
                                const idx = template.findIndex(
                                  (x) => x.name == e.target.value
                                );
                                if (idx != -1) reset(template[idx]);
                                else reset(defaultValue);
                              }
                            }}
                          >
                            <option selected="">
                              Select Template Template
                            </option>
                            {template &&
                              Array.isArray(template) &&
                              template.map((x, index) => {
                                return (
                                  <option value={x.name} key={index}>
                                    {x.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="user-dietform">
                <h2>User Template Plan</h2>

                <div className="diet-radioGroup">
                  <div className="form-radio-item">
                    <input
                      type="radio"
                      name="radTemplateGroup"
                      id="radTemplateDailyGroup"
                      defaultChecked={groups == 1 ? true : false}
                      onChange={(e) => {
                        setGroups(1);
                      }}
                      value="1"
                    />
                    <label htmlFor="radTemplateDailyGroup">
                      <h2>Daily Groups</h2>
                    </label>
                  </div>
                  <div className="form-radio-item">
                    <input
                      type="radio"
                      name="radTemplateGroup"
                      id="radTemplateWeeklyGroup"
                      defaultChecked={groups == 2 ? true : false}
                      onChange={(e) => {
                        setGroups(2);
                      }}
                      value="2"
                    />
                    <label htmlFor="radTemplateWeeklyGroup">
                      <h2>Weekly Groups</h2>
                    </label>
                  </div>
                </div>
                <Groups
                  {...{
                    selectedGroupId: groups,
                    control,
                    register,
                    errors,
                    update,
                    setValue,
                    getValues,
                  }}
                />
              </div>

              <div className="text-center mt-5">
                <button
                  className="w-25 btn btn-primary"
                  type="submit"
                  onClick={() => {
                    trigger();
                    if (isValid) add({ ...getValues() });
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default TemplateManagement;
