export const timeValidator = (e) => {
  if (
    parseInt(e.key) >= 0 &&
    parseInt(e.key) <= 9 &&
    e.target.value.length == 2
  ) {
    e.target.value += ":";
  } else if ((e.key != "0" && !parseInt(e.key)) || e.target.value.length > 5) {
    e.target.value = e.target.value.substring(0, e.target.value.length - 1);
  }
};
