import { db } from "../firebase-config";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
const getAll = async () => {
  const getAll1 = await getDocs(collection(db, "templates"));
  let templates = [];
  getAll1.forEach((doc) => {
    templates.push({ ...doc.data(), id: doc.id });
  });

  return templates;
};
const getTemplatesById = async (id) => {
  try {
    const docRef = doc(db, "templates", id);
    const docSnap = await getDoc(docRef);
    console.log(docSnap.data());
    return docSnap.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};
const create = async (value) => {
  const id = value.id;
  delete value.id;
  try {
    await addDoc(collection(db, "templates"), value);
  } catch (err) {
    console.log(err);
  }
};
const update = async (value) => {
  try {
    let tempValue = { ...value };
    delete tempValue.id;
    await updateDoc(doc(db, "templates", value.id), {
      ...tempValue,
    });
    return { ...tempValue, id: value.id };
  } catch (err) {
    console.log(err);
  }
};
const deleteById = (key) => {
  return db.child(key).remove();
};
const deleteAll = () => {
  return db.remove();
};

export default {
  getAll,
  create,
  update,
  deleteById,
  deleteAll,
  getTemplatesById,
};
