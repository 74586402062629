import React, { useEffect, useState, useRef } from "react";
import { set, useForm, useFieldArray, get } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { timesCalculate } from "./timesCalculate";
import { Link, useLocation } from "react-router-dom";
import { addExerciseSidePanel, isNumberKey } from "../theme";
import { getAll } from "../exercises/exercises-service";
import ReactPlayer from "react-player";
import Loader from "react-js-loader";
import {
  updateModels,
  createModels,
  getAllModels,
  getModelById,
} from "./model-service";
import FormArray from "./formArray";

function Diabetes() {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { exercises: [] },
  });
  const [exercises, setExercises] = useState([]);
  const [count, setCount] = useState(0);
  const dataFetchedRef = useRef(false);
  const videoListRemove = [useRef(0), useRef(0), useRef(0), useRef(0)];
  const [startLoader, setStartLoader] = useState(true);
  const [normalUser, setNormalUser] = useState({});

  const [searchExercise, setSearchExercise] = useState("");
  const [trimester, setTrimester] = useState(1);
  const [week, setWeek] = useState(1);
  const [day, setDay] = useState(1);
  const [exercise, setExercise] = useState(1);
  const [restDay, setRestday] = useState(2);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getAllExercises();
    getAllModel();
    // createModel();
  }, []);

  const getAllExercises = () => {
    getAll()
      .then((x) => {
        if (Array.isArray(x)) {
          setExercises(x);
        }
      })
      .catch((err) => console.log(err));
  };
  const getAllModel = () => {
    setStartLoader(true);
    getModelById("model2")
      .then((docSnap) => {
        setStartLoader(false);
        if (docSnap) {
          // setModelData(docSnap);
          console.log(docSnap);
          setNormalUser({ ...docSnap });
          setRestday(
            docSnap.trimesters[trimester - 1].weeks[week - 1].days[day - 1]
              .restDay
          );
          reset({
            exercises:
              docSnap.trimesters[trimester - 1].weeks[week - 1].days[day - 1]
                .exercises,
          });

          setCount(1);
        } else {
          notify("Model2 User Not Found");
        }
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
      });
  };
  const createModel = async (data) => {
    try {
      await createModels(data, "model2");
      notify("Model has been created successfully.");
    } catch (e) {
      notify("Some Error.");
    }
  };
  const updateModel = async (key, data) => {
    try {
      await updateModels(key, data);
      notify("Model has been updated successfully.");
    } catch (e) {
      notify("Some Error.");
    }
  };

  const notify = (message) =>
    toast(message, {
      style: {
        border: "1px solid #badbcc",
        padding: "10px 15px",
        color: "#0f5132",
        backgroundColor: "#d1e7dd",
      },
    });

  useEffect(() => {
    if (normalUser.trimesters) {
      setRestday(
        normalUser.trimesters[trimester - 1].weeks[week - 1].days[day - 1]
          .restDay
      );
      reset({
        exercises:
          normalUser.trimesters[trimester - 1].weeks[week - 1].days[day - 1]
            .exercises,
      });
    }
  }, [trimester, week, day]);
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/normal">Model Management</Link>
            </li>
            <li className="breadcrumb-item active">Model2</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="row">
          <div className="col-md-12">
            <Toaster />
            <div className="card">
              <div className="card-header">
                <h2>Model2</h2>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="section-check">
                        <label className="form-label d-block mb-3">
                          Select Trimester
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setTrimester(parseInt(e.target.value));
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioTrimester"
                            id="radioTrimesterOne"
                            defaultValue="1"
                            checked={trimester == 1 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioTrimesterOne"
                          >
                            Trimester 1
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setTrimester(parseInt(e.target.value));
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioTrimester"
                            id="radioTrimesterTwo"
                            defaultValue="2"
                            checked={trimester == 2 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioTrimesterTwo"
                          >
                            Trimester 2
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setTrimester(parseInt(e.target.value));
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioTrimester"
                            id="radioTrimesterThree"
                            defaultValue="3"
                            checked={trimester == 3 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioTrimesterThree"
                          >
                            Trimester 3
                          </label>
                        </div>
                        {/* <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setTrimester(parseInt(e.target.value));
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioTrimester"
                            id="radioCesarean"
                            defaultValue="4"
                            checked={trimester == 4 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioCesarean"
                          >
                            Cesarean
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setTrimester(parseInt(e.target.value));
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioTrimester"
                            id="radioVaginalBirth"
                            defaultValue="5"
                            checked={trimester == 5 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioVaginalBirth"
                          >
                            Vaginal Birth
                          </label>
                        </div> */}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="section-check">
                        <label className="form-label d-block mb-3">
                          Select Week
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setWeek(parseInt(e.target.value));
                            }}
                            checked={week == 1 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelWeek"
                            id="radioModelWeekA"
                            defaultValue="1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelWeekA"
                          >
                            Week - A
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setWeek(parseInt(e.target.value));
                            }}
                            checked={week == 2 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelWeek"
                            id="radioModelWeekB"
                            defaultValue="2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelWeekB"
                          >
                            Week - B
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setWeek(parseInt(e.target.value));
                            }}
                            checked={week == 3 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelWeek"
                            id="radioModelWeekC"
                            defaultValue="3"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelWeekC"
                          >
                            Week - C
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setWeek(parseInt(e.target.value));
                            }}
                            checked={week == 4 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelWeek"
                            id="radioModelWeekD"
                            defaultValue="4"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelWeekD"
                          >
                            Week - D
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="section-check">
                        <label className="form-label d-block mb-3">
                          Select Day
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 1 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayA"
                            defaultValue="1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayA"
                          >
                            Sunday
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 2 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayB"
                            defaultValue="2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayB"
                          >
                            Monday
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 3 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayC"
                            defaultValue="3"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayC"
                          >
                            Tuesday
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 4 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayD"
                            defaultValue="4"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayD"
                          >
                            Wednesday
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 5 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayE"
                            defaultValue="5"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayE"
                          >
                            Thursday
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 6 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayF"
                            defaultValue="6"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayF"
                          >
                            Friday
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              setDay(parseInt(e.target.value));
                            }}
                            checked={day == 7 ? true : false}
                            className="form-check-input"
                            type="radio"
                            name="radioModelDay"
                            id="radioModelDayG"
                            defaultValue="7"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelDayG"
                          >
                            Saturday
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="section-check">
                        <label className="form-label d-block mb-3">
                          Rest Day ?
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              normalUser.trimesters[trimester - 1].weeks[
                                week - 1
                              ].days[day - 1].restDay = 1;
                              setNormalUser({ ...normalUser });
                              setRestday(1);
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioModelRest"
                            id="radioModelRestYes"
                            checked={restDay == 1 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelRestYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            onChange={(e) => {
                              normalUser.trimesters[trimester - 1].weeks[
                                week - 1
                              ].days[day - 1].restDay = 2;
                              setNormalUser({ ...normalUser });
                              setRestday(2);
                            }}
                            className="form-check-input"
                            type="radio"
                            name="radioModelRest"
                            id="radioModelRestNo"
                            checked={restDay == 2 ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioModelRestNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {restDay == 2 && Object.keys(normalUser).length ? (
              <div className="row">
                <FormArray
                  {...{
                    setExercise,
                    videoListRemove,
                    control,
                    register,
                    defaultValues:
                      normalUser.trimesters[trimester - 1].weeks[week - 1].days[
                        day - 1
                      ],
                    getValues,
                    setValue,
                    errors,
                  }}
                />
              </div>
            ) : startLoader ? (
              <Loader
                type="spinner-circle"
                bgColor={"#D98669"}
                color={"#D98669"}
                size={100}
              />
            ) : (
              <div className="text-center">No Data</div>
            )}
          </div>
        </div>
      </section>

      <aside id="exercise-side-list">
        <div className="aside-header">
          <h2>Select Exercises</h2>
          <a
            href="javascript:void(0)"
            className="btnCloseExercise"
            onClick={(e) => {
              addExerciseSidePanel(e);
            }}
          >
            <i className="fas fa-times"></i>
          </a>
        </div>

        <div className="aside-search">
          <i className="fas fa-search"></i>
          <input
            type="text"
            className="form-control"
            name="txtExerciseSearch"
            placeholder="Search Exercise"
            onChange={(e) => setSearchExercise(e.target.value)}
          />
        </div>

        {Object.keys(normalUser).length && (
          <ul className="list-group list-group-flush list-exercise-add">
            {exercises
              .filter((x) => x.title.includes(searchExercise))
              .map((x, i) => (
                <li className="list-group-item" key={i}>
                  <div className="select-exercise-row">
                    <div className="d-flex">
                      <div className="exercise-image">
                        <ReactPlayer
                          url={x.videoUrl}
                          className="thumb"
                          width="50px"
                          height="50px"
                        />
                      </div>
                      <div className="exercise-name">
                        <h2>{x.title}</h2>
                        <p>{x.time} Min</p>
                      </div>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        key={x.id}
                        className="form-check-input"
                        type="checkbox"
                        name={"checkUserType" + i}
                        id={"checkNormalUser" + i}
                        onChange={(e) => {
                          let exe = getValues();
                          let videoList1 = exe.exercises[exercise].videoList;
                          if (e.target.checked == false) {
                            let videoIndex1 = videoList1.findIndex(
                              (y) => y.videoId == x.id
                            );
                            videoListRemove[exercise].current.removeVideo(
                              videoIndex1
                            );
                          } else {
                            let videoIndex = videoList1.findIndex(
                              (y) => y.videoId == x.id
                            );
                            if (videoIndex == -1) {
                              videoListRemove[exercise].current.addVideo({
                                reps: 15,
                                order: videoList1.length
                                  ? videoList1
                                      .map((x) => x.order)
                                      .reverse()[0] + 1
                                  : 1,
                                videoId: x.id,
                                resttime: "",
                                video: x,
                              });
                            }
                          }
                          // setNormalUser({ ...normalUser });
                        }}
                        checked={
                          getValues().exercises.length
                            ? getValues().exercises[exercise].videoList.find(
                                (y) => y.videoId == x.id
                              )
                              ? true
                              : false
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"checkNormalUser" + i}
                      ></label>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </aside>
      <div id="body-overlay" style={{ display: "none" }}></div>
      <div className="col-12 text-center mt-5 mb-3">
        <button
          className="btn btn-primary"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            trigger();
            if (isValid) {
              normalUser.trimesters[trimester - 1].weeks[week - 1].days[
                day - 1
              ].restDay = restDay;
              normalUser.trimesters[trimester - 1].weeks[week - 1].days[
                day - 1
              ].exercises = getValues().exercises;
              let time = "00:00:00";
              for (
                let i = 0;
                i <
                normalUser.trimesters[trimester - 1].weeks[week - 1].days[
                  day - 1
                ].exercises[exercise].videoList.length;
                i++
              ) {
                time = timesCalculate(
                  time,
                  "00:" +
                    normalUser.trimesters[trimester - 1].weeks[week - 1].days[
                      day - 1
                    ].exercises[exercise].videoList[i].video.time
                );
              }
              normalUser.trimesters[trimester - 1].weeks[week - 1].days[
                day - 1
              ].exercises[exercise].estimated_time = time;

              updateModel("model2", normalUser);
            }
          }}
        >
          Save Changes
        </button>
      </div>
    </main>
  );
}

export default Diabetes;
