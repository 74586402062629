import React from "react";
import { Link } from "react-router-dom";

function TimeManagement() {
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
            <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Time Management</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="row justify-content-center">
          <div className="col-xxl-6 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h2>Add Timings</h2>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="txtExerciseRestTime"
                        className="form-label"
                      >
                        Exercise Rest Time (In Minutes)
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        name="txtExerciseRestTime"
                        id="txtExerciseRestTime"
                        placeholder="Enter Exercise Rest Time"
                      />
                    </div>
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="txtCircuitRestTime"
                        className="form-label"
                      >
                        Circuit Rest Time (In Minutes)
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        name="txtCircuitRestTime"
                        id="txtCircuitRestTime"
                        placeholder="Enter Circuit Rest Time"
                      />
                    </div>
                    <div className="col-12 mb-4">
                      <label htmlFor="txtSetestTime" className="form-label">
                        Set Rest Time (In Minutes)
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        name="txtSetestTime"
                        id="txtSetestTime"
                        placeholder="Enter Set Rest Time"
                      />
                    </div>

                    <div className="col-12 text-center mt-5 mb-3">
                      <button className="btn btn-primary" type="submit">
                        Add Timings
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default TimeManagement;
