import React from "react";
import { Link, useLocation } from "react-router-dom";
function SideBar() {
  const location = useLocation();
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className={`nav-item ${location.pathname == "/" ? "active" : ""}`}>
          <Link className="nav-link" to="/">
            <i className="fa-solid fa-laptop"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li
          className={`nav-item ${
            location.pathname.match("exercis") == "exercis" ? "active" : ""
          }`}
        >
          <a className="nav-link" data-bs-target="#exercise-nav" href="javascript:void(0)">
            <i className="fas fa-running"></i>
            <span>Exercise Management</span>
          </a>
          <ul
            id="exercise-nav"
            className="nav-content"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link to="/all-exercises">
                <span
                  style={{
                    color: `${
                      location.pathname == "/all-exercises" ? "#D98669" : ""
                    }`,
                  }}
                >
                  All Exercises
                </span>
              </Link>
            </li>
            <li>
              <Link to="/add-exercise">
                <span
                  style={{
                    color: `${
                      location.pathname == "/add-exercise" ? "#D98669" : ""
                    }`,
                  }}
                >
                  Add New Exercise
                </span>
              </Link>
            </li>
          </ul>
        </li>

        <li
          className={`nav-item ${
            ["/model1", "/model2", "/model3", "/obese"].includes(
              location.pathname
            )
              ? "active"
              : ""
          }`}
        >
          <a className="nav-link" data-bs-target="#model-nav" href="javascript:void(0)">
            <i className="fas fa-layer-group"></i>
            <span>Model Management</span>
          </a>
          <ul
            id="model-nav"
            className="nav-content"
            data-bs-parent="#sidebar-nav"
          >
            {/* <li>
              <Link to="/all-models">
                <span
                  style={{
                    color: `${
                      location.pathname == "/all-models" ? "#D98669" : ""
                    }`,
                  }}
                >
                  All Models
                </span>
              </Link>
            </li> */}
            <li>
              <Link to="/model1">
                <span
                  style={{
                    color: `${location.pathname == "/model1" ? "#D98669" : ""}`,
                  }}
                >
                  Model 1
                </span>
              </Link>
            </li>
            <li>
              <Link to="/model2">
                <span
                  style={{
                    color: `${location.pathname == "/model2" ? "#D98669" : ""}`,
                  }}
                >
                  Model 2
                </span>
              </Link>
            </li>
            <li>
              <Link to="/model3">
                <span
                  style={{
                    color: `${location.pathname == "/model3" ? "#D98669" : ""}`,
                  }}
                >
                  Model 3
                </span>
              </Link>
            </li>
            {/* <li>
              <Link to="/obese">
                <span
                  style={{
                    color: `${location.pathname == "/obese" ? "#D98669" : ""}`,
                  }}
                >
                  Obese
                </span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/add-model">
                <span
                  style={{
                    color: `${
                      location.pathname == "/add-model" ? "#D98669" : ""
                    }`,
                  }}
                >
                  Add New Model
                </span>
              </Link>
            </li> */}
          </ul>
        </li>
        <li
          className={`nav-item ${
            location.pathname == "/all-users" ? "active" : ""
          }`}
        >
          <Link className="nav-link" to="/all-users">
            <i className="fas fa-user-friends"></i>
            <span
              style={{
                color: `${location.pathname == "/all-users" ? "#D98669" : ""}`,
              }}
            >
              Users
            </span>
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname.match("food") == "food" || location.pathname.match("template") == "template"? "active" : ""
          }`}
        >
          <a className="nav-link" data-bs-target="#exercise-nav" href="javascript:void(0)">
            <i className="fas fa-running"></i>
            <span>Diet Management</span>
          </a>
          <ul
            id="exercise-nav"
            className="nav-content"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link to="/food-groups">
                <span
                  style={{
                    color: `${
                      location.pathname == "/food-groups" ? "#D98669" : ""
                    }`,
                  }}
                >
                  Food Groups
                </span>
              </Link>
            </li>
            <li>
              <Link to="/food-items">
                <span
                  style={{
                    color: `${
                      location.pathname == "/food-items" ? "#D98669" : ""
                    }`,
                  }}
                >
                  Food Items
                </span>
              </Link>
            </li>
            <li>
              <Link to="/all-templates">
                <span
                  style={{
                    color: `${
                      location.pathname == "/all-templates" ? "#D98669" : ""
                    }`,
                  }}
                >
                  All Templates
                </span>
              </Link>
            </li>
            <li>
              <Link to="/add-template">
                <span
                  style={{
                    color: `${
                      location.pathname == "/add-template" ? "#D98669" : ""
                    }`,
                  }}
                >
                  Add Template
                </span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;
