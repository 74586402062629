import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { sideBarToggle } from "./theme.js";

function Header() {
  let navigate = useNavigate();
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <img src="assets/images/9moons_light_logo.svg" alt="" />
        </Link>
        <div
          className="toggle-sidebar-btn"
          onClick={(e) => {
            sideBarToggle(e);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown pe-4">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src="assets/images/profile-img.jpg"
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                Admin
                <span>Admin</span>
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <i className="fa-regular fa-user"></i>
                  <span>My Profile</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={(e) => {
                    localStorage.removeItem("authUser");
                    setTimeout(() => navigate("/login"), 0);
                  }}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
