import { app, db, storage } from "../firebase-config";
import {
  collection,
  addDoc,
  Timestamp,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import { v4 } from "uuid";
export const getAll = async () => {
  const getAll1 = await getDocs(collection(db, "exercises"));
  let exercises = [];
  getAll1.forEach((doc) => {
    exercises.push({ ...doc.data(), id: doc.id });
  });

  return exercises;
};
const url =
  "https://firebasestorage.googleapis.com/v0/b/dev-ninemoons.appspot.com/o/";
export const create = async (value) => {
  try {
    if (
      typeof value.thumbUrl == "object" &&
      Object.keys(value.thumbUrl).length > 0
    ) {
      let v41 = v4();
      const imageRef = ref(
        storage,
        `/exercises/${v41}${value.thumbUrl["0"].name.substring(
          value.thumbUrl["0"].name.lastIndexOf("."),
          value.thumbUrl["0"].name.length
        )}`
      );
      value.thumbName = `${v41}${value.thumbUrl["0"].name.substring(
        value.thumbUrl["0"].name.lastIndexOf("."),
        value.thumbUrl["0"].name.length
      )}`;
      const thumbUrl = await uploadBytes(imageRef, value.thumbUrl["0"]);
      if (thumbUrl.metadata.fullPath) {
        value.thumbUrl =
          url + thumbUrl.metadata.fullPath.replace("/", "%2F") + "?alt=media";
      } else {
        value.thumbUrl = null;
        value.thumbName = null;
      }
    }
    if (
      typeof value.videoUrl == "object" &&
      Object.keys(value.videoUrl).length > 0
    ) {
      let v41 = v4();
      const imageRef = ref(
        storage,
        `/exercises/${v41}${value.videoUrl["0"].name.substring(
          value.videoUrl["0"].name.lastIndexOf("."),
          value.videoUrl["0"].name.length
        )}`
      );
      value.videoName = `${v41}${value.videoUrl["0"].name.substring(
        value.videoUrl["0"].name.lastIndexOf("."),
        value.videoUrl["0"].name.length
      )}`;
      const videoFileUrl = await uploadBytes(imageRef, value.videoUrl["0"]);
      if (videoFileUrl.metadata.fullPath) {
        value.videoUrl =
          url +
          videoFileUrl.metadata.fullPath.replace("/", "%2F") +
          "?alt=media";
      } else {
        value.videoUrl = null;
        value.videoName = null;
      }
    } else {
      // if (
      //   value.videoUrl.includes("https://www.youtube.com/") &&
      //   !value.videoUrl.includes("/embed/")
      // ) {
      //   let videoUrl = value.videoUrl.split("https://www.youtube.com/");
      //   console.log(videoUrl);
      //   value.videoUrl = "https://www.youtube.com/embed/" + videoUrl[1];
      //   console.log(value.videoUrl);
      // }
    }
    await addDoc(collection(db, "exercises"), {
      ...value,
      completed: false,
    });
  } catch (err) {
    console.log(err);
  }
};
export const update = async (oldValue, value) => {
  try {
    if (
      typeof value.thumbUrl == "object" &&
      Object.keys(value.thumbUrl).length > 0
    ) {
      let v41 = v4();
      value.thumbName = `${v41}${value.thumbUrl["0"].name.substring(
        value.thumbUrl["0"].name.lastIndexOf("."),
        value.thumbUrl["0"].name.length
      )}`;
      const imageRef = ref(storage, `/exercises/${value.thumbName}`);

      const thumbUrl = await uploadBytes(imageRef, value.thumbUrl["0"]);

      if (thumbUrl.metadata.fullPath) {
        value.thumbUrl =
          url + thumbUrl.metadata.fullPath.replace("/", "%2F") + "?alt=media";

        // Delete the file
        if (oldValue.thumbName) {
          const desertRef = ref(storage, `exercises/${oldValue.thumbName}`);

          deleteObject(desertRef)
            .then(() => {
              console.log("old file succesfully removed");
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
            });
        }
      } else {
        value.thumbUrl = null;
        value.thumbName = null;
      }
    }

    if (
      typeof value.videoUrl == "object" &&
      Object.keys(value.videoUrl).length > 0
    ) {
      let v41 = v4();
      value.videoName = `${v41}${value.videoUrl["0"].name.substring(
        value.videoUrl["0"].name.lastIndexOf("."),
        value.videoUrl["0"].name.length
      )}`;
      const imageRef = ref(storage, `/exercises/${value.videoName}`);

      const videoFileUrl = await uploadBytes(imageRef, value.videoUrl["0"]);
      if (videoFileUrl.metadata.fullPath) {
        value.videoUrl =
          url +
          videoFileUrl.metadata.fullPath.replace("/", "%2F") +
          "?alt=media";
        if (oldValue.videoName) {
          const desertRef = ref(storage, `exercises/${oldValue.videoName}`);
          deleteObject(desertRef)
            .then(() => {
              console.log("old file succesfully removed");
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
            });
        }
      } else {
        value.videoUrl = null;
        value.videoName = null;
      }
    } else {
      // if (
      //   value.videoUrl.includes("https://www.youtube.com/") &&
      //   !value.videoUrl.includes("/embed/")
      // ) {
      //   let videoUrl = value.videoUrl.split("https://www.youtube.com/");
      //   value.videoUrl = "https://www.youtube.com/embed/" + videoUrl[1];
      // }
    }
    delete value.thumb;
    delete value.videoFile;
    let tempValue = { ...value };
    delete tempValue.id;
    await updateDoc(doc(db, "exercises", value.id), {
      ...tempValue,
    });
    return { ...tempValue, id: value.id };
  } catch (err) {
    console.log(err);
  }
};
export const deleteById = (key) => {
  return db.child(key).remove();
};
export const deleteAll = () => {
  return db.remove();
};
