import React, { forwardRef, useRef, useState, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import Foods from "./Foods";
import foodGroupApi from "../dietManagement/service/foodGroups";
import foodItemApi from "../dietManagement/service/foodItems";
import { addExerciseSidePanel, isNumberKey } from "../theme";
import Select from "react-select";
import style from "../style";
function FoodGroups({
  control,
  register,
  setValue,
  getValues,
  errors,
  groupIndex,
  selectedGroupId,
  videoListRemove,
  setExercise,
  setFruitsList,
}) {
  const [foods, setFoods] = useState([]);
  const { fields, append, remove, prepend, update } = useFieldArray({
    control,
    name: `groups.${groupIndex}.foodGroups`,
  });
  const [foodsList, setFoodsList] = useState(
    getValues(`groups.${groupIndex}.foodGroups`).map((x, inde) => {
      return {
        ...x,
        foods: x.foods?.map((y) => {
          return {
            label: y.name,
            value: y.id,
            data: y,
          };
        }),
      };
    })
  );

  useEffect(() => {
    (async () => {
      const food = await foodItemApi.getAll();
      if (food && Array.isArray(food)) {
        setFoods(
          food.map((x) => {
            return { label: x.name, value: x.id, data: x };
          })
        );
      }
    })();
  }, []);
  //   const foodListRemove = [useRef(0), useRef(0), useRef(0), useRef(0)];
  return (
    <>
      {fields.map((item, foodGroupIndex) => {
        return (
          <div className="col-md-6" key={foodGroupIndex}>
            <div className="food-item-card">
              <div className="food-item-header">
                <div className="d-flex align-items-center justify-content-between">
                  <h2>{item.name}</h2>
                  <a href="javascript:void(0)" className="delete-food-group">
                    <i
                      className="far fa-trash-alt"
                      onClick={() => {
                        const groupOption = getValues(
                          `groups.${groupIndex}.foodGroups`
                        );
                        const store = groupOption.filter(
                          (x, ind) => ind != foodGroupIndex
                        );
                        console.log(groupOption);
                        console.log(foodsList);

                        setFruitsList(store);
                        setValue(`groups.${groupIndex}.foodGroups`, store);
                      }}
                    ></i>
                  </a>
                </div>
                <div className="food-item-header-info">
                  <div className="row gx-2">
                    <div className="col">
                      <label htmlFor="txtGroupRecommended" className="form-label">
                        Recommended for you because
                      </label>
                      <input
                        {...register(
                          `groups.${groupIndex}.foodGroups.${foodGroupIndex}.recommended`,
                          {
                            required: "Recommended for you because is required",
                          }
                        )}
                        type="text"
                        className="form-control form-control-sm"
                        name={`groups.${groupIndex}.foodGroups.${foodGroupIndex}.recommended`}
                        id="txtGroupRecommended"
                      />
                      <p className="text-danger">
                        {errors.groups &&
                          errors.groups[groupIndex] &&
                          errors.groups[groupIndex].foodGroups &&
                          errors.groups[groupIndex].foodGroups[
                            foodGroupIndex
                          ] &&
                          errors.groups[groupIndex].foodGroups[foodGroupIndex]
                            .recommended?.message}
                      </p>
                    </div>
                    <div className="col-3">
                      <label htmlFor="txtGroupServing" className="form-label">
                        Servings
                      </label>
                      <input
                        {...register(
                          `groups.${groupIndex}.foodGroups.${foodGroupIndex}.serving`,
                          {
                            required: "servings because is required",
                            pattern: {
                              value: /^([0-5]?[0-9])$/,
                              message: "Enter only number",
                            },
                          }
                        )}
                        type="text"
                        className="form-control form-control-sm"
                        name={`groups.${groupIndex}.foodGroups.${foodGroupIndex}.serving`}
                        id="txtGroupRecommended"
                        onKeyDown={(e) => isNumberKey(e)}
                      />
                      <p className="text-danger">
                        {errors.groups &&
                          errors.groups[groupIndex] &&
                          errors.groups[groupIndex].foodGroups &&
                          errors.groups[groupIndex].foodGroups[
                            foodGroupIndex
                          ] &&
                          errors.groups[groupIndex].foodGroups[foodGroupIndex]
                            .serving?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="food-item-list">
                <Foods
                  {...{
                    setFoodsList,
                    getValues,
                    setValue,
                    control,
                    register,
                    errors,
                    update,
                    groupIndex,
                    foodGroupIndex,
                  }}
                />
              </div>
              <div className="food-item-action">
                <label htmlFor="txtSelectDietFood" className="form-label">
                  Select Food Items
                </label>
                <Select
                  styles={style.react_select_style}
                  {...register(
                    `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`
                  )}
                  name={`groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`}
                  options={foods}
                  isMulti={true}
                  value={getValues(
                    `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`
                  )?.map((x) => {
                    return { label: x.name, value: x.id, data: x };
                  })}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      const groupOption = getValues(
                        `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`
                      );
                      if (selected.length < groupOption.length) {
                        const selectedIds = selected.map((x) => x.value);
                        const store = groupOption.filter((x) =>
                          selectedIds.includes(x.id)
                        );
                        setValue(
                          `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`,
                          store
                        );
                      } else {
                        setValue(
                          `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`,
                          [...groupOption, selected[selected.length - 1].data]
                        );
                      }
                    } else {
                      setValue(
                        `groups.${groupIndex}.foodGroups.${foodGroupIndex}.foods`,
                        []
                      );
                    }
                    selected.length &&
                    selected.find((option) => option.value === "all")
                      ? setFoodsList(foods.slice(1))
                      : !true
                      ? setFoodsList((selected && selected.value) || null)
                      : setFoodsList(selected);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default FoodGroups;
