import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart, Bar, Line, Scatter } from "react-chartjs-2";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Keyboard } from "swiper";
import moment from "moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getUserVitalsDataById, getUserWeightDataById } from "./api";
function TrackReport({ singleUserInfo, noOfWeek, bmi }) {
  const [vitals, setVitals] = useState([]);
  const [weights, setWeights] = useState([]);
  const [weekIndex, setWeekIndex] = useState(0);
  const [weekWeightData, setWeekWeightData] = useState({});
  const [weekVitalData, setWeekVitalData] = useState({});
  const [heartRates, setHeartRates] = useState([]);
  const [respiration, setRespiration] = useState([]);
  const [idelWeights, setIdelWeights] = useState([]);
  const location = useLocation();

  const calHeight = (data) => {
    let heightUnit = data["height_unit"];

    var footVal = 0.0;
    var inchVal = 0.0;

    if (heightUnit == "ft/in") {
      let footInch = data["height"].split(".");

      footVal = footInch[0] ? parseFloat(footInch[0]) : 0.0;

      inchVal = footInch[1] ? parseFloat(footInch[1]) : 0.0;

      let footToMt = footVal / 3.281;
      let inchToMt = inchVal / 39.37;
      let totalMt = footToMt + inchToMt;
      return totalMt;
    } else {
      let heightInCm = data["height"] ? parseFloat(data["height"]) : 0.0;
      let heightInMt = heightInCm / 100;
      return heightInMt;
    }
  };
  const getWeightInLBS = (data) => {
    if (data) {
      if (data.weight && data.weight_unit && data.weight_unit == "lbs") {
        const poundOnce = data["weight"].split(".");
        let poundVal = 0.0;
        let ounceVal = 0.0;

        if (poundOnce[0]) {
          poundVal = parseFloat(poundOnce[0]) ?? 0.0;
        }
        if (poundOnce[1]) {
          ounceVal = parseFloat(poundOnce[1]) ?? 0.0;
        }
        let ounceToPound = ounceVal / 16;
        let totalPound = poundVal + ounceToPound;
        return totalPound;
      } else {
        let weightInKg = parseFloat(data["weight"]) ?? 0.0;
        let kgToPound = weightInKg * 2.205;
        return kgToPound;
      }
    } else {
      return 0.0;
    }
  };
  const calculateAllDaysIdealWeight = () => {
    let arrAllDaysIdealWeight = [];
    for (let i = 1; i <= 280; i++) {
      arrAllDaysIdealWeight.push({ num: i, lowWeight: 0.0, highWeight: 0.0 });
    }
    let weekNDay = getPregnancyWeekNDay();
    let week = weekNDay.week;
    let day = weekNDay.day;
    let currentNoOfDay = weekNDay.days;
    var arrTmpWeights = [];
    for (let i = 0; i < arrAllDaysIdealWeight.length; i++) {
      let item = arrAllDaysIdealWeight[i];
      let noOfDay = item.num;
      var weightOfDay = 0.0;
      if (noOfDay == currentNoOfDay) {
        let date = moment().format("DD-MM-YYYY");
        let strDate = date;
        let row = weights.findIndex(
          (x) => moment(x["date"], "DD-MM-YYYY").format("DD-MM-YYYY") == strDate
        );
        if (row >= 0) {
          let strWeight = weights[row]["weight"];
          weightOfDay = parseFloat(strWeight) ?? 0.0;
        } else {
          if (
            moment(location.state.metadata.creationTime).format("DD-MM-YYYY") ==
            strDate
          ) {
            weightOfDay = getWeightInLBS(singleUserInfo);
          } else {
            let arrOldData = weights
              .filter((x, index) => {
                return moment(x["date"], "DD-MM-YYYY").isBefore(
                  moment(date, "DD-MM-YYYY")
                );
              })
              .sort((a, b) => {
                if (
                  moment(a["date"], "DD-MM-YYYY").isBefore(
                    moment(b["date"], "DD-MM-YYYY")
                  )
                ) {
                  return 1;
                }
                return -1;
              });
            if (arrOldData.length) {
              let latestWeightData = arrOldData[0];
              let strWeight = latestWeightData["weight"];
              weightOfDay = parseFloat(strWeight) ?? 0.0;
            } else {
              weightOfDay = getWeightInLBS(singleUserInfo);
            }
          }
        }
      } else if (noOfDay < currentNoOfDay) {
        let subtractDays = currentNoOfDay - noOfDay;
        let date = moment().subtract(subtractDays, "days").format("DD-MM-YYYY");
        let strDate = date;
        let row = weights.findIndex((x) => x["date"] == strDate);
        if (row >= 0) {
          let strWeight = weights[row]["weight"];
          weightOfDay = parseFloat(strWeight) ?? 0.0;
        } else {
          if (
            moment(location.state.metadata.creationTime).format("DD-MM-YYYY") ==
            strDate
          ) {
            weightOfDay = getWeightInLBS(singleUserInfo);
          } else {
            let arrOldData = weights
              .filter((x, index) => {
                return moment(x["date"], "DD-MM-YYYY").isBefore(
                  moment(date, "DD-MM-YYYY")
                );
              })
              .sort((a, b) => {
                if (
                  moment(a["date"], "DD-MM-YYYY").isBefore(
                    moment(b["date"], "DD-MM-YYYY")
                  )
                ) {
                  return 1;
                }
                return -1;
              });
            if (arrOldData.length) {
              let latestWeightData = arrOldData[0];
              let strWeight = latestWeightData["weight"];
              weightOfDay = parseFloat(strWeight) ?? 0.0;
            } else {
              weightOfDay = getWeightInLBS(singleUserInfo);
            }
          }
        }
      } else {
        if (arrTmpWeights[i - 1]) {
          weightOfDay = arrTmpWeights[i - 1];
        }
      }
      arrTmpWeights.push(weightOfDay);
      let weightOfDayInKg = weightOfDay / 2.205;
      let bmi = weightOfDayInKg / Math.pow(calHeight(singleUserInfo), 2);
      var firstTrimesterLastLowDay = 0.0;
      var firstTrimesterLastHighDay = 0.0;
      if (noOfDay < 91) {
        let lowDay = (0.5 / 90) * parseFloat(noOfDay) + weightOfDayInKg;
        let highDay = (2.0 / 90) * parseFloat(noOfDay) + weightOfDayInKg;
        let lowDayInLbs = lowDay * 2.205;
        let highDayInLbs = highDay * 2.205;
        if (noOfDay == 90) {
          firstTrimesterLastLowDay = lowDayInLbs;
          firstTrimesterLastHighDay = highDayInLbs;
        }
        arrAllDaysIdealWeight[i].lowWeight = lowDayInLbs;
        arrAllDaysIdealWeight[i].highWeight = highDayInLbs;
      } else {
        let noOfDay2 = noOfDay - 90;
        let row = arrAllDaysIdealWeight.findIndex((x) => x.num == 90);
        if (row >= 0) {
          let item90 = arrAllDaysIdealWeight[row];
          firstTrimesterLastLowDay = item90.lowWeight / 2.205;
          firstTrimesterLastHighDay = item90.highWeight / 2.205;
        }
        var firstLowDayCal = 0.0;
        if (bmi < 18.5) {
          firstLowDayCal = parseFloat(noOfDay2) * parseFloat(12.24 / 190);
        } else if (bmi <= 24.9) {
          firstLowDayCal = parseFloat(noOfDay2) * parseFloat(11.02 / 190);
        } else if (bmi <= 29.9) {
          firstLowDayCal = parseFloat(noOfDay2) * parseFloat(6.12 / 190);
        } else if (bmi >= 30) {
          firstLowDayCal = parseFloat(noOfDay2) * parseFloat(4.9 / 190);
        }
        let finalLowDay = firstLowDayCal + firstTrimesterLastLowDay;
        var firstHighDayCal = 0.0;
        if (bmi < 18.5) {
          firstHighDayCal = parseFloat(noOfDay2) * parseFloat(15.92 / 190);
        } else if (bmi <= 24.9) {
          firstHighDayCal = parseFloat(noOfDay2) * parseFloat(14.08 / 190);
        } else if (bmi <= 29.9) {
          firstHighDayCal = parseFloat(noOfDay2) * parseFloat(8.57 / 190);
        } else if (bmi >= 30) {
          firstHighDayCal = parseFloat(noOfDay2) * parseFloat(7.35 / 190);
        }
        let finalHighDay = firstHighDayCal + firstTrimesterLastHighDay;
        let lowDayInLbs = finalLowDay * 2.205;
        let highDayInLbs = finalHighDay * 2.205;
        arrAllDaysIdealWeight[i].lowWeight = lowDayInLbs;
        arrAllDaysIdealWeight[i].highWeight = highDayInLbs;
      }
    }
    setIdelWeights(arrAllDaysIdealWeight);
  };

  const getPregnancyWeekNDay = () => {
    if (singleUserInfo) {
      let isDueDateKnown = singleUserInfo["is_due_date_known"];
      if (isDueDateKnown && singleUserInfo["due_date"]) {
        let dueDate = moment(singleUserInfo["due_date"], "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
        let todayDate = moment().format("DD-MM-YYYY"),
          addTodayDate = moment(todayDate, "DD-MM-YYYY").add(280, "days");
        let weeks = moment(addTodayDate, "DD-MM-YYYY").diff(
          moment(dueDate, "DD-MM-YYYY"),
          "weeks"
        );
        let days = moment(addTodayDate).diff(
          moment(dueDate, "DD-MM-YYYY"),
          "days"
        );
        return {
          week: weeks.toString().length > 0 ? parseInt(weeks) : 0,
          days: days ? days : 0,
          day: weeks.toString().includes(".")
            ? parseInt(weeks.toString().split(".")[1][0])
            : 0,
          start: moment()
            .subtract(days - 1, "days")
            .format("DD-MM-YYYY"),
        };
      } else {
        let isPeriodDate = singleUserInfo["is_period_date"];
        if (isPeriodDate && singleUserInfo["is_period_date"]) {
          let periodDate = moment(
            singleUserInfo["period_date"],
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY");
          let days = moment().diff(moment(periodDate, "DD-MM-YYYY"), "days");
          let weeks = moment.duration(days, "days").asWeeks();
          return {
            week: weeks.toString().length > 0 ? parseInt(weeks) : 0,
            days: days ? days : 0,
            day: weeks.toString().includes(".")
              ? parseInt(weeks.toString().split(".")[1][0])
              : 0,
            start: moment()
              .subtract(days - 1, "days")
              .format("DD-MM-YYYY"),
          };
        } else {
          let coceptionDate = moment(
            singleUserInfo["coception_date"],
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY");
          let todaydate = moment().format("DD-MM-YYYY");
          let addTodayDate = moment(todaydate, "DD-MM-YYYY").add(14, "D");
          let weeks = moment(addTodayDate, "DD-MM-YYYY").diff(
            moment(coceptionDate, "DD-MM-YYYY"),
            "weeks"
          );

          let days = moment(addTodayDate, "DD-MM-YYYY").diff(
            moment(coceptionDate, "DD-MM-YYYY"),
            "days"
          );
          return {
            week: weeks.toString().length > 0 ? parseInt(weeks) : 0,
            days: days ? days : 0,
            day: weeks.toString().includes(".")
              ? parseInt(weeks.toString().split(".")[1][0])
              : 0,
            start: moment()
              .subtract(days - 1, "days")
              .format("DD-MM-YYYY"),
          };
        }
      }
    } else {
      return { week: 0, days: 0, day: 0, start: null };
    }
  };

  const storeWeekWeightData = () => {
    let weeks = {};
    let weekDa = getPregnancyWeekNDay();
    let startDate = weekDa.start;
    let startMonth = moment(startDate, "DD-MM-YYYY").month();
    let endDate = moment(startDate, "DD-MM-YYYY")
      .add(weekDa.days, "days")
      .format("DD-MM-YYYY");
    for (let i = startMonth; i < startMonth + 10; i++) {
      let weekIndex = 1;
      weeks[weekIndex] = [];
      let weekStart = moment(startDate, "DD-MM-YYYY").format("DD-MM-YYYY");
      let weekEnd = moment(weekStart, "DD-MM-YYYY")
        .add(6, "days")
        .endOf("date")
        .format("DD-MM-YYYY");
      for (let j = 0; j < 7; j++) {
        if (j == 0) {
          weeks[weekIndex].push({
            date: moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY"),
            day: moment(weekStart, "DD-MM-YYYY").format("dddd").slice(0, 3),
            weight: weights.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY")
            )?.weight,
          });
        } else {
          weeks[weekIndex].push({
            date: moment(weekStart, "DD-MM-YYYY")
              .add(j, "days")
              .format("DD-MM-YYYY"),
            day: moment(weekStart, "DD-MM-YYYY")
              .add(j, "days")
              .format("dddd")
              .slice(0, 3),
            weight: weights.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY")
                  .add(j, "days")
                  .format("DD-MM-YYYY")
            )?.weight,
          });
          if (j == 6) {
            weekStart = moment(weekEnd, "DD-MM-YYYY")
              .add(1, "day")
              .format("DD-MM-YYYY");
            weekEnd = moment(weekStart, "DD-MM-YYYY")
              .add(6, "days")
              .endOf("date")
              .format("DD-MM-YYYY");

            if (
              moment(weekStart, "DD-MM-YYYY").isBetween(
                moment(startDate, "DD-MM-YYYY"),
                moment(endDate, "DD-MM-YYYY")
              )
            ) {
              weekIndex = weekIndex + 1;

              weeks[weekIndex] = [];
              j = -1;
            }
          }
        }
      }
    }
    setWeekWeightData(weeks);
  };
  const storeWeekVitalData = () => {
    let weeks = {};
    let weekDa = getPregnancyWeekNDay();
    let startDate = weekDa.start;
    let startMonth = moment(startDate, "DD-MM-YYYY").month();
    let endDate = moment(startDate, "DD-MM-YYYY")
      .add(weekDa.days, "days")
      .format("DD-MM-YYYY");
    for (let i = startMonth; i < startMonth + 10; i++) {
      let weekIndex = 1;
      weeks[weekIndex] = [];
      let weekStart = moment(startDate, "DD-MM-YYYY").format("DD-MM-YYYY");
      let weekEnd = moment(weekStart, "DD-MM-YYYY")
        .add(6, "days")
        .endOf("date")
        .format("DD-MM-YYYY");
      for (let j = 0; j < 7; j++) {
        if (j == 0) {
          weeks[weekIndex].push({
            date: moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY"),
            day: moment(weekStart, "DD-MM-YYYY").format("dddd").slice(0, 3),
            breathingRate: vitals.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY")
            )?.breathingRate,
            hrvScore: vitals.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY")
            )?.hrvScore,
            hr: vitals.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY").format("DD-MM-YYYY")
            )?.hr,
          });
        } else {
          weeks[weekIndex].push({
            date: moment(weekStart, "DD-MM-YYYY")
              .add(j, "days")
              .format("DD-MM-YYYY"),
            day: moment(weekStart, "DD-MM-YYYY")
              .add(j, "days")
              .format("dddd")
              .slice(0, 3),
            breathingRate: vitals.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY")
                  .add(j, "days")
                  .format("DD-MM-YYYY")
            )?.breathingRate,
            hrvScore: vitals.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY")
                  .add(j, "days")
                  .format("DD-MM-YYYY")
            )?.hrvScore,
            hr: vitals.find(
              (x) =>
                x["date"] ==
                moment(weekStart, "DD-MM-YYYY")
                  .add(j, "days")
                  .format("DD-MM-YYYY")
            )?.hr,
          });
          if (j == 6) {
            weekStart = moment(weekEnd, "DD-MM-YYYY")
              .add(1, "day")
              .format("DD-MM-YYYY");
            weekEnd = moment(weekStart, "DD-MM-YYYY")
              .add(6, "days")
              .endOf("date")
              .format("DD-MM-YYYY");

            if (
              moment(weekStart, "DD-MM-YYYY").isBetween(
                moment(startDate, "DD-MM-YYYY"),
                moment(endDate, "DD-MM-YYYY")
              )
            ) {
              weekIndex = weekIndex + 1;

              weeks[weekIndex] = [];
              j = -1;
            }
          }
        }
      }
    }
    setWeekVitalData(weeks);
  };

  useEffect(() => {
    (async () => {
      try {
        if (singleUserInfo) {
          const vitals = await getUserVitalsDataById(singleUserInfo.id);
          if (Array.isArray(vitals)) {
            setVitals(vitals);
          }
          const weight = await getUserWeightDataById(singleUserInfo.id);
          if (Array.isArray(weight)) {
            setWeights(weight);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
    
  }, []);
  useEffect(() => {
    if (vitals) storeWeekVitalData();
  }, [vitals]);
  useEffect(() => {
    if (weights.length > 0) {
      storeWeekWeightData();
      calculateAllDaysIdealWeight();
    }
  }, [weights]);
  return (
    <>
      {Math.ceil(getPregnancyWeekNDay().days / 7) ?? 0 ? (
        <div className="card h-100">
          <div className="card-header">
            <h2>Tracker Reports </h2>
          </div>
          <div className="card-body">
            <div className="row my-5">
              <Swiper
                style={{ width: "200px", textAlign: "center" }}
                keyboard={{
                  enabled: true,
                }}
                initialSlide={Math.ceil(getPregnancyWeekNDay().days / 7) ?? 0}
                modules={[Keyboard, Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                onSlideChange={(x) => setWeekIndex(x.activeIndex)}
                onSwiper={(swiper) => {}}
              >
                {Array.from(
                  { length: Math.ceil(getPregnancyWeekNDay().days / 7) ?? 0 },
                  (_, i) => (
                    <SwiperSlide key={i + 1}>
                      <h5>
                        <b>Week {i + 1}</b>
                      </h5>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
            <div className="row">
              <h6 className="text-center">
                {weekVitalData[weekIndex + 1]
                  ? weekVitalData[weekIndex + 1][0].date
                  : ""}{" "}
                To{" "}
                {weekVitalData[weekIndex + 1]
                  ? weekVitalData[weekIndex + 1][6].date
                  : ""}
              </h6>
              <div className="col-md-6">
                <div className="chart-card">
                  <h2>Your Average Heart Rate</h2>
                  <Line
                    data={{
                      labels:
                        Object.keys(weekVitalData).length &&
                        weekVitalData[weekIndex + 1]
                          ? weekVitalData[weekIndex + 1].map((x) => x.day ?? 0)
                          : [],
                      datasets: [
                        {
                          label: "Users Gained ",
                          data:
                            Object.keys(weekVitalData).length &&
                            weekVitalData[weekIndex + 1]
                              ? weekVitalData[weekIndex + 1].map(
                                  (x) => x.hr ?? 0
                                )
                              : [],
                          backgroundColor: "#D98669",
                          borderColor: "#D98669",
                          borderRadius: 8,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          position: "right",
                          max: 150,
                          min: 0,
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="chart-card">
                  <h2>Your Average Heart Rate Variability</h2>
                  <Line
                    data={{
                      labels:
                        Object.keys(weekVitalData).length &&
                        weekVitalData[weekIndex + 1]
                          ? weekVitalData[weekIndex + 1].map((x) => x.day ?? 0)
                          : [],
                      datasets: [
                        {
                          label: "Users Gained ",
                          data:
                            Object.keys(weekVitalData).length &&
                            weekVitalData[weekIndex + 1]
                              ? weekVitalData[weekIndex + 1].map(
                                  (x) => x.hrvScore ?? 0
                                )
                              : [],
                          backgroundColor: "#D98669",
                          borderColor: "#D98669",
                          borderRadius: 8,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          position: "right",
                          max: 150,
                          min: 0,
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="chart-card">
                  <h2>Average Weight Gain {weekIndex + 1} </h2>
                  <Scatter
                    data={{
                      labels:
                        Object.keys(weekWeightData).length &&
                        weekWeightData[weekIndex + 1]
                          ? weekWeightData[weekIndex + 1].map((x) => x.day ?? 0)
                          : [],
                      datasets: [
                        {
                          type: "bar",
                          label: "Users Gained ",
                          data:
                            Object.keys(weekWeightData).length &&
                            weekWeightData[weekIndex + 1]
                              ? weekWeightData[weekIndex + 1].map(
                                  (x) => x.weight ?? 0
                                )
                              : [],
                          backgroundColor: ["#D98669"],
                          borderRadius: 8,
                        },
                        {
                          type: "line",
                          label: "Users1 Gained ",
                          data: idelWeights.length
                            ? [
                                idelWeights[(weekIndex + 1) * 7 - 7]
                                  ? idelWeights[(weekIndex + 1) * 7 - 7]
                                      .lowWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 6]
                                  ? idelWeights[(weekIndex + 1) * 7 - 6]
                                      .lowWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 5]
                                  ? idelWeights[(weekIndex + 1) * 7 - 5]
                                      .lowWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 4]
                                  ? idelWeights[(weekIndex + 1) * 7 - 4]
                                      .lowWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 3]
                                  ? idelWeights[(weekIndex + 1) * 7 - 3]
                                      .lowWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 2]
                                  ? idelWeights[(weekIndex + 1) * 7 - 2]
                                      .lowWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 1]
                                  ? idelWeights[(weekIndex + 1) * 7 - 1]
                                      .lowWeight
                                  : 0,
                              ]
                            : [],
                          backgroundColor: ["#999999"],
                          borderRadius: 8,
                          // fill: -1,
                        },
                        {
                          type: "line",
                          label: "Users2 Gained ",
                          data: idelWeights.length
                            ? [
                                idelWeights[(weekIndex + 1) * 7 - 7]
                                  ? idelWeights[(weekIndex + 1) * 7 - 7]
                                      .highWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 6]
                                  ? idelWeights[(weekIndex + 1) * 7 - 6]
                                      .highWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 5]
                                  ? idelWeights[(weekIndex + 1) * 7 - 5]
                                      .highWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 4]
                                  ? idelWeights[(weekIndex + 1) * 7 - 4]
                                      .highWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 3]
                                  ? idelWeights[(weekIndex + 1) * 7 - 3]
                                      .highWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 2]
                                  ? idelWeights[(weekIndex + 1) * 7 - 2]
                                      .highWeight
                                  : 0,
                                idelWeights[(weekIndex + 1) * 7 - 1]
                                  ? idelWeights[(weekIndex + 1) * 7 - 1]
                                      .highWeight
                                  : 0,
                              ]
                            : [],
                          backgroundColor: ["#999999"],
                          borderRadius: 8,
                          fill: 1,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          position: "right",
                          max: 150,
                          min: 0,
                          ticks: {
                            z: -1,
                          },
                        },
                      },

                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="chart-card">
                  <h2>Average Respiration Rate</h2>
                  <Line
                    data={{
                      labels:
                        Object.keys(weekVitalData).length &&
                        weekVitalData[weekIndex + 1]
                          ? weekVitalData[weekIndex + 1].map((x) => x.day ?? 0)
                          : [],
                      datasets: [
                        {
                          label: "Users Gained ",
                          data:
                            Object.keys(weekVitalData).length &&
                            weekVitalData[weekIndex + 1]
                              ? weekVitalData[weekIndex + 1].map(
                                  (x) => x.breathingRate ?? 0
                                )
                              : [],
                          backgroundColor: "#D98669",
                          borderColor: "#D98669",
                          borderRadius: 8,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          position: "right",
                          max: 150,
                          min: 0,
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card h-100">
          <div className="card-header">
            <h2>Tracker Reports</h2>
          </div>
          <div className="card-body p-0 text-center pt-5">
            <h3>No Data Found</h3>
          </div>
        </div>
      )}
    </>
  );
}

export default TrackReport;
