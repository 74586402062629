import React, { useState, useEffect } from "react";
import { useRoutes, useLocation, useNavigate, Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import AddExercises from "./exercises/add-exercises";
import AllExercises from "./exercises/all-exercises";
import SingleExercise from "./exercises/single-exercise";
import Login from "./Login";
import AddModel from "./models/add-model";
import AllModels from "./models/all-models";
import Model2 from "./models/Model2";
import Model3 from "./models/Model3";
import Model1 from "./models/Model1";
import Obese from "./models/obese";
import Navigation from "./Navigation";
import SideBar from "./SideBar";
import TimeManagement from "./time-management";
import SingleUser from "./users/SingleUser";
import UserList from "./users/UserList";
import FoodGroups from "./dietManagement/foodGroups";
import FoodItems from "./dietManagement/foodItems";
import TemplateManagement from "./templateManagement/TemplateManagement";
import TemplateList from "./templateManagement/TemplateList";
import SignUp from "./auth/SignUp";
import Plans from "./auth/Plans";
import { async } from "@firebase/util";
import { auth } from "./firebase-config";
import Loader from "react-js-loader";

const Layout = () => {
  const isAuth = localStorage.getItem("authUser");
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [moduleById, setModuleById] = useState([]);
  const [activeIndexs, setActiveIndexs] = useState([]);
  const [userData, setUserData] = useState(null);
  const [startLoader, setStartLoader] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    console.log(userData);
    console.log(location.pathname);
    setTimeout(() => {
      if(auth && auth.currentUser && auth.currentUser.getIdTokenResult)
      auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        setUserData(idTokenResult);
      });
    }, 2000);
    if (
      authRoutes.filter((x) => x.path == location.pathname).length &&
      isAuth == null
    ) {
      if (location.pathname == "/plans") navigate("/plans");
      else if (location.pathname == "/sign-up") navigate("/sign-up");
      else navigate("/login");
    }
  }, []);

  useEffect(() => {
    console.log(userData);
    if (
      isAuth &&
      userData &&
      userData.claims &&
      userData.claims.role &&
      userData.claims.role == "Admin"
    ) {
      navigate("/");
    } else if (!isAuth) {
      if (location.pathname == "/plans") navigate("/plans");
      else if (location.pathname == "/sign-up") navigate("/sign-up");
      else navigate("/login");
    } else {
      if (userData && userData.claims && !userData.claims.role) {
        navigate("/plans");
      }
    }
  }, [userData]);

  const authRoutes = [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/all-exercises",
      element: <AllExercises />,
    },
    {
      path: "/add-exercise",
      element: <AddExercises />,
    },
    // {
    //   path: "/all-models",
    //   element: <AllModels />,
    // },
    // {
    //   path: "/add-model",
    //   element: <AddModel />,
    // },
    {
      path: "/model3",
      element: <Model3 />,
    },
    {
      path: "/model2",
      element: <Model2 />,
    },
    // {
    //   path: "/obese",
    //   element: <Obese />,
    // },
    {
      path: "/model1",
      element: <Model1 />,
    },
    {
      path: "/single-exercise",
      element: <SingleExercise />,
    },
    {
      path: "/time-management",
      element: <TimeManagement />,
    },
    {
      path: "/all-users",
      element: <UserList />,
    },
    {
      path: "/single-user",
      element: <SingleUser />,
    },
    {
      path: "/food-groups",
      element: <FoodGroups />,
    },
    {
      path: "/food-items",
      element: <FoodItems />,
    },
    {
      path: "/all-templates",
      element: <TemplateList />,
    },
    {
      path: "/add-template",
      element: <TemplateManagement />,
    },
  ];
  const publicRoutes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/plans",
      element: <Plans />,
    },
  ];
  const publicAuthRoutes = [
    {
      path: "/plans",
      element: <Plans />,
    },
  ];

  // const routes = useRoutes(isAuth != null ? authRoutes : publicRoutes);

  let routes = useRoutes(
    isAuth && auth.currentUser
      ? userData &&
        userData.claims &&
        userData.claims.role &&
        userData.claims.role == "Admin"
        ? authRoutes
        : publicAuthRoutes
      : publicRoutes
  );

  return isAuth && auth.currentUser ? (
    <>
      {isAuth &&
        userData &&
        userData.claims &&
        userData.claims.role &&
        userData.claims.role == "Admin" && <Navigation />}
      {routes}
    </>
  ) : (
    <>{routes}</>
  );
};

export default Layout;
