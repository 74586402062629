import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import Loader from "react-js-loader";
import {
  getFileByName,
  getUserById,
  nineMoonsLogin,
  getFileByUrl,
  getTratis,
} from "./api";
import {
  Nutrition_AI_RDA,
  Nutrition_content,
  Prental_vitamins,
  sample,
} from "../constatnt";
import DietSetup from "./DietSetup";
import TrackReport from "./TrackReport";
import moment from "moment";
import DietReport from "./DietReport";

function SingleUser() {
  const [singleUserInfo, setSingleUserInfo] = useState(null);
  const [nutritionContent, setNutritionContent] = useState(null);
  const [nutritionAiRda, setNutritionAiRda] = useState([]);
  const [prentalVitamins, setPrentalVitamins] = useState(null);
  const [bmi, setBmi] = useState(0);
  const [activityLevel, setActivityLevel] = useState(0);
  const [noOfWeek, setNoOfWeek] = useState(0);
  const [age, setAge] = useState(0);
  const [calorie, setCalories] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [fats, setFats] = useState(0);
  const [protein, setProtein] = useState(0);
  // const [sampleKit, setSampleKit] = useState(sample);
  const [traitsList, setTraitsList] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const location = useLocation();
  const risk = (traitData) => {
    let level = traitData["assessment"]["level"];
    let connotation = traitData["trait"]["connotation"];
    if (level == 2) {
      if (connotation == "risky") {
        return {
          Assessment: "Elevated",
          Color: "#C17160",
        };
      } else {
        return {
          Assessment: "Advantaged",
          Color: "#667F70",
        };
      }
    } else if (level == 1) {
      if (connotation == "risky") {
        return {
          Assessment: "Slightly Elevated",
          Color: "#F6A13E",
        };
      } else {
        return {
          Assessment: "Slightly Advantaged",
          Color: "#7DA48C",
        };
      }
    } else {
      if (connotation == "risky") {
        return {
          Assessment: "Low Risk",
          Color: "#7DA48C",
        };
      } else {
        return {
          Assessment: "Typical",
          Color: "#97CACA",
        };
      }
    }
  };
  const colorStatus = (color) => {
    return (
      <span
        className="tdNutrientStatus"
        style={{ backgroundColor: color }}
      ></span>
    );
  };
  const DnaPersonalizedNutrients = (name) => {
    if (
      singleUserInfo &&
      singleUserInfo.is_kit_registered &&
      singleUserInfo.is_kit_registered == true &&
      nutritionContent &&
      traitsList &&
      traitsList.length &&
      [
        "vitaminB9",
        "iron",
        "calcium",
        "vitaminD",
        "choline",
        "vitaminC",
        "zinc",
      ].includes(name)
    ) {
      const data = traitsList.find(
        (x) =>
          x.trait_code ==
          nutritionContent.find((x) => x.code == name).DNA_Trait_Code
      );
      // console.log(data, name);
      if (data && data.assessment.level == 2) {
        return nutritionContent.find((x) => x.code == name).Pregnant_2;
      } else if (data && data.assessment.level == 1) {
        // console.log(nutritionContent.find((x) => x.code == name));
        return nutritionContent.find((x) => x.code == name).Pregnant_1;
      } else {
        return nutritionContent.find((x) => x.code == name).Pregnant_0;
      }
    }
    return userValue && userValue[name] ? userValue[name] : "0";
  };
  const userTargetValue = (name) => {
    if (nutritionAiRda.length) {
      let nutr = nutritionAiRda.find((x) => x.nutrient_code == name);
      let Ai = nutr.AI;
      let Rda = nutr.RDA;
      let Unit = nutr.Unit;
      if (Ai) return { value: Ai, unit: Unit };
      if (Rda) return { value: Rda, unit: Unit };
      return { value: "0", unit: Unit };
    } else return { value: "0", unit: null };
  };
  const userValues = (name) => {
    if (prentalVitamins && prentalVitamins.length && name) {
      setUserValue(
        prentalVitamins.find((x) => x.Name == name)
          ? prentalVitamins.find((x) => x.Name == name)
          : null
      );
    } else return { value: "0", unit: null };
  };

  const getAllFiles = () => {
    getFileByUrl(Nutrition_content).then((x) => {
      if (x.data) {
        setNutritionContent(x.data);
      }
    });
    getFileByUrl(Nutrition_AI_RDA).then((x) => {
      if (x.data) {
        setNutritionAiRda(x.data);
      }
    });
    getFileByUrl(Prental_vitamins).then((x) => {
      console.log(x);
      if (x.data) {
        setPrentalVitamins(x.data);
      }
    });
  };
  useEffect(() => {
    console.log(singleUserInfo);
    if (singleUserInfo && singleUserInfo.prenatal_vitamins) {
      userValues(singleUserInfo.prenatal_vitamins);
    }
  }, [nutritionContent, nutritionAiRda, prentalVitamins, singleUserInfo]);
  useEffect(() => {
    (async () => {
      // if (!localStorage.getItem("ninemoon"))
      await nineMoonsLogin();
      const singleUser = await getUserById(location.state.uid);
      setSingleUserInfo(singleUser);

      console.log(singleUser);
      if (
        singleUser &&
        singleUser.is_kit_registered &&
        singleUser.dna_sample_id
      ) {
        console.log(singleUser);
        let tratis = await getTratis(singleUser.dna_sample_id);
        if (
          tratis &&
          tratis.data &&
          tratis.data.results &&
          Array.isArray(tratis.data.results)
        ) {
          let tempArr = tratis.data.results;
          for (let i = 0; tratis.data.next != null; i++) {
            tratis = await getTratis(null, tratis.data.next);
            console.log(tratis);
            if (tratis.data) {
              tempArr.push(...tratis.data.results);
            }
          }
          if (
            tratis &&
            tratis.data &&
            tratis.data.next == null &&
            tempArr &&
            Array.isArray(tempArr)
          ) {
            console.log(tempArr);
            setTraitsList(tempArr);
          }
        }
      }
      getAllFiles();
    })();
  }, []);

  const calWeight = (data) => {
    if (data.weight && data.weight_unit && data.weight_unit == "lbs") {
      const poundOnce = data["weight"].split(".");
      let poundVal = 0.0;
      let ounceVal = 0.0;

      poundVal = poundOnce[0] ? parseFloat(poundOnce[0]) : 0.0;
      ounceVal = poundOnce[1] ? parseFloat(poundOnce[1]) : 0.0;

      let poundToKg = poundVal / 2.205;
      let ounceToKg = ounceVal / 35.274;
      let totalKg = poundToKg + ounceToKg;
      return totalKg;
    } else {
      return data["weight"] ? parseFloat(data["weight"]) : 0.0;
    }
  };

  const calHeight = (data) => {
    let heightUnit = data["height_unit"];

    var footVal = 0.0;
    var inchVal = 0.0;

    if (heightUnit == "ft/in") {
      let footInch = data["height"].split(".");

      footVal = footInch[0] ? parseFloat(footInch[0]) : 0.0;

      inchVal = footInch[1] ? parseFloat(footInch[1]) : 0.0;

      let footToMt = footVal / 3.281;
      let inchToMt = inchVal / 39.37;
      let totalMt = footToMt + inchToMt;
      return totalMt;
    } else {
      let heightInCm = data["height"] ? parseFloat(data["height"]) : 0.0;
      let heightInMt = heightInCm / 100;
      return heightInMt;
    }
  };

  useEffect(() => {
    if (singleUserInfo && singleUserInfo.weight && singleUserInfo.height) {
      setBmi(
        parseInt(
          calWeight(singleUserInfo) / Math.pow(calHeight(singleUserInfo), 2)
        )
      );
    }
    const activityLevelFormula = {
      "No exercise": 1,
      "1-2 times per week": 1.12,
      "3-5 times per week": 1.27,
      "More than 5 times per week": 1.45,
    };
    if (singleUserInfo && singleUserInfo.exercise_frequency) {
      setActivityLevel(activityLevelFormula[singleUserInfo.exercise_frequency]);
    }

    if (singleUserInfo && singleUserInfo.birth_date) {
      const extractDate = singleUserInfo.birth_date.split("0");
      const dob = new Date(
        `${extractDate[1]}-${extractDate[0]}-${extractDate[2]}`
      );
      const month_diff = Date.now() - dob.getTime();
      const age_dt = new Date(month_diff);
      const year = age_dt.getUTCFullYear();
      const age = Math.abs(year - 1970);
      setAge(age);
    }
    if (singleUserInfo && singleUserInfo.is_due_date_known == true) {
      const extractDate = singleUserInfo.due_date.split("0");
      const due_date = new Date(
        `${extractDate[1]}-${extractDate[0]}-${extractDate[2]}`
      );
      const currentDate = new Date();
      const Difference_In_Time = due_date.getTime() - currentDate.getTime();
      const noOfDays = Difference_In_Time / (1000 * 3600 * 24);
      setNoOfWeek(parseInt((280 - noOfDays) / 7));
    } else {
      if (singleUserInfo && singleUserInfo.is_period_date == true) {
        const extractDate = singleUserInfo.period_date.split("0");
        const periodDate = new Date(
          `${extractDate[1]}-${extractDate[0]}-${extractDate[2]}`
        );
        const currentDate = new Date();
        const Difference_In_Time = currentDate.getTime() - periodDate.getTime();
        const noOfDays = Difference_In_Time / (1000 * 3600 * 24);
        setNoOfWeek(parseInt(noOfDays / 7));
      } else if (singleUserInfo && singleUserInfo.conceptionDate) {
        const extractDate = singleUserInfo.conception_date.split("0");
        const conceptionDate = new Date(
          `${extractDate[1]}-${extractDate[0]}-${extractDate[2]}`
        );
        const currentDate = new Date();
        const Difference_In_Time =
          currentDate.getTime() - conceptionDate.getTime();
        const noOfDays = Difference_In_Time / (1000 * 3600 * 24);
        setNoOfWeek(parseInt(noOfDays / 7 + 2));
      }
    }
  }, [singleUserInfo]);

  useEffect(() => {
    if (
      bmi &&
      activityLevel >= 0 &&
      age &&
      noOfWeek &&
      singleUserInfo &&
      singleUserInfo.weight &&
      singleUserInfo.height
    ) {
      setCalories(
        (
          354 -
          6.91 * age +
          (9.6 * calWeight(singleUserInfo) + 726 * calHeight(singleUserInfo)) *
            activityLevel +
          (bmi > 29.9
            ? 0
            : parseInt(noOfWeek) < 13
            ? 0
            : 8 * parseInt(noOfWeek) + 180)
        ).toFixed(2)
      );
    }
  }, [bmi, activityLevel, age, noOfWeek]);
  useEffect(() => {
    if (calorie) {
      if (
        singleUserInfo &&
        singleUserInfo.at_risk_for &&
        singleUserInfo.at_risk_for == "Gestational Diabetes"
      ) {
        setCarbs(parseFloat(0.4 * calorie));
        setFats(parseFloat(0.3 * calorie));
        setProtein(parseFloat(0.3 * calorie));
      } else {
        setCarbs(parseFloat(0.5 * calorie));
        setFats(parseFloat(0.275 * calorie));
        setProtein(parseFloat(0.225 * calorie));
      }
    }
  }, [calorie]);
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/all-users">Users</Link>
            </li>
            <li className="breadcrumb-item active">User Details</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="user-details">
          <div className="row">
            <div className="col-lg-3 d-flex">
              <div className="card w-100 mb-lg-0">
                <div className="card-header user-profile">
                  <div className="d-flex">
                    <i className="far fa-user"></i>
                    <div>
                      {location.state && location.state.displayName ? (
                        <h2>{location.state.displayName}</h2>
                      ) : (
                        <></>
                      )}
                      {location.state && location.state.email ? (
                        <p>{location.state.email}</p>
                      ) : (
                        "0"
                      )}

                      {singleUserInfo && singleUserInfo.due_date ? (
                        <h4>Due : {singleUserInfo.due_date}</h4>
                      ) : (
                        colorStatus("#DE3D1B")
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body ps-0">
                  <div className="user-menu">
                    <h2>
                      User Profile Menu{" "}
                      <Link
                        to="/all-users"
                        className="btn btn-sm btn-secondary"
                      >
                        <i className="fas fa-chevron-left me-1"></i> Back
                      </Link>
                    </h2>
                    <div
                      className="nav flex-column nav-pills"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link active"
                        data-bs-toggle="pill"
                        data-bs-target="#user-profile"
                        type="button"
                        role="tab"
                        aria-controls="user-profile"
                        aria-selected="true"
                      >
                        <i className="fas fa-user-tag"></i> User Profile
                      </button>
                      <button
                        className="nav-link"
                        data-bs-toggle="pill"
                        data-bs-target="#user-membership"
                        type="button"
                        role="tab"
                        aria-controls="user-membership"
                        aria-selected="false"
                      >
                        <i className="fas fa-money-check-alt"></i> Membership
                        Details
                      </button>
                      <button
                        className="nav-link"
                        data-bs-toggle="pill"
                        data-bs-target="#user-dna-traits"
                        type="button"
                        role="tab"
                        aria-controls="user-dna-traits"
                        aria-selected="false"
                      >
                        <i className="fas fa-dna"></i> DNA Trait Results
                      </button>
                      <button
                        className="nav-link"
                        data-bs-toggle="pill"
                        data-bs-target="#user-dris"
                        type="button"
                        role="tab"
                        aria-controls="user-dris"
                        aria-selected="false"
                      >
                        <i className="fas fa-diagnoses"></i> DRIs
                      </button>

                      <button
                        className="nav-link"
                        data-bs-toggle="pill"
                        data-bs-target="#user-trackreport"
                        type="button"
                        role="tab"
                        aria-controls="user-trackreport"
                        aria-selected="false"
                      >
                        <i className="fas fa-chart-line"></i> Tracker Reports
                      </button>
                      <button
                        className="nav-link"
                        data-bs-toggle="pill"
                        data-bs-target="#user-diet"
                        type="button"
                        role="tab"
                        aria-controls="user-diet"
                        aria-selected="false"
                      >
                        <i className="fas fa-utensils"></i> Diet Setup
                      </button>
                      <button
                        className="nav-link"
                        data-bs-toggle="pill"
                        data-bs-target="#user-dietreport"
                        type="button"
                        role="tab"
                        aria-controls="user-dietreport"
                        aria-selected="false"
                      >
                        <i className="fas fa-chart-line"></i> Diet Reports
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 d-flex">
              <div className="tab-content w-100">
                <div
                  className="tab-pane h-100 fade show active"
                  id="user-profile"
                  role="tabpanel"
                  aria-labelledby="user-profile-tab"
                >
                  <div className="card h-100">
                    <div className="card-header">
                      <h2>User Profile Details</h2>
                    </div>
                    {singleUserInfo ? (
                      <div className="card-body p-0">
                        <div className="user-data-list">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <h2>birth_date</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.birth_date
                                  ? singleUserInfo.birth_date
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>weight</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.weight
                                  ? singleUserInfo.weight +
                                    " " +
                                    singleUserInfo.weight_unit
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>height</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.height
                                  ? singleUserInfo.height +
                                    " " +
                                    singleUserInfo.height_unit
                                  : "-"}{" "}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>is_period_date</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.is_period_date == true
                                  ? "True"
                                  : "False"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>due_date</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.due_date
                                  ? singleUserInfo.due_date
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>is_due_date_known</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.is_due_date_known == true
                                  ? "True"
                                  : "False"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>complication</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.complication
                                  ? singleUserInfo.complication
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>exercise_frequency</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.exercise_frequency
                                  ? singleUserInfo.exercise_frequency
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>per_day_supplement_caps</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.per_day_supplement_caps
                                  ? singleUserInfo.per_day_supplement_caps
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>period_date</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.period_date
                                  ? singleUserInfo.period_date
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>is_setup_profile</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.is_setup_profile == true
                                  ? "True"
                                  : "False"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>is_take_supplement</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.is_take_supplement == true
                                  ? "True"
                                  : "False"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>at_risk_for</h2>
                              <p>
                                {singleUserInfo && singleUserInfo.at_risk_for
                                  ? singleUserInfo.at_risk_for
                                  : "-"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>isAgreeDisclaimer</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.isAgreeDisclaimer == true
                                  ? "True"
                                  : "False"}
                              </p>
                            </li>
                            <li className="list-group-item">
                              <h2>prenatal_vitamins</h2>
                              <p>
                                {singleUserInfo &&
                                singleUserInfo.prenatal_vitamins
                                  ? singleUserInfo.prenatal_vitamins
                                  : "-"}
                              </p>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
                    ) : (
                      <Loader
                        type="spinner-circle"
                        bgColor={"#D98669"}
                        color={"#D98669"}
                        size={100}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane h-100 fade"
                  id="user-membership"
                  role="tabpanel"
                  aria-labelledby="user-membership-tab"
                >
                  <div className="card h-100">
                    <div className="card-header">
                      <h2>User Membership Details</h2>
                    </div>
                    <div className="card-body p-0">
                      <div className="user-data-list">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <h2>transactionId</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData.transactionId
                                ? singleUserInfo.membershipData.transactionId
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>originalTransactionId</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData
                                .originalTransactionId
                                ? singleUserInfo.membershipData
                                    .originalTransactionId
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>productId</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData.productId
                                ? singleUserInfo.membershipData.transactionId
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>quantity</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData.quantity
                                ? singleUserInfo.membershipData.quantity
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>subscriptionExpirationDate</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData
                                .subscriptionExpirationDate
                                ? singleUserInfo.membershipData
                                    .subscriptionExpirationDate
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>purchaseDate</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData.purchaseDate
                                ? singleUserInfo.membershipData.purchaseDate
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>originalPurchaseDate</h2>
                            <p>
                              {singleUserInfo &&
                              singleUserInfo.membershipData &&
                              singleUserInfo.membershipData.originalPurchaseDate
                                ? singleUserInfo.membershipData
                                    .originalPurchaseDate
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>isTrialPeriod</h2>
                            <p>
                              {singleUserInfo && singleUserInfo.membershipData
                                ? singleUserInfo.membershipData.isTrialPeriod ==
                                  true
                                  ? "True"
                                  : "False"
                                : "-"}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <h2>isInIntroOfferPeriod</h2>
                            <p>
                              {singleUserInfo && singleUserInfo.membershipData
                                ? singleUserInfo.membershipData
                                    .isInIntroOfferPeriod == true
                                  ? "True"
                                  : "False"
                                : "-"}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {singleUserInfo && singleUserInfo.is_kit_registered ? (
                  <div
                    className="tab-pane h-100 fade"
                    id="user-dna-traits"
                    role="tabpanel"
                    aria-labelledby="user-dna-traits-tab"
                  >
                    <div className="card h-100">
                      <div className="card-header">
                        <h2>DNA Trait Results</h2>
                      </div>
                      <div className="card-body p-0">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Trait Name</th>
                              <th scope="col">Assessment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {traitsList && traitsList.length > 0 ? (
                              traitsList
                                .filter((x) =>
                                  [
                                    "vitaminB9-deficiency",
                                    "iron-deficiency",
                                    "calcium-deficiency",
                                    "vitaminD-deficiency",
                                    "choline-deficiency",
                                    "monounsaturated-fats-increased-benefits",
                                    "vitaminC-deficiency",
                                    "zinc-deficiency",
                                    "caffeine-metabolism-impairment",
                                    "gluten-sensitivity",
                                    "starch-metabolism",
                                    "low-carb-diet-effectiveness",
                                    "exercise-benefits-maximal-oxygen-uptake",
                                    "muscle-damage-risk",
                                    "power",
                                    "endurance",
                                    "joint-injury-risk",
                                    "overall-tendon-ligament-injury-risk",
                                    "low-resting-metabolic-rate",
                                    "exercise-benefits-blood-pressure",
                                    "exercise-benefits-lowering-cholesterol",
                                  ].includes(x.trait_code)
                                )
                                .map((x, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <h2 className="tdTraitName">
                                          {x.trait.name}
                                        </h2>
                                      </td>
                                      <td>
                                        <div className="tdTraitAssess">
                                          <span
                                            style={{
                                              backgroundColor: risk(x).Color,
                                            }}
                                          ></span>
                                          <h2>{risk(x).Assessment}</h2>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <tr>
                                <td colSpan={2} className="p-5">
                                  <Loader
                                    type="spinner-circle"
                                    bgColor={"#D98669"}
                                    color={"#D98669"}
                                    size={100}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="tab-pane h-100 fade"
                    id="user-dna-traits"
                    role="tabpanel"
                    aria-labelledby="user-dris-tab"
                  >
                    <div className="card h-100">
                      <div className="card-header">
                        <h2>DNA Trait Results</h2>
                      </div>
                      <div className="card-body p-0 text-center pt-5">
                        <h3>Waiting for Dna Kit Register</h3>
                      </div>
                    </div>
                  </div>
                )}

                {singleUserInfo && singleUserInfo.is_kit_registered ? (
                  <div
                    className="tab-pane h-100 fade"
                    id="user-dris"
                    role="tabpanel"
                    aria-labelledby="user-dris-tab"
                  >
                    <div className="card h-100">
                      <div className="card-header">
                        <h2>User’s supplements vs DRIs</h2>
                      </div>
                      {prentalVitamins ? (
                        <div className="card-body p-0">
                          <h2 className="driTitle">Macros</h2>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Nutrient Name</th>
                                <th scope="col">User's Target</th>
                                <th scope="col">User's Value</th>
                                <th scope="col" className="text-center">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Calorie</h2>
                                </td>
                                <td>
                                  {userTargetValue("calories").value &&
                                  userTargetValue("calories").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("calories").value}{" "}
                                      {userTargetValue("calories").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {calorie != "0" && calorie > 0 ? (
                                    <h2 className="tdNutrientValue">
                                      {calorie}{" "}
                                      {userTargetValue("calories").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("calories").value != "0" &&
                                  calorie > 0
                                    ? userTargetValue("calories").value >
                                      calorie
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Carbs</h2>
                                </td>
                                <td>
                                  <h2 className="tdNutrientTarget">
                                    {userTargetValue("carbs").value &&
                                    userTargetValue("carbs").value != "0" ? (
                                      <h2 className="tdNutrientTarget">
                                        {userTargetValue("carbs").value}{" "}
                                        {userTargetValue("carbs").unit}
                                      </h2>
                                    ) : (
                                      <h2 className="tdNutrientTarget">0</h2>
                                    )}
                                  </h2>
                                </td>
                                <td>
                                  {(carbs / 4).toFixed(2) > 0 ? (
                                    <h2 className="tdNutrientValue">
                                      {(carbs / 4).toFixed(2)}{" "}
                                      {userTargetValue("carbs").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("carbs").value != "0" &&
                                  carbs / 4 > 0
                                    ? userTargetValue("carbs").value > carbs / 4
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Fat</h2>
                                </td>
                                <td>
                                  {userTargetValue("fats").value &&
                                  userTargetValue("fats").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("fats").value}{" "}
                                      {userTargetValue("fats").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {parseFloat(fats / 9).toFixed(2) > 0 ? (
                                    <h2 className="tdNutrientValue">
                                      {parseFloat(fats / 9).toFixed(2)}{" "}
                                      {userTargetValue("fats").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("fats").value != "0" &&
                                  parseFloat(fats / 9) > 0
                                    ? userTargetValue("fats").value >
                                      parseFloat(fats / 9)
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Omega3</h2>
                                </td>
                                <td>
                                  {userTargetValue("omega3").value &&
                                  userTargetValue("omega3").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("omega3").value}{" "}
                                      {userTargetValue("omega3").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("omega3") &&
                                  DnaPersonalizedNutrients("omega3") != "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("omega3")}{" "}
                                      {userTargetValue("omega3").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("omega3").value != "0" &&
                                  DnaPersonalizedNutrients("omega3") != "0"
                                    ? userTargetValue("omega3").value >
                                      DnaPersonalizedNutrients("omega3")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Proteins</h2>
                                </td>
                                <td>
                                  {userTargetValue("protein").value &&
                                  userTargetValue("protein").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("protein").value}{" "}
                                      {userTargetValue("protein").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {(protein / 4).toFixed(2) > 0 ? (
                                    <h2 className="tdNutrientValue">
                                      {(protein / 4).toFixed(2)}
                                      {userTargetValue("protein").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("protein").value != "0" &&
                                  protein / 4 > 0
                                    ? userTargetValue("protein").value >
                                      protein / 4
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Fiber</h2>
                                </td>
                                <td>
                                  {userTargetValue("fiber").value &&
                                  userTargetValue("fiber").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("fiber").value}{" "}
                                      {userTargetValue("fiber").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("fiber") &&
                                  DnaPersonalizedNutrients("fiber") != "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("fiber")}{" "}
                                      {userTargetValue("fiber").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("fiber").value != "0" &&
                                  DnaPersonalizedNutrients("fiber") != "0"
                                    ? userTargetValue("fiber").value >
                                      DnaPersonalizedNutrients("fiber")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <h2 className="driTitle">Vitamins</h2>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Nutrient Name</th>
                                <th scope="col">User's Target</th>
                                <th scope="col">User's Value</th>
                                <th scope="col" className="text-center">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin A</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminA").value &&
                                  userTargetValue("vitaminA").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminA").value}{" "}
                                      {userTargetValue("vitaminA").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminA") &&
                                  DnaPersonalizedNutrients("vitaminA") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminA")}{" "}
                                      {userTargetValue("vitaminA").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminA").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminA") != "0"
                                    ? userTargetValue("vitaminA").value >
                                      DnaPersonalizedNutrients("vitaminA")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B1</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB1").value &&
                                  userTargetValue("vitaminB1").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB1").value}{" "}
                                      {userTargetValue("vitaminB1").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB1") &&
                                  DnaPersonalizedNutrients("vitaminB1") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB1")}{" "}
                                      {userTargetValue("vitaminB1").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB1").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB1") != "0"
                                    ? userTargetValue("vitaminB1").value >
                                      DnaPersonalizedNutrients("vitaminB1")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">
                                    Vitamin B12
                                  </h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB12").value &&
                                  userTargetValue("vitaminB12").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB12").value}{" "}
                                      {userTargetValue("vitaminB12").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB12") &&
                                  DnaPersonalizedNutrients("vitaminB12") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB12")}{" "}
                                      {userTargetValue("vitaminB12").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB12").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB12") != "0"
                                    ? userTargetValue("vitaminB12").value >
                                      DnaPersonalizedNutrients("vitaminB12")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B2</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB2").value &&
                                  userTargetValue("vitaminB2").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB2").value}{" "}
                                      {userTargetValue("vitaminB2").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB2") &&
                                  DnaPersonalizedNutrients("vitaminB2") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB2")}{" "}
                                      {userTargetValue("vitaminB2").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB2").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB2") != "0"
                                    ? userTargetValue("vitaminB2").value >
                                      DnaPersonalizedNutrients("vitaminB2")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B3</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB3").value &&
                                  userTargetValue("vitaminB3").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB3").value}{" "}
                                      {userTargetValue("vitaminB3").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB3") &&
                                  DnaPersonalizedNutrients("vitaminB3") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB3")}{" "}
                                      {userTargetValue("vitaminB3").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB3").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB3") != "0"
                                    ? userTargetValue("vitaminB3").value >
                                      DnaPersonalizedNutrients("vitaminB3")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B5</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB5").value &&
                                  userTargetValue("vitaminB5").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB5").value}{" "}
                                      {userTargetValue("vitaminB5").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB5") &&
                                  DnaPersonalizedNutrients("vitaminB5") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB5")}{" "}
                                      {userTargetValue("vitaminB5").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB5").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB5") != "0"
                                    ? userTargetValue("vitaminB5").value >
                                      DnaPersonalizedNutrients("vitaminB5")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B6</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB6").value &&
                                  userTargetValue("vitaminB6").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB6").value}{" "}
                                      {userTargetValue("vitaminB6").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB6") &&
                                  DnaPersonalizedNutrients("vitaminB6") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB6")}{" "}
                                      {userTargetValue("vitaminB6").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB6").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB6") != "0"
                                    ? userTargetValue("vitaminB6").value >
                                      DnaPersonalizedNutrients("vitaminB6")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B7</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminB7").value &&
                                  userTargetValue("vitaminB7").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminB7").value}{" "}
                                      {userTargetValue("vitaminB7").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB7") &&
                                  DnaPersonalizedNutrients("vitaminB7") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminB7")}{" "}
                                      {userTargetValue("vitaminB7").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminB7").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminB7") != "0"
                                    ? userTargetValue("vitaminB7").value >
                                      DnaPersonalizedNutrients("vitaminB7")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin B9</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminB9") &&
                                  DnaPersonalizedNutrients("vitaminB9") !=
                                    "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("vitaminB9")}{" "}
                                      {userTargetValue("vitaminB9").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    colorStatus("#DE3D1B")
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["vitaminB9"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["vitaminB9"]}{" "}
                                      {userTargetValue("vitaminB9").unit}
                                    </h2>
                                  ) : (
                                    colorStatus("#DE3D1B")
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["vitaminB9"] &&
                                  DnaPersonalizedNutrients("vitaminB9") != "0"
                                    ? DnaPersonalizedNutrients("vitaminB9") >
                                      userValue["vitaminB9"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin C</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminC") &&
                                  DnaPersonalizedNutrients("vitaminC") !=
                                    "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("vitaminC")}{" "}
                                      {userTargetValue("vitaminC").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    colorStatus("#DE3D1B")
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["vitaminC"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["vitaminC"]}{" "}
                                      {userTargetValue("vitaminC").unit}
                                    </h2>
                                  ) : (
                                    colorStatus("#DE3D1B")
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["vitaminC"] &&
                                  DnaPersonalizedNutrients("vitaminC") != "0"
                                    ? DnaPersonalizedNutrients("vitaminC") >
                                      userValue["vitaminC"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin D</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminD") &&
                                  DnaPersonalizedNutrients("vitaminD") !=
                                    "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("vitaminD")}{" "}
                                      {userTargetValue("vitaminD").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    colorStatus("#DE3D1B")
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["vitaminD"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["vitaminD"]}{" "}
                                      {userTargetValue("vitaminD").unit}
                                    </h2>
                                  ) : (
                                    colorStatus("#DE3D1B")
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["vitaminD"] &&
                                  DnaPersonalizedNutrients("vitaminD") != "0"
                                    ? DnaPersonalizedNutrients("vitaminD") >
                                      userValue["vitaminD"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin E</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminE").value &&
                                  userTargetValue("vitaminE").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminE").value}{" "}
                                      {userTargetValue("vitaminE").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminE") &&
                                  DnaPersonalizedNutrients("vitaminE") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminE")}{" "}
                                      {userTargetValue("vitaminE").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminE").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminE") != "0"
                                    ? userTargetValue("vitaminE").value >
                                      DnaPersonalizedNutrients("vitaminE")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Vitamin K</h2>
                                </td>
                                <td>
                                  {userTargetValue("vitaminK").value &&
                                  userTargetValue("vitaminK").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("vitaminK").value}{" "}
                                      {userTargetValue("vitaminK").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("vitaminK") &&
                                  DnaPersonalizedNutrients("vitaminK") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("vitaminK")}{" "}
                                      {userTargetValue("vitaminK").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("vitaminK").value != "0" &&
                                  DnaPersonalizedNutrients("vitaminK") != "0"
                                    ? userTargetValue("vitaminK").value >
                                      DnaPersonalizedNutrients("vitaminK")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Choline</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("choline") &&
                                  DnaPersonalizedNutrients("choline") != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("choline")}{" "}
                                      {userTargetValue("choline").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    <>0</>
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["choline"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["choline"]}{" "}
                                      {userTargetValue("choline").unit}
                                    </h2>
                                  ) : (
                                    "0"
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["choline"] &&
                                  DnaPersonalizedNutrients("choline") != "0" &&
                                  DnaPersonalizedNutrients("choline") != "0"
                                    ? DnaPersonalizedNutrients("choline") >
                                      userValue["choline"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <h2 className="driTitle">Minerals</h2>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Nutrient Name</th>
                                <th scope="col">User's Target</th>
                                <th scope="col">User's Value</th>
                                <th scope="col" className="text-center">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Calcium</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("calcium") &&
                                  DnaPersonalizedNutrients("calcium") != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("calcium")}{" "}
                                      {userTargetValue("calcium").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["calcium"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["calcium"]}{" "}
                                      {userTargetValue("calcium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["calcium"] &&
                                  DnaPersonalizedNutrients("calcium") != "0"
                                    ? DnaPersonalizedNutrients("calcium") >
                                      userValue["calcium"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Chromium</h2>
                                </td>
                                <td>
                                  {userTargetValue("chromium").value &&
                                  userTargetValue("chromium").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("chromium").value}{" "}
                                      {userTargetValue("chromium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("chromium") &&
                                  DnaPersonalizedNutrients("chromium") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("chromium")}{" "}
                                      {userTargetValue("chromium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("chromium").value != "0" &&
                                  DnaPersonalizedNutrients("chromium") != "0"
                                    ? userTargetValue("chromium").value >
                                      DnaPersonalizedNutrients("chromium")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Copper</h2>
                                </td>
                                <td>
                                  {userTargetValue("copper").value &&
                                  userTargetValue("copper").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("copper").value}{" "}
                                      {userTargetValue("copper").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("copper") &&
                                  DnaPersonalizedNutrients("copper") != "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("copper")}{" "}
                                      {userTargetValue("copper").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("copper").value != "0" &&
                                  DnaPersonalizedNutrients("copper") != "0"
                                    ? userTargetValue("copper").value >
                                      DnaPersonalizedNutrients("copper")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Iodine</h2>
                                </td>
                                <td>
                                  {userTargetValue("iodine").value &&
                                  userTargetValue("iodine").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("iodine").value}{" "}
                                      {userTargetValue("iodine").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("iodine") &&
                                  DnaPersonalizedNutrients("iodine") != "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("iodine")}{" "}
                                      {userTargetValue("iodine").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>

                                <td>
                                  {userTargetValue("iodine").value != "0" &&
                                  DnaPersonalizedNutrients("iodine") != "0"
                                    ? userTargetValue("iodine").value >
                                      DnaPersonalizedNutrients("iodine")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Iron</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("iron") &&
                                  DnaPersonalizedNutrients("iron") != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("iron")}{" "}
                                      {userTargetValue("iron").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["iron"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["iron"]}{" "}
                                      {userTargetValue("iron").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["iron"] &&
                                  DnaPersonalizedNutrients("iron") != "0" &&
                                  DnaPersonalizedNutrients("iron") != "0"
                                    ? DnaPersonalizedNutrients("iron") >
                                      userValue["iron"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Magnesium</h2>
                                </td>
                                <td>
                                  {userTargetValue("magnesium").value &&
                                  userTargetValue("magnesium").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("magnesium").value}{" "}
                                      {userTargetValue("magnesium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("magnesium") &&
                                  DnaPersonalizedNutrients("magnesium") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("magnesium")}{" "}
                                      {userTargetValue("magnesium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("magnesium").value != "0" &&
                                  DnaPersonalizedNutrients("magnesium") != "0"
                                    ? userTargetValue("magnesium").value >
                                      DnaPersonalizedNutrients("magnesium")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Manganese</h2>
                                </td>
                                <td>
                                  {userTargetValue("manganese").value &&
                                  userTargetValue("manganese").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("manganese").value}{" "}
                                      {userTargetValue("manganese").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("manganese") &&
                                  DnaPersonalizedNutrients("manganese") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("manganese")}{" "}
                                      {userTargetValue("manganese").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>

                                <td>
                                  {userTargetValue("manganese").value != "0" &&
                                  DnaPersonalizedNutrients("manganese") != "0"
                                    ? userTargetValue("manganese").value >
                                      DnaPersonalizedNutrients("manganese")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Molybdenum</h2>
                                </td>
                                <td>
                                  {userTargetValue("molybdenum").value &&
                                  userTargetValue("molybdenum").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("molybdenum").value}{" "}
                                      {userTargetValue("molybdenum").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("molybdenum") &&
                                  DnaPersonalizedNutrients("molybdenum") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("molybdenum")}{" "}
                                      {userTargetValue("molybdenum").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("molybdenum").value != "0" &&
                                  DnaPersonalizedNutrients("molybdenum") != "0"
                                    ? userTargetValue("molybdenum").value >
                                      DnaPersonalizedNutrients("molybdenum")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Phosphorus</h2>
                                </td>
                                <td>
                                  {userTargetValue("phosphorus").value &&
                                  userTargetValue("phosphorus").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("phosphorus").value}{" "}
                                      {userTargetValue("phosphorus").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("phosphorus") &&
                                  DnaPersonalizedNutrients("phosphorus") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("phosphorus")}{" "}
                                      {userTargetValue("phosphorus").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>

                                <td>
                                  {userTargetValue("phosphorus").value != "0" &&
                                  DnaPersonalizedNutrients("phosphorus") != "0"
                                    ? userTargetValue("phosphorus").value >
                                      DnaPersonalizedNutrients("phosphorus")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Potassium</h2>
                                </td>
                                <td>
                                  {userTargetValue("potassium").value &&
                                  userTargetValue("potassium").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("potassium").value}{" "}
                                      {userTargetValue("potassium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("potassium") &&
                                  DnaPersonalizedNutrients("potassium") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("potassium")}{" "}
                                      {userTargetValue("potassium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("potassium").value != "0" &&
                                  DnaPersonalizedNutrients("potassium") != "0"
                                    ? userTargetValue("potassium").value >
                                      DnaPersonalizedNutrients("potassium")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Selenium</h2>
                                </td>
                                <td>
                                  {userTargetValue("selenium").value &&
                                  userTargetValue("selenium").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("selenium").value}{" "}
                                      {userTargetValue("selenium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("selenium") &&
                                  DnaPersonalizedNutrients("selenium") !=
                                    "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("selenium")}{" "}
                                      {userTargetValue("selenium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("selenium").value != "0" &&
                                  DnaPersonalizedNutrients("selenium") != "0"
                                    ? userTargetValue("selenium").value >
                                      DnaPersonalizedNutrients("selenium")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Sodium</h2>
                                </td>
                                <td>
                                  {userTargetValue("sodium").value &&
                                  userTargetValue("sodium").value != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {userTargetValue("sodium").value}{" "}
                                      {userTargetValue("sodium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("sodium") &&
                                  DnaPersonalizedNutrients("sodium") != "0" ? (
                                    <h2 className="tdNutrientValue">
                                      {DnaPersonalizedNutrients("sodium")}{" "}
                                      {userTargetValue("sodium").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userTargetValue("sodium").value != "0" &&
                                  DnaPersonalizedNutrients("sodium") != "0"
                                    ? userTargetValue("sodium").value >
                                      DnaPersonalizedNutrients("sodium")
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2 className="tdNutrientName">Zinc</h2>
                                </td>
                                <td>
                                  {DnaPersonalizedNutrients("zinc") &&
                                  DnaPersonalizedNutrients("zinc") != "0" ? (
                                    <h2 className="tdNutrientTarget">
                                      {DnaPersonalizedNutrients("zinc")}{" "}
                                      {userTargetValue("zinc").unit}
                                      <span className="dna-personalized-label">
                                        DNA-Personalized
                                      </span>
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientTarget">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userValue && userValue["zinc"] ? (
                                    <h2 className="tdNutrientValue">
                                      {userValue["zinc"]}{" "}
                                      {userTargetValue("zinc").unit}
                                    </h2>
                                  ) : (
                                    <h2 className="tdNutrientValue">0</h2>
                                  )}
                                </td>
                                <td>
                                  {userValue &&
                                  userValue["zinc"] &&
                                  DnaPersonalizedNutrients("zinc") != "0"
                                    ? DnaPersonalizedNutrients("zinc") >
                                      userValue["zinc"]
                                      ? colorStatus("#DE3D1B")
                                      : colorStatus("#479140")
                                    : colorStatus("#DE3D1B")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <Loader
                          type="spinner-circle"
                          bgColor={"#D98669"}
                          color={"#D98669"}
                          size={100}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="tab-pane h-100 fade"
                    id="user-dris"
                    role="tabpanel"
                    aria-labelledby="user-dris-tab"
                  >
                    <div className="card h-100">
                      <div className="card-header">
                        <h2>User’s supplements vs DRIs</h2>
                      </div>
                      <div className="card-body p-0 text-center pt-5">
                        <h3>Waiting for Dna Kit Register</h3>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="tab-pane h-100 fade"
                  id="user-dietreport"
                  role="tabpanel"
                  aria-labelledby="user-dietreport-tab"
                >
                  {singleUserInfo ? (
                    <DietReport {...{ singleUserInfo, noOfWeek, bmi }} />
                  ) : (
                    colorStatus("#DE3D1B")
                  )}
                </div>
                <div
                  className="tab-pane h-100 fade"
                  id="user-trackreport"
                  role="tabpanel"
                  aria-labelledby="user-trackreport-tab"
                >
                  {singleUserInfo ? (
                    <TrackReport {...{ singleUserInfo, noOfWeek, bmi }} />
                  ) : (
                    colorStatus("#DE3D1B")
                  )}
                </div>
                <div
                  className="tab-pane h-100 fade"
                  id="user-diet"
                  role="tabpanel"
                  aria-labelledby="user-diet-tab"
                >
                  <DietSetup {...{ singleUserInfo }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default SingleUser;
