import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import style from "../style";
import { useFieldArray } from "react-hook-form";
import FoodGroups from "./FoodGroups";
import Select from "react-select";
import foodGroupApi from "../dietManagement/service/foodGroups";
import foodItemApi from "../dietManagement/service/foodItems";
function Groups({
  foodListRemove,
  control,
  register,
  setValue,
  getValues,
  errors,
  selectedGroupId,
}) {
  const [foodGroups, setFoodGroups] = useState([]);
  const { fields, append, remove, prepend, update } = useFieldArray({
    control,
    name: "groups",
  });
  useEffect(() => {
    (async () => {
      const foodGroups = await foodGroupApi.getAll();
      if (foodGroups && Array.isArray(foodGroups)) {
        setFoodGroups(
          foodGroups.map((x) => {
            return { label: x.name, value: x.id };
          })
        );
      }
    })();
  }, []);
  const [fruitsList, setFruitsList] = useState(
    getValues(`groups`).map((x, inde) => {
      return {
        ...x,
        foodGroups: x.foodGroups?.map((y) => {
          return {
            label: y.name,
            value: y.id,
            data: y,
          };
        }),
      };
    })
  );
  function isNumberKey(evt)
	{
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode != 46 && charCode > 31 
		&& (charCode < 48 || charCode > 57))
		return false;
		return true;
	}
  return (
    <>
      {fields.map((item, groupIndex) => {
        if (groupIndex == selectedGroupId - 1)
          return (
            <Fragment key={groupIndex}>
              <div className="row justify-content-between">
                <div className="col-6">
                  <h4>Select Food Groups which needs to be assigned</h4>
                  <div className="section-check">
                    <label htmlFor="txtDietGroups" className="form-label">
                      Select Food Groups
                    </label>
                    <div className={`react-select-wrapper multi`}>
                      <Select
                        styles={style.react_select_style}
                        {...register(`groups.${groupIndex}.foodGroups`)}
                        name={`groups.${groupIndex}.foodGroups`}
                        options={foodGroups}
                        isMulti={true}
                        value={getValues(
                          `groups.${selectedGroupId - 1}.foodGroups`
                        )?.map((x) => {
                          return { label: x.name, value: x.id, data: x };
                        })}
                        onChange={(selected) => {
                          if (selected.length > 0) {
                            const groupOption = getValues(
                              `groups.${groupIndex}.foodGroups`
                            );

                            if (
                              selected &&
                              groupOption &&
                              selected.length < groupOption.length
                            ) {
                              const selectedIds = selected.map((x) => x.value);
                              const store = groupOption.filter((x) =>
                                selectedIds.includes(x.id)
                              );
                              setValue(
                                `groups.${groupIndex}.foodGroups`,
                                store
                              );
                            } else {
                              setValue(`groups.${groupIndex}.foodGroups`, [
                                ...groupOption,
                                {
                                  id: selected[selected.length - 1].value,
                                  name: selected[selected.length - 1].label,
                                  ...selected[selected.length - 1].data,
                                  foods: [],
                                },
                              ]);
                            }
                          } else {
                            setValue(`groups.${groupIndex}.foodGroups`, []);
                          }
                          // append({name: selected[selected.length -1].label, foods: []})
                          selected.length &&
                          selected.find((option) => option.value === "all")
                            ? setFruitsList(foodGroups.slice(1))
                            : !true
                            ? setFruitsList(
                                (selected && selected.value) || null
                              )
                            : setFruitsList(selected);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <h4>Water</h4>
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="txtPerGlass" className="form-label">
                        Ounces per Glass
                      </label>
                      <input
                        {...register(`ouncesPerGlass`, {
                          required: getValues(`groups.${groupIndex}.foodGroups`)
                            .length
                            ? "Ounces Per Glass is required"
                            : false,
                          min: {
                            value: 0,
                            message: "Please Input positive Value",
                          },
                        })}
                        min={0}
                        type="number"
                        className="form-control"
                        name={`ouncesPerGlass`}
                        id="txtPerGlass"
                      />
                      <p className="text-danger">
                        {errors &&
                          errors.ouncesPerGlass &&
                          errors.ouncesPerGlass.message}
                      </p>
                    </div>
                    <div className="col-6">
                      <label htmlFor="txtTotalGlass" className="form-label">
                        Total Glass
                      </label>
                      <input
                        {...register(`totalGlass`, {
                          required: getValues(`groups.${groupIndex}.foodGroups`)
                            .length
                            ? "Total Glass is required"
                            : false,
                          min: {
                            value: 0,
                            message: "Please Input positive Value",
                          },
                        })}
                        min={0}
                        type="number"
                        className="form-control"
                        name={`totalGlass`}
                        id="txtTotalGlass"
                      />
                      <p className="text-danger">
                        {errors &&
                          errors.totalGlass &&
                          errors.totalGlass.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="diet-foodItems">
                {getValues(`groups.${groupIndex}.foodGroups`) &&
                getValues(`groups.${groupIndex}.foodGroups`).length ? (
                  <>
                    <h4>Add Food Items to particular Food Groups</h4>
                    <div className="row">
                      <FoodGroups
                        {...{
                          setFruitsList,
                          selectedGroupId,
                          groupIndex,
                          control,
                          register,
                          defaultValues: getValues(
                            `groups.${groupIndex}.foodGroups`
                          ),
                          getValues,
                          setValue,
                          errors,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Fragment>
          );
      })}
    </>
  );
}

export default Groups;
