import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import templateApi from "./api";
import Loader from "react-js-loader";
function TemplateList() {
  const [startLoader, setStartLoader] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  useEffect(() => {
    getTemplateList();
  }, []);

  const getTemplateList = async () => {
    try {
      setStartLoader(true)
      const data = await templateApi.getAll();
      setStartLoader(false)
      console.log(data);
      if (data && Array.isArray(data)) {
        console.log(data);
        setTemplateList(data);
      }
      // reset(data);
    } catch (err) {
      setStartLoader(false)
      console.log(err);
    }
  };
  return (
    <>
      <main id="main">
        <div className="page-title">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">Exercise Management</a>
              </li>
              <li className="breadcrumb-item active">All Templates</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h2>All Templates</h2>
                </div>
                <div className="card-body p-0">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {templateList.length > 0 ? (
                        templateList.map((x, index) => (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>
                              <div className="tdAction">
                                <Link
                                  to="/add-template"
                                  state={x}
                                  className="btn-round btn-edit"
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className="p-5 text-center">
                            {startLoader ? <Loader
                              type="spinner-circle"
                              bgColor={"#D98669"}
                              color={"#D98669"}
                              size={100}
                            />:<>No Data</>}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default TemplateList;
