// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyCJW6OsYSapeS_5MYJ8-_0zejk9JeFV7j4",
//   authDomain: "dev-ninemoons.firebaseapp.com",
//   projectId: "dev-ninemoons",
//   storageBucket: "dev-ninemoons.appspot.com",
//   messagingSenderId: "697997073843",
//   appId: "1:697997073843:web:e0e7506f24b5018f443fc6",
//   measurementId: "G-1WD146F365",
// };

// ninemoon production
const firebaseConfig = {
  apiKey: "AIzaSyARFD7slGONca60-z7L2vOO_ry7TkxC0PQ",
  authDomain: "ninemoons-196e5.firebaseapp.com",
  projectId: "ninemoons-196e5",
  storageBucket: "ninemoons-196e5.appspot.com",
  messagingSenderId: "77600571036",
  appId: "1:77600571036:web:00c04e15a215b69a9d3759",
  measurementId: "G-54M8Q4L7M6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

const storage = getStorage(app);
// connectFunctionsEmulator(functions,'192.168.1.22','5001')
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const gAuth = new GoogleAuthProvider();
const eAuth = new EmailAuthProvider();
export {
  auth,
  app,
  db,
  storage,
  functions,
  gAuth,
  eAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
};
