import React, { forwardRef } from "react";
import { useFieldArray } from "react-hook-form";
import NestedArray from "./nestedFieldArray";
import { addExerciseSidePanel, isNumberKey } from "../theme";
import { timesCalculate } from "./timesCalculate";
import { timeValidator } from "../timeValidator";
let renderCount = 0;

const FormArray = forwardRef(
  ({
    control,
    register,
    setValue,
    getValues,
    errors,
    videoListRemove,
    setExercise,
  }) => {
    const { fields, append, remove, prepend, update } = useFieldArray({
      control,
      name: "exercises",
    });
    return (
      <>
        {fields.map((item, exerciseIndex) => {
          let time = "00:00:00";
          for (
            let i = 0;
            i < getValues().exercises[exerciseIndex].videoList.length;
            i++
          ) {
            if (getValues().exercises[exerciseIndex].videoList[i].video)
              time = timesCalculate(
                time,
                "00:" +
                  getValues().exercises[exerciseIndex].videoList[i].video.time
              );
          }
          // let data = (getValues().exercises[exerciseIndex].estimated_time =
          //   time);
          // setValue({ exercises: [...data] });
          // update(exerciseIndex,{...item, estimated_time: time });

          return (
            <div
              className="col-xxl-3 col-xl-6 col-lg-6 d-flex d-flex flex-wrap"
              key={exerciseIndex}
            >
              <div className="card w-100">
                <div className="card-header">
                  <h2>Exercise Type</h2>
                  <input
                    name={`exercises.${exerciseIndex}.name`}
                    {...register(`exercises.${exerciseIndex}.name`, {
                      required: getValues().exercises[exerciseIndex].videoList
                        .length
                        ? "Name is required"
                        : false,
                    })}
                    type="text"
                    className="form-control"
                    defaultValue={item.name}
                    id="txtExerciseType01"
                  />
                  <p className="text-danger">
                    {errors &&
                    errors.exercises &&
                    errors.exercises[exerciseIndex]
                      ? errors.exercises[exerciseIndex].name?.message
                      : ""}
                  </p>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="exercise_need" className="form-label">
                      What will you need
                    </label>
                    <input
                      name={`exercises.${exerciseIndex}.exercise_need`}
                      {...register(`exercises.${exerciseIndex}.exercise_need`)}
                      placeholder="Enter what will you need"
                      type="text"
                      className="form-control"
                      defaultValue={item.exercise_need}
                      id="txtWarmUpNeed"
                    />
                    <p className="text-danger">
                      {errors &&
                      errors.exercises &&
                      errors.exercises[exerciseIndex]
                        ? errors.exercises[exerciseIndex].exercise_need?.message
                        : ""}
                    </p>
                  </div>
                  <div className="row g-2">
                    <div className="col-6">
                      <div className="mb-3">
                        <label htmlFor="estimated_time" className="form-label">
                          Estimated Time
                        </label>
                        <input
                          readOnly
                          name={`exercises.${exerciseIndex}.estimated_time`}
                          {...register(
                            `exercises.${exerciseIndex}.estimated_time`,
                            {
                              pattern: {
                                value:
                                  /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/,
                                message: "Estimated Time is required",
                              },
                            }
                          )}
                          type="text"
                          className="form-control"
                          value={time}
                          defaultValue={item.estimated_time}
                          id="txtWarmUpEstTime"
                        />
                        <p className="text-danger">
                          {errors &&
                          errors.exercises &&
                          errors.exercises[exerciseIndex]
                            ? errors.exercises[exerciseIndex].estimated_time
                                ?.message
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label">SET</label>
                        <input
                          name={`exercises.${exerciseIndex}.set`}
                          {...register(`exercises.${exerciseIndex}.set`, {
                            pattern: {
                              value: /^([0-5]?[0-9])$/,
                              message: "Enter only number",
                            },
                            required: getValues().exercises[exerciseIndex]
                              .videoList.length
                              ? "Set is required"
                              : false,
                          })}
                          type="text"
                          className="form-control"
                          placeholder="set no.of set"
                          onKeyDown={(e) => isNumberKey(e)}
                          maxLength="2"
                          defaultValue={item.set}
                        />
                        <p className="text-danger">
                          {errors &&
                          errors.exercises &&
                          errors.exercises[exerciseIndex]
                            ? errors.exercises[exerciseIndex].set?.message
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2">
                    <div className="col-6">
                      <div className="mb-3">
                        <label htmlFor="set_rest_time" className="form-label">
                          Set Rest Time
                        </label>
                        <input
                          name={`exercises.${exerciseIndex}.set_rest_time`}
                          {...register(
                            `exercises.${exerciseIndex}.set_rest_time`,
                            {
                              required: getValues().exercises[exerciseIndex]
                                .videoList.length
                                ? "Set Rest Time is required"
                                : false,
                              pattern: {
                                value: /^([0-5]?[0-9]):([0-5]?[0-9])$/,
                                message: "Set Rest Time is required",
                              },
                            }
                          )}
                          maxLength={5}
                          onKeyUp={timeValidator}
                          type="text"
                          placeholder="--:--"
                          className="form-control"
                          defaultValue={item.set_rest_time}
                          id="txtSetresttime01"
                        />
                        <p className="text-danger">
                          {errors &&
                          errors.exercises &&
                          errors.exercises[exerciseIndex]
                            ? errors.exercises[exerciseIndex].set_rest_time
                                ?.message
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <ul className="list-group list-group-flush list-exercise">
                    <NestedArray
                      videoListRemove={videoListRemove[exerciseIndex]}
                      nestIndex={exerciseIndex}
                      {...{ control, register, errors, update }}
                    />
                  </ul>

                  <div className="text-center mt-5">
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary btnAddExercise"
                      onClick={(e) => {
                        setExercise(exerciseIndex);
                        addExerciseSidePanel(e);
                      }}
                    >
                      <i className="fas fa-plus me-2"></i> Add Exercise
                    </a>
                  </div>
                </div>
              </div>
              <div className="card w-100">
                <div className="card-body">
                  <label htmlFor="circuit_rest_time" className="form-label">
                    Circuit Rest Time
                  </label>
                  <input
                    name={`exercises.${exerciseIndex}.circuit_rest_time`}
                    type="text"
                    placeholder="--:--"
                    className="form-control"
                    maxLength={5}
                    defaultValue={item.circuit_rest_time}
                    id="txtCircuitresttime01"
                    {...register(
                      `exercises.${exerciseIndex}.circuit_rest_time`,
                      {
                        required: getValues().exercises[exerciseIndex].videoList
                          .length
                          ? "Circuit Rest Time is required"
                          : false,
                        pattern: {
                          value: /^([0-5]?[0-9]):([0-5]?[0-9])$/,
                          message: "Circuit Rest Time is required",
                        },
                      }
                    )}
                    onKeyUp={timeValidator}
                  />
                  <p className="text-danger">
                    {errors &&
                    errors.exercises &&
                    errors.exercises[exerciseIndex]
                      ? errors.exercises[exerciseIndex].circuit_rest_time
                          ?.message
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
);

export default FormArray;
