import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { getAllUsers, getUserById } from "./api";
import Loader from "react-js-loader";
function UserList() {
  const [users, setUsers] = useState([]);
  const [startLoader, setStartLoader] = useState(true);
  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    setStartLoader(true);
    getAllUsers()
      .then((x) => {
        setStartLoader(true);
        console.log(x);
        if (x.data && Array.isArray(x.data.users)) {
          setUsers(x.data.users);
        }
      })
      .catch((err) => {
        setStartLoader(true);
        console.log(err);
      });
  };
  return (
    <>
      <main id="main">
        <div className="page-title">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">User Management</a>
              </li>
              <li className="breadcrumb-item active">All Users</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h2>All Users</h2>
                </div>
                <div className="card-body p-0">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col" className="text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.length > 0 ? (
                        users.map((x) => (
                          <tr key={x.uid}>
                            <td>
                              <Link
                                to="/single-user"
                                state={x}
                                className="btn-round btn-view"
                              >
                                <h2 className="tdUserName">{x.displayName}</h2>
                              </Link>{" "}
                            </td>
                            <td>
                              <Link
                                to="/single-user"
                                state={x}
                                className="btn-round btn-view"
                              >
                                {x.email}
                              </Link>{" "}
                            </td>

                            <td>
                              <div className="tdAction">
                                <Link
                                  to="/single-user"
                                  state={x}
                                  className="btn-round btn-view"
                                >
                                  <i className="fa-regular fa-eye"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} className="p-5">
                            <Loader
                              type="spinner-circle"
                              bgColor={"#D98669"}
                              color={"#D98669"}
                              size={100}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default UserList;
