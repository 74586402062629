import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  gAuth,
  auth,
  eAuth,
  createUserWithEmailAndPassword,
} from "../firebase-config";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import {
  Auth,
  AuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

function SignUp() {
  let navigate = useNavigate();
  const [user, setUser] = useState();
  const handleClick = () => {
    signInWithPopup(auth, gAuth).then((data) => {
      console.log(data.user);
      localStorage.setItem("authUser", JSON.stringify(data.user));
      navigate('/plans')
      
    });
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const notify = (message, type) => {
    switch (type) {
      case 1:
        toast(message, {
          style: {
            border: "1px solid #badbcc",
            padding: "10px 15px",
            color: "#0f5132",
            backgroundColor: "#d1e7dd",
          },
        });
        break;
      case 2:
        toast.success(message, {
          style: {
            border: "1px solid #badbcc",
            padding: "10px 15px",
            color: "#0f5132",
            backgroundColor: "#d1e7dd",
          },
        });
        break;
      default:
        toast.error(message);
    }
  };
  const onSubmit = async (values) => {
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((data) => {
        const user = data.user;
        // navigate("/");
        
        localStorage.setItem("authUser", JSON.stringify(user));
        window.location.reload(); 
        notify("Welcome", 1);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if ("auth/email-already-in-use" == errorCode) {
          signInWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              // navigate("/");
              localStorage.setItem("authUser", JSON.stringify(user));
              window.location.reload(); 
              notify("Welcome", 1);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              notify("Username or Password Wrong", 0);
              console.log(errorCode, errorMessage);
            });
        } else notify("Username or Password Wrong", 0);
        console.log(errorCode, errorMessage);
      });
  };
  return (
    <>
      {/* <style>
	@font-face 	{ "font-family": "Canela Light"; src: url("assets/fonts/Canela-Light.otf"); }
	@font-face	{ "font-family": "GT America Light"; src: url("assets/fonts/GT-America-Light.otf"); }

	</style> */}
      <main className="frontend-user">
        <div className="container">
          <Toaster />
          <section className="min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 frontend-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="forntend-card">
                    <div className="d-flex justify-content-center py-4">
                      <a
                        href="javascript:void(0)"
                        className="logo d-flex align-items-center w-auto"
                      >
                        <img src="assets/images/nine.moons.svg" alt="" />
                      </a>
                    </div>

                    <div className="title-box">
                      <h1>Let’s get started</h1>
                      <p>We need to make sure we are licensed in your state.</p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <input
                            {...register("email", {
                              required: "Email Address is required",
                            })}
                            type="email"
                            name="email"
                            className="form-control"
                            id="txtLoginEmail"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <div className="col-12 mb-5">
                          <input
                            {...register("password", {
                              required: "Password Address is required",
                            })}
                            type="password"
                            name="password"
                            className="form-control"
                            id="txtLoginPassword"
                            placeholder="Password"
                            required
                          />
                        </div>

                        <div className="col-12">
                          <button className="btn btn-secondary" type="submit">
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="sso-login">
                      <h2>OR</h2>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <a
                          className="btn btn-google"
                          href="javascript:void(0)"
                          onClick={() => handleClick()}
                        >
                          <i className="fab fa-google"></i> Sign up with Google
                        </a>
                        {/* <a className="btn btn-apple" href="javascript:void(0)">
                          <i className="fab fa-apple"></i> Sign up with Apple
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default SignUp;
