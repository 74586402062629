import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
function SingleExercise() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Exercise Management</a>
            </li>
            <li className="breadcrumb-item active">Exercise Details</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="row justify-content-center">
          <div className="col-xxl-6 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h2>{location.state.title}</h2>
              </div>
              <div className="card-body">
                <div className="exercise-video ratio ratio-16x9">
                  <ReactPlayer
                    url={location.state.videoUrl}
                    playing={false}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="exercise-description">
                  <p>{location.state.description}</p>
                </div>
                <div className="exercise-details">
                  <div className="exercise-details-row">
                    <h2>Duration</h2>
                    <p>{location.state.time} Min</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <a
                href="javascript:void(0);"
                className="btn btn-primary w-50"
                onClick={() => {
                  navigate("/all-exercises");
                }}
              >
                Back
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default SingleExercise;
