export const Nutrition_content =
  "https://firebasestorage.googleapis.com/v0/b/dev-ninemoons.appspot.com/o/Nutrition_content.json?alt=media&token=ce9b135e-a36a-439c-92c8-d2d2b4e80a73";
export const Nutrition_AI_RDA =
  "https://firebasestorage.googleapis.com/v0/b/dev-ninemoons.appspot.com/o/nutrition_AI_RDA.json?alt=media&token=b57bf850-3eab-4308-8764-63de3e6fc622";
export const Prental_vitamins =
  "https://firebasestorage.googleapis.com/v0/b/dev-ninemoons.appspot.com/o/Prenatal_vitamins.json?alt=media&token=98ee469e-eb28-43bb-b8fa-871ae7abeddb";

export const sample = [
  {
    trait_code: "achilles-tendinopathy",
    trait: {
      code: "achilles-tendinopathy",
      name: "Achilles Tendinopathy",
      short_description:
        "Achilles tendon injury affects close to 20% of athletes. Certain genetic variations increase the risk of achilles tendinopathy.",
      description:
        "Achilles tendon injury includes degenerative and painful conditions that affect athletes in various sports, including up to 20% of runners. This is a multifactorial condition for which various genetic risk factors have been identified. Genetic variants in the FBGN2, TNC, and ADAMTS14 (metallopeptidase with thrombospondin) gene contribute to the risk of achilles tendinopathy, while genetic variants in the MMP3 have been found to be protective.",
      connotation: "risky",
    },
    assessment: {
      score: 2.0,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 8,
      snps_found: 6,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Always use proper footwear when training or exercising. Always stretch before working out to lower the risk of an injury. Never exercise on hard or sloped surfaces. Never exercise in cold weather, as it will exacerbate the risk of an Achilles tendon injury. Take it easy on days when your pain is higher, and be sure to take enough days of rest. Avoid high-impact sports and focus on strengthening your muscles to avoid injury risk. This especially applies to any sports that involve repeated jumping, which places more strain on your Achilles tendon.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "aerobic-performance",
    trait: {
      code: "aerobic-performance",
      name: "Aerobic Performance",
      short_description:
        "Your maximal aerobic capacity is the maximum amount of oxygen uptake that occurs during exercise. Higher aerobic performance is influenced by genetics.",
      description:
        "VO2 max (maximal aerobic capacity) is the maximum oxygen uptake rate measured during incremental exercise, most typically on a motorized treadmill. Maximal oxygen uptake reflects the aerobic physical fitness of the individual and is an essential determinant of their endurance capacity during prolonged, submaximal exercise. The benefits of aerobic fitness are lower blood pressure, lower cholesterol, and less risk of obesity, Type II diabetes, and heart disease.",
      connotation: "good",
    },
    assessment: {
      score: 1.5,
      level: 2,
      percentile: 75.0,
    },
    coverage: {
      lookup_snps: 7,
      snps_found: 7,
      inhibiting_snps: 1,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Work out in your target heart rate zone to make the most of your aerobic capacity. Always gradually increase the intensity and duration of your exercises over time. Do interval training to significantly improve your VO2 max. Run faster than you usually do if you want to train your aerobic capacity. Ideally, you should be cutting down your normal mile time by 10 to 30 seconds. Remember that your goal is to do more strenuous exercises with the same amount of oxygen uptake capacity. Incorporate aerobic exercises such as rowing, climbing stairs, cycling, elliptical, walking, running, or swimming into your daily routine.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-cockroaches",
    trait: {
      code: "allergy-cockroaches",
      name: "Allergy to Cockroaches",
      short_description:
        "The saliva, feces, and body parts of cockroaches contain a protein that triggers allergies in some people with certain genetic variations.",
      description:
        "Cockroaches live in many locations worldwide, in all types of buildings and all kinds of neighborhoods. The saliva, feces, and shedding body parts of cockroaches contain a protein that can trigger allergies and even asthma in some people. A cockroach allergy is a trigger of year-round allergy and asthma. Common cockroach allergy symptoms include runny, stuffy, or itchy nose, itchy or red eyes, skin rash, cough, and mucus. People with some genetic variations have a higher risk of developing allergies to cockroaches.",
      connotation: "risky",
    },
    assessment: {
      score: 2.0,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 6,
      snps_found: 4,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Fix leaky pipes under the sinks and in the basement to reduce cockroach access. Store food in airtight containers to keep cockroaches away. Use cockroach baits and traps to minimize cockroaches. Keep tables and floors free from crumbs and cover trash cans to avoid attracting cockroaches. Clean up stray cardboard and paper to reduce hiding spots for cockroaches. Don't use sprays, which can trigger allergies and asthma. If you suspect that you have a cockroach infestation, be sure to contact an exterminator immediately.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-dust-mites",
    trait: {
      code: "allergy-dust-mites",
      name: "Allergy to Dust Mites",
      short_description:
        "Dust mites are microscopic creatures that can be found in house dust. More than 10% of the population has a genetic risk for this allergy.",
      description:
        "Dust mites are microscopic creatures related to ticks and spiders that live in house dust. The proteins in dust mite body parts and feces cause allergic reactions in some people. Dust allergy symptoms are similar to pollen allergies and include red, itchy, watery eyes, runny, stuffy nose, and sneezing. These symptoms persist all year round and feel like an endless cold or even asthma. Several genetic variations have been found to be associated with an increased risk of allergy to dust mites.",
      connotation: "risky",
    },
    assessment: {
      score: 6.5,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 11,
      snps_found: 11,
      inhibiting_snps: 0,
      contributing_snps: 8,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Use dust-mite-proof covers on your mattresses and pillows with synthetic fillings. Wash your bedding in hot water every week, but avoid steam-cleaning bedding, upholstery, and curtains: increased humidity fosters future mite growth. Use natural fiber rugs that can be cleaned easily or hardwood, tile, or linoleum flooring, which helps you avoid dust mites. Use a vacuum cleaner with HEPA (high-efficiency particulate air) filter, or invest in the HEPA air purifier to remove allergens. Cut clutter, as anywhere that dust can gather is a place where dust mites can gather. Mop up hard floors (such as hardwood, tile, or linoleum) once a day to remove dust.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-eggs",
    trait: {
      code: "allergy-eggs",
      name: "Allergy to Eggs",
      short_description:
        "For people with certain genetic variations, the immune system may see the egg protein as a foreign invader and attack it, causing allergies.",
      description:
        "The body's immune system may see the egg protein as a foreign invader and attack it, causing an allergic reaction. Egg allergy symptoms usually occur a few minutes to a few hours after eating eggs or foods containing eggs or even touching eggs for some people. Symptoms vary from mild to severe and can include skin rashes, hives, nasal congestion, vomiting, or other digestive problems. Eggs are one of the most common allergy-causing foods for children. Several genetic variations are associated with an increased risk of egg allergy.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 0,
      percentile: 60.0,
    },
    coverage: {
      lookup_snps: 5,
      snps_found: 5,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Products that might contain eggs include cakes, ice cream, and mayonnaise. Your allergy may not react the same way to all kinds of eggs — you may be more sensitive to some than others. Some skincare products may contain egg proteins.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-milk",
    trait: {
      code: "allergy-milk",
      name: "Allergy to Milk",
      short_description:
        "Milk allergy (different from lactose intolerance) can result in delayed reaction hours after drinking milk. Genetics is a significant contributor.",
      description:
        "Milk allergy symptoms (different from lactose intolerance) occur a few minutes to a few hours after drinking or eating milk products. Immediately after consuming milk, signs, and symptoms of a milk allergy might include: hives, wheezing, and vomiting. Signs and symptoms that may take longer to develop include loose stools containing blood, diarrhea, abdominal cramps, coughing or wheezing, runny nose, watery eyes, and itchy skin rash. Genetic variations associated with milk allergy have recently been identified in extensive studies.",
      connotation: "risky",
    },
    assessment: {
      score: 2.5,
      level: 0,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 8,
      snps_found: 8,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Products that might contain milk include pastries, cakes, and butter. There are a variety of milk substitutes, such as oat milk, coconut milk, or soy milk. Not all animal milk contains the same proteins - goat's milk or sheep's milk can be safer alternatives.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-mold",
    trait: {
      code: "allergy-mold",
      name: "Allergy to Mold",
      short_description:
        "Mold are fungi that thrive both outside and inside a home. Genetic variations associated with allergies to mold fungus are assessed here.",
      description:
        "Mold are fungi that thrive both outside (in logs, fallen leaves, compost piles, grasses) and inside (in moist places like the bathroom, kitchen, basement). There are many different types of mold; some are visible by eye, others are not. Molds make spores that float in the air like pollen. But unlike plants that produce pollen, mold does not die with the first frost. Genetic variations associated with allergies to two types of mold fungus (Cladosporium and Alternaria) have been identified.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 1,
      percentile: 75.0,
    },
    coverage: {
      lookup_snps: 3,
      snps_found: 3,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Try to keep humidity low to deal with potential mold. Consider cleaning out your vents and opening your windows to keep good airflow. Check out potential sources of moisture, such as broken pipes or leaky faucets. Think about using an air conditioner to install central air conditioning with a high-efficiency particulate air (HEPA) filter attachment. Take inventory of old books and newspapers — if left in damp places, such as basements, they can quickly become moldy.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-peanuts",
    trait: {
      code: "allergy-peanuts",
      name: "Allergy to Peanuts",
      short_description:
        "For people with peanut allergies, the reaction may be triggered by the slightest exposure. Genetics accounts for more than 80% of the risk.",
      description:
        "Peanut allergy is one of the most common food allergies. Approximately three million people report allergies to peanuts and tree nuts. Even the slightest exposure may trigger peanut allergies, and they are challenging to manage because peanut is used in a variety of food products. Peanut allergy symptoms vary from a slightly itchy mouth or throat to nausea and congested nose to less common anaphylaxis, which is a potentially life-threatening reaction. Researchers estimate that genetics may account for up to 80% of peanut allergies.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 0,
      percentile: 45.0,
    },
    coverage: {
      lookup_snps: 5,
      snps_found: 5,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Checking ingredient lists can be a good way of avoiding accidental consumption of peanuts. Be aware of cross-contamination — even using a cutting board that had peanuts on it can be dangerous. Keeping an epinephrine injection device around can potentially be a lifesaver in the case of an allergic reaction.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "allergy-pets",
    trait: {
      code: "allergy-pets",
      name: "Allergy to Pets",
      short_description:
        "Pet allergy is an allergic reaction to proteins found in an animal's skin cells, saliva, or urine. The allergy is primarily genetic in nature.",
      description:
        "Pet allergy is an allergic reaction to proteins found in an animal's skin cells, saliva, or urine. Signs of pet allergy include those common to hay fever, such as sneezing and runny nose. Some people may also experience signs of asthma, such as wheezing and difficulty breathing. Most often, pet allergy is triggered by the dead flakes of skin a pet sheds. Any animal with fur can be a source of pet allergy, but pet allergies are most commonly associated with cats and dogs (as well as horses and rats). Genetic variations in some genes have been associated with allergies to pets.",
      connotation: "risky",
    },
    assessment: {
      score: 3.0,
      level: 1,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 6,
      snps_found: 6,
      inhibiting_snps: 0,
      contributing_snps: 4,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Be careful around long-haired dogs and cats, as they are more likely to trigger your allergy. If you have a pet, bathing them more often can help get rid of dander. Try to thoroughly wash clothes, bedding, and pillowcases to minimize danger. If you are considering buying or adopting a pet, you should see how your allergy reacts before committing. If you experience symptoms of an allergy after pet exposure, you may want to contact your doctor for a diagnostic test.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "anterior-cruciate-ligament-rupture",
    trait: {
      code: "anterior-cruciate-ligament-rupture",
      name: "Anterior Cruciate Ligament Rupture",
      short_description:
        "Anterior cruciate ligament ruptures are among the most severe sports injuries. Genetic variations can influence the risk of such ruptures.",
      description:
        "Anterior cruciate ligament (ACL) ruptures are considered the most severe joint injury in sports and are very common in a sporting population. Athletes who participate in high-demand sports like soccer, football, and basketball are more likely to injure their anterior cruciate ligaments. Athletes playing sports with a sudden deceleration of the body from jumping and forward running while the knee is in a shallow flexion angle are more likely to get injured. ACL injuries are not common among athletes involved in endurance sports, such as running, swimming, and biking.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 1,
      percentile: 90.0,
    },
    coverage: {
      lookup_snps: 8,
      snps_found: 8,
      inhibiting_snps: 3,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Consider exercising your balance, agility, and strength to minimize the risk of an ACL injury. Warm-up before you play to get your blood flowing to your joints, reducing the risk of strain. Try to wear proper footwear and padding to avoid potential injuries. Stretching your legs, specifically, the rotation of your ankles and the fronts and backs of your thighs, can reduce the risk of rupturing your ACL. If you experience an ACL rupture, meet with a sports medicine specialist immediately.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "birch-pollen-allergy-resistance",
    trait: {
      code: "birch-pollen-allergy-resistance",
      name: "Birch Pollen Allergy Resistance",
      short_description:
        "Persons with this genetic advantage are more likely to be resistant to birch pollen allergy.",
      description:
        "Some people have genetic variations that make them more resistant to birch pollen allergies. In other words, people with this genetic variation have fewer incidents of allergies to birch (Betulaceae). Birch pollen is very allergenic pollen and is one of the primary triggers of hay fever. In people with allergies, it can also cause an asthma attack, conjunctivitis, and oral allergy syndrome that causes an itchy mouth after eating raw fruits, vegetables, or nuts. Genetics accounts for more than 60% of birch pollen allergies.",
      connotation: "good",
    },
    assessment: {
      score: 0.5,
      level: 0,
      percentile: 65.0,
    },
    coverage: {
      lookup_snps: 3,
      snps_found: 3,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Wear a mask or scarf over your face can keep pollen away. Keeping your windows and doors shut during peak pollen times can also ward off pollen. A dab of Vaseline just inside your nose can trap pollen, preventing an allergic reaction.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "bitter-taste-sensitivity",
    trait: {
      code: "bitter-taste-sensitivity",
      name: "Bitter Taste Sensitivity",
      short_description: "",
      description:
        "Taste perception plays a fundamental role in our dietary preferences and behaviors, by shaping aversions (or cravings) to foods and drinks.  People with genetic predispositions for bitter taste sensitivity may avoid bitter vegetables that are beneficial (like broccoli, Brussels sprouts, cabbage). A new study found that people who are naturally more sensitive to bitterness are more likely to add sodium to their food, often exceeding recommended daily dietary guidelines.",
      connotation: "risky",
    },
    assessment: {
      score: 1.5,
      level: 1,
      percentile: 50.0,
    },
    coverage: {
      lookup_snps: 4,
      snps_found: 4,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You have known genetic variations in bitter taste receptors that slightly increase the likelihood of bitter taste sensitivity.\nIf you feel this is the case and you are avoiding dark leafy green vegetables, in particular broccoli or Brussels sprouts, try using lightly sweetened sauces or dressings (such as honey glaze) when cooking them.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "caffeine-metabolism-impairment",
    trait: {
      code: "caffeine-metabolism-impairment",
      name: "Caffeine Metabolism Impairment",
      short_description: "",
      description:
        "If you're pregnant, you may want to limit caffeine to 200 milligrams each day. But if you are one of the people who are slow metabolizers of caffeine based on their CYP1A2 gene, you be hypersensitive to small amounts of caffeine of 100 mg or less. If you experience insomnia, jitters, and an increased heartbeat when consuming small amounts of caffeine, you may be a slow metabolizer.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 1,
      percentile: 90.0,
    },
    coverage: {
      lookup_snps: 1,
      snps_found: 1,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You seem to have some known genetic predispositions for slow caffeine metabolism and have a slightly increased likelihood to be hyper sensitive to caffeine.\nIf this is indeed the case and  recommend that you limit your coffee intake to one cup of coffee or one can of energy drink per day, taken early in the day. This is particularly true if you have experienced insomnia, jitters, or an increased heartbeat after drinking coffee.\nIf you feel jittery with even one cup of coffee you can substitute your daily coffee with up to 2 cups of black tea or green tea.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "calcium-deficiency",
    trait: {
      code: "calcium-deficiency",
      name: "Calcium Deficiency",
      short_description: "",
      description:
        "Calcium is important for the growth of strong bones and teeth. Calcium intake is especially critical for pregnant women, younger than 25 years old, whose bones are still growing. Absorption increases during pregnancy to meet fetal demands, therefore many calcium-deficient women have issues with their teeth and nails during pregnancy. Pregnant women with inadequate consumption of calcium are at greater risk of osteoporosis later in life. For this trait we assess whether you have a genetic predisposition that may impair your calcium processing in the body.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 1,
      percentile: 65.0,
    },
    coverage: {
      lookup_snps: 11,
      snps_found: 11,
      inhibiting_snps: 3,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You seem to have known predispositions that would suggest slightly increased likelihood for impairments of calcium processing. This means your recommended level of calcium intake is slightly higher than the minimum guidelines.\nYou should consider eating foods rich in calcium. These include fortified foods (milk, beverages, and cereals), dairy products (milk, kefir, Greek yogurt, hard cheeses), green leafy vegetables, sardines and salmon (with soft bones), legumes and beans.\nAn interesting fact most people may not know is calcium absorption is helped through adequate vitamin D intake.\nThe 9Moons app has designed the right combination of foods and meal plans personalized for you to ensure you get adequate intake of daily calcium and vitamin D.\nIf choosing to supplement with a prenatal vitamin, make sure it has adequate amounts of calcium, listed in the nutrition facts label.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "carb-overconsumption",
    trait: {
      code: "carb-overconsumption",
      name: "Carb Overconsumption",
      short_description: "",
      description:
        "Carbohydrates serve as the primary energy source for the brain and its role as a source of energy for maintaining body weight. Individuals with specific genetic variations are more likely to crave carbs than others and may be drawn to consume higher level of carbs. This may result in over-consumption of carbs for such individuals. The recommended intake for carbohydrate intake is 45-65% of total for women who have a BMI <25. For women with BMIs higher than 25, a low-carb diet with 40% of total intake is recommended.",
      connotation: "risky",
    },
    assessment: {
      score: 1.5,
      level: 0,
      percentile: 40.0,
    },
    coverage: {
      lookup_snps: 10,
      snps_found: 9,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Happy to report that as far as we can tell, you do not have genetic predispositions that contribute to carb craving. However, if you see yourself craving carbs during your pregnancy it may be due to the natural tendency of the body to crave foods rather than your genetics. In such a case be always aware to keep a watch on the amount of carbs you eat. The myth of eating for two at the time of pregnancy is dangerous for a healthy pregnancy, unless your pre-pregnacy BMI was lower than normal (BMI<19).",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "choline-deficiency",
    trait: {
      code: "choline-deficiency",
      name: "Choline Deficiency",
      short_description: "",
      description:
        "Choline's role in the human body is very nuanced and complex. Choline can be synthesized by the body in small amounts, but dietary intake is needed to maintain health and adequate intake levels.  For pregnant women, choline is essential for embryonic and fetal brain development, liver function, and placental function. Large amounts of choline are needed for fetal growth, and although one may be able to get enough choline by eating a varied diet, supplementation may be necessary as that is not always the case.",
      connotation: "risky",
    },
    assessment: {
      score: 2.5,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 6,
      snps_found: 6,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Our assessment indicates that you may have known predispositions that would suggest a need for higher choline intake. You may want to consider a slightly higher than recommended choline intake. Your 9Moons app will tell you exactly how much is needed in your specific case.\nThe following food sources are rich in choline: chicken and turkey liver, eggs, milk, peanuts, soy beans, legumes and enriched breakfast cereals. In addition, several fruits and vegetables, including avocado, onions, spinach, Brussels sprouts and broccoli.\nThe meal plans within your 9Moons app will ensure that you will get your choline needs are met without the need for additional supplementation.\nIf you are taking prenatal vitamins, make sure they contain choline.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "coenzymeQ10-deficiency",
    trait: {
      code: "coenzymeQ10-deficiency",
      name: "Coenzyme Q10 Deficiency",
      short_description: "",
      description:
        "Coenzyme Q10 (CoQ10) is a fat-soluble natural compound primarily synthesized by the body. It is also consumed in the diet. Coenzymes generally help enzymes to ensure biochemical reactions run smoothly. Taking a combination of nutrients that include CoQ10 has been found to benefit fertility and egg quality for those who are trying to become pregnant at higher ages. It is also shown that women with very low CoQ10 levels may experience higher likelihood of miscarriage.",
      connotation: "risky",
    },
    assessment: {
      score: 2.0,
      level: 2,
      percentile: 90.0,
    },
    coverage: {
      lookup_snps: 5,
      snps_found: 5,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You seem to have predispositions that may indicate a need for slightly higher CoQ10 intake. The recommended daily intake for you would be around 150 mg. CoQ10 is considered a well-tolerated and safe supplement, so there is less concern for taking too much, as there may be other nutrients. Always check with your healthcare provider about any possible interactions before supplementing.\nCQ10 can be found in organ meats such as heart, liver, and kidney, as well as beef, fish, soy and canola oils, peanuts, pistachios and sesame seeds. Follow 9Moons application guidelines to stay on track with your CQ10 consumption.\nResearch shows that CoQ10 has antioxidant benefits, it helps with heart disease, as well as skin appearance and fine wrinkles.\nSome medicines such as statins and anticoagulants may lower CoQ10 levels, so if you are on any such medication consider increasing your intake to 200 mg per day. Check with your healthcare provider to make sure this is the right option for you.\nIf you consider CoQ10 supplementation, seek out the ubiquinol form that is the preferred form of coenzyme Q10 in the blood. This form is best transported to the mitochondria, where it plays out its crucial role in the generation of energy from food.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "copper-deficiency",
    trait: {
      code: "copper-deficiency",
      name: "Copper Deficiency",
      short_description: "",
      description:
        "Copper is an essential trace mineral that has a number of important functions in the human body. It is a co-factor in many oxidation-reduction reactions and it plays important role in health of blood vessels, nerves, immune system, bones, and connective tissues (hair, skin, nails, tendons, ligaments). It is particularly crucial for expecting mothers, as it also supports the development of the baby's heart, blood vessels, as well as their nervous, immune and skeletal systems.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 0,
      percentile: 40.0,
    },
    coverage: {
      lookup_snps: 6,
      snps_found: 6,
      inhibiting_snps: 1,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You seem to be fine with copper intake and not have known predispositions that would need a higher intake. Follow the guidelines of the 9Moons application for getting your copper through foods. However, if you are taking high amounts of zinc, iron, or vitamin C, you may need to increase your copper intake.\nFood sources rich in copper that are safe for pregnancy include: whole grains, beans, nuts, avocado, potatoes. Dark leafy greens, black pepper, and yeast are also sources of copper in the diet. In addition, dried fruits, chocolate, cocoa are also rich with copper.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "difficulty-losing-weight",
    trait: {
      code: "difficulty-losing-weight",
      name: "Difficulty in Losing Weight",
      short_description: "",
      description:
        "The road to pregnancy naturally involves gaining weight, both because of the baby itself and due to the baby's nutritional needs. After delivery, different moms go back to their original weight at different rates. Moms with genetic variants associated with obesity, sensitivity to fat (FTO, PPARG), type 2 diabetes (TCF7L2) emotional eating (CLOCK, SIRT1) and food addiction (DRD2), do not lose weight as easily as other moms even with the same amount of calories and exercises. So if you are found to be among those with weight loss difficulty, you may want to be more patient for your weight to return to its normal level, or go easier on sweets and carbs post delivery to make your weight loss easier.",
      connotation: "risky",
    },
    assessment: {
      score: 3.0,
      level: 2,
      percentile: 80.0,
    },
    coverage: {
      lookup_snps: 11,
      snps_found: 11,
      inhibiting_snps: 1,
      contributing_snps: 5,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You seem to have a genetic-based predisposition towards weight loss difficulty, but there is no reason to worry. If you can restrict the amount of sugar and carbs that you eat post pregnancy, and engage in at least 45 minutes to 1 hour of exercise, you should see your pregnancy weight drop by half within 3 months of delivery.\nPatience will be key. Make sure to exercise. numerous studies show that diet alone may not be sufficient for long-term weight-loss. You may need to stay motivated longer to see the impact of your new diet and exercise plan.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "elite-endurance-athletes",
    trait: {
      code: "elite-endurance-athletes",
      name: "Elite Endurance Athletes",
      short_description: "",
      description:
        "Elite endurance athletes, such as distance runners, road cyclists and triathletes, excel in aerobic performance. They are more likely to have higher proportion of slow-twitch (type I) muscle fibers. In addition, elite endurance athletes have a superior cardiovascular system that maintains optimal control of heart rate, stroke volume, and blood pressure. Elite athletes are likely to have increased fat oxidation capacity and higher lactate threshold that ensures their muscles can function for longer periods of time. Assuming proper nutrition and training, athletic endurance is, in simplest terms, limited by a human's ability to extract oxygen from the environment and deliver it to muscle cells that use it to generate energy. Lifenome computes genetic predisposition likelihood for elite endurance based on 23 genetic variants, taking into account genetics-based endurance and aerobic performance scores.",
      connotation: "good",
    },
    assessment: {
      score: 8.5,
      level: 0,
      percentile: 60.0,
    },
    coverage: {
      lookup_snps: 23,
      snps_found: 21,
      inhibiting_snps: 1,
      contributing_snps: 13,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Working out in your target heart rate zone will increase your aerobic capacity. Gradually increasing the intensity and duration of your exercises over time can help you avoid injury. Examples of aerobic exercises that you can include in your daily routine are climbing stairs, speed walking, cycling, and running.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "endurance",
    trait: {
      code: "endurance",
      name: "Endurance",
      short_description:
        "Endurance is the ability to repeat an activity for longer times. People with this genetic advantage are best suited for endurance exercises.",
      description:
        "Your muscle endurance measures your ability to repeat an activity for an extended period of time without getting tired. If your muscle structure favors endurance, you have the potential to thrive in exercises that leverage your endurance. Cross-country skiing and push-ups are examples of endurance exercises. Studies have identified several genetic variants associated with a higher proportion of slow-twitch fibers and higher oxygen supplies to muscle tissues.",
      connotation: "good",
    },
    assessment: {
      score: 6.0,
      level: 1,
      percentile: 70.0,
    },
    coverage: {
      lookup_snps: 12,
      snps_found: 11,
      inhibiting_snps: 0,
      contributing_snps: 8,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "You should consider including aerobic exercises - such as rowing, climbing stairs, cycling, running, or swimming - into your routines to take advantage of your heightened endurance. Take advantage of interval training, which has been shown to improve endurance levels significantly. Try yoga and meditation, as they train your ability to handle stress, which helps you exercise longer. Listening to music while you work out can boost your cardiac efficiency, allowing you to reap bigger rewards from each session. You may want to meet with a fitness professional to create an exercise program that can take advantage of your endurance.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "exercise-aversion",
    trait: {
      code: "exercise-aversion",
      name: "Exercise Aversion",
      short_description: "",
      description:
        "Your motivation for physical activity is partly influenced by your genetics. How your body feels during a workout (e.g oxygen transportation and lung capacity), and how you are predispositioned to respond mentally to the challenge all come down to your genetic makeup. Studies indicate that up to 50% of your like or dislike for exercise comes down to your DNA. There is a difference between exercise aversion and a lack of motivation, where the former is a stronger inhibition factor. If you have specific genetic variations, it may result in a lower biological desire to engage in physical activity making exercise your least favorite activity.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 1,
      percentile: 80.0,
    },
    coverage: {
      lookup_snps: 16,
      snps_found: 16,
      inhibiting_snps: 2,
      contributing_snps: 4,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "If you don't like the gym, remember that it isn't the only way to work out - you can also practice yoga, take a dance class, go roller skating, take the dog on a hike or just dance in your living room to your favorite music. Try to make an exercise partner who will hold you accountable and make workouts more fun. Consider working out at the same time every day or week to help solidify it into a habit. Try to find comfortable workout clothes: it'll give your brain a cue that it's time to get into exercise mode as soon as you put them on. Not all workouts are equally enjoyable - consider trying several different kinds until you find one that works for you.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "exercise-benefits-blood-pressure",
    trait: {
      code: "exercise-benefits-blood-pressure",
      name: "Exercise Benefits for Blood Pressure",
      short_description:
        "Individuals differ in how much their blood pressure will decrease when they exercise, and the majority of it is due to genetics.",
      description:
        "For most people, moderate-intensity exercise has been shown to lower blood pressure. In fact, aerobic exercise training is generally recommended as lifestyle therapy to prevent, treat and control hypertension. General guidelines call for at least half an hour of low intensity aerobic exercises, which helps to decrease blood pressure. There is large variability in the individual response to the anti-hypertensive effect of exercise, and much of it is explained by genetic variations.",
      connotation: "good",
    },
    assessment: {
      score: 2.5,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 4,
      snps_found: 4,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Include aerobic exercises - such as rowing, climbing stairs, cycling, running, or swimming - into your routines to further lower your blood pressure. Get at least forty-five minutes of aerobic activity in a day, even if it just means taking a walk around the park or mowing the lawn. Take a break from sitting - evidence finds that a five minute walk every hour is crucial in lowering your blood pressure. Yoga or stretching is also a great way to break up your sitting sessions. Get a standing desk, which will prevent you from keeping a sedentary pose. Keep a balance between low energy and high energy aerobic activities: taking a walk is great, but you should be getting in at least an hour of something more intense, like running, cycling, or swimming, a week. Keep a food diary: taking stock of what you eat is a great first step in fixing your diet.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "exercise-benefits-lowering-cholesterol",
    trait: {
      code: "exercise-benefits-lowering-cholesterol",
      name: "Exercise Benefits for Lowering Cholesterol",
      short_description:
        "Exercise helps remove bad cholesterol from the blood. People with this genetic predisposition have a higher exercise benefit on cholesterol.",
      description:
        "Research has shown that exercise stimulates enzymes that help move bad cholesterol from the blood to the liver, allowing it to be excreted as bile. It is also stipulated that exercise increases the size of the protein particles that carry cholesterol through the blood, reducing the possibility that smaller particles may clog arteries. People with some genetic variants will have good results at increasing levels of good cholesterol by exercise alone, while carriers of other genetic variants are less likely to succeed by exercise alone.",
      connotation: "good",
    },
    assessment: {
      score: 1.0,
      level: 1,
      percentile: 80.0,
    },
    coverage: {
      lookup_snps: 4,
      snps_found: 4,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Consider including aerobic exercises into your routines to further lower your cholesterol. Try to get at least half an hour of aerobic activity in a day, even if it just means taking a walk around the park or mowing the lawn. Try finding an exercise buddy or joining an exercise group, as these will keep you motivated to keep working out. Try to keep a balance between low energy and high energy aerobic activities: taking a walk is great, but you should be getting in at least an hour of something more intense, like running, cycling, or swimming, a week. Consider keeping a food diary: taking stock of what you eat is a great first step in fixing your diet.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "exercise-benefits-maximal-oxygen-uptake",
    trait: {
      code: "exercise-benefits-maximal-oxygen-uptake",
      name: "Exercise Benefits for Maximal Oxygen Uptake Response",
      short_description: "",
      description:
        "VO2 max (also maximal oxygen consumption, maximal oxygen uptake, peak oxygen uptake or maximal aerobic capacity) is the maximum rate of oxygen consumption as measured during incremental exercise, most typically on a motorized treadmill. One of the exercise benefits is improvement in maximal oxygen uptake response. When you exercise your muscles are working harder than normal and, as a result, they require more energy than normal. Since the ATP energy used by your muscles is generated with the aid of oxygen, it follows that an increase in exercise intensity will result in an increase in muscular oxygen demands. Therefore, increased exercise intensity ultimately corresponds to an increased VO2. This is the reason that your breathing gets progressively faster and deeper as your exercise intensity increases, your body is trying to provide more oxygen to your working muscles so that they can generate enough ATP energy to keep you moving. People with differences in genetics, gender, age and fitness state respond differently to the same exercise training program. At least 50% of these differences have been found to be due to genetic variations.",
      connotation: "good",
    },
    assessment: {
      score: 7.0,
      level: 1,
      percentile: 60.0,
    },
    coverage: {
      lookup_snps: 32,
      snps_found: 32,
      inhibiting_snps: 0,
      contributing_snps: 11,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Try to take advantage of your heightened aerobic capacity by working out in your target heart rate zone. Remember to gradually increase the intensity and duration of your exercises over time, instead of all at once. Consider doing interval training, which can significantly improve your VO2 max. Examples of interval training include walking, running, biking or swimming. Remember that your goal is to do more strenuous activities without feeling more strained by them.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "fat-overconsumption",
    trait: {
      code: "fat-overconsumption",
      name: "Fat Overconsumption",
      short_description: "",
      description:
        "For pregnancy, healthy fats are essential for building baby's proper brain and eye development. Depending on your calorie intake, you should be consuming approximately 40-90 grams of fat each day. However too much fat consumption may result in higher than expected weight gain during pregnancy. Several genetic variations are known to influence subconscious fat craving. Being aware if that is the case will help you manage your cravings.",
      connotation: "risky",
    },
    assessment: {
      score: 1.5,
      level: 1,
      percentile: 65.0,
    },
    coverage: {
      lookup_snps: 10,
      snps_found: 10,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "It seems you may indeed have a slight genetic predisposition for fat craving. It is good to be aware of this if indeed you observe yourself fancying that fried chicken. If craving hits, try to substitute it with healthy fat food sources such as avocado, nuts (if you are not allergic), fatty fish other than tuna (if you can have fish), olives, or yogurt (if you are not vegan or lactose intolerant).\nIt is important for you to not exceed the general recommendations for daily consumption of fats. Recommended daily calories from fat should constitute 25-35%.\nEnsure that your diet is full of good (mono-unsaturated and poly-unsaturated) fats and watch for the bad (saturated) fats. Most 9Moons recipes are designed to meet your healthy fat needs.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "fat-taste-perception",
    trait: {
      code: "fat-taste-perception",
      name: "Fat Taste Perception",
      short_description: "",
      description:
        "There is growing evidence that foods we crave are at least partially determined by our genetics. Genetic variation in taste receptors (or taste buds) may account for differences in our food choices and dietary habits. Research reported that people with a variant in the CD36 gene do not taste dietary fats as much. These people crave fats more than people who detect higher amounts of fat in food. In fact, there is consistent emerging evidence that fat is the sixth primary taste, with some people being genetic super-tasters and others low-tasters of fat. Low fat taste perception is linked with dietary consumption of fatty foods, which in combination with other factors, such as pregnancy, may lead to extra weight.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 2,
      percentile: 65.0,
    },
    coverage: {
      lookup_snps: 1,
      snps_found: 1,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You seem to be a low taster of fat.\nThis means that you are more likely to crave and over-consume fats than individuals whose fat taste perception is not impaired.\nSince you may not notice the fat content of meals through taste, make sure to read labels and pay attention to ingredients when cooking or eating out. No need to worry! 9Moons will provide you with accurate measurements of the fat content in your meals.\nMonitor your fat intake to not go beyond 40% of your daily calories, and consume good fats. 9Moons will indicate what percent of your daily intake specific meals consume.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "general-food-sensitivities",
    trait: {
      code: "general-food-sensitivities",
      name: "General Food Sensitivities",
      short_description: "",
      description:
        "Over the past 20 years, food allergy has grown from a relatively uncommon condition to a major clinical and public health problem worldwide due to its increasing prevalence, potential fatality, as well as enormous medical and economic impact. Up to 15 million Americans have food allergies, and up to 1 in every 13 children (under 18 years of age) in the U.S may be affected by a food allergy. That is roughly two in every classroom. Researchers estimate that genetic factors may contribute to up to 80% of food allergies. However, genetic predisposition does not always manifest in a specific allergy. Factors such as general health, immune system functioning, as well as other rare and yet unknown genetic and epigenetic mechanisms, have significant contributions too. Clinical food allergy is defined as immunoglobulin E (IgE)-mediated clinical reactivity to specific food proteins, and several genetic variations associated with food allergies have been identified. A recent large-scale genome-wide study identified several genetic variants associated with at least one common food allergens.\nThe top 10 symptoms of food allergy, 30 minutes to 2 hours after ingesting a potential allergen include: headache, lack of attention/focus, anxiety/depression, bloating, gas, constipation, diarrhea, exhaustion, joint pain, skin rash.",
      connotation: "risky",
    },
    assessment: {
      score: -1.0,
      level: 0,
      percentile: 5.0,
    },
    coverage: {
      lookup_snps: 10,
      snps_found: 8,
      inhibiting_snps: 3,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Paying attention to your body's signals after eating can help you learn what sensitivities you have. Reading food labels carefully will keep you aware of what you're eating. Short-term elimination diets can help you identify specific food triggers.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "glutathione-deficiency",
    trait: {
      code: "glutathione-deficiency",
      name: "Glutathione Deficiency",
      short_description: "",
      description:
        "Glutathione is a powerful antioxidant that is produced naturally by the liver. It is involved in tissue building, immune response, nutrient metabolism, and regulation of cellular events, including cell proliferation and apoptosis. Glutathione is often called the master antioxidant as it scavenges free radicals, peroxides, lipid peroxides and heavy metals. Glutathione is required for proper utilization and functioning of other antioxidants such as vitamins C, E, selenium and carotenoids. Glutathione deficiency contributes to oxidative stress, which plays a key role in aging and the age-related diseases. Genetic variations (and deletions) in genes (GPX1 GSTP1 GSTT1 GSTM1) lead to lower production and activity of glutathione compromising its detoxification capacity. There is a growing scientific evidence that glutathione helps control inflammation, fight infections, and boost immune system. It has been used to treat acne and as a skin lightening agent. Glutathione occurs naturally in many foods but its absorption from fruits and vegetables may be low. Adequate amounts of protein increases the production of glutathione in our bodies. There is even some research pointing to glutathione aiding in the healthy development of the fetus; by reducing the oxidative stress placed on the mother and child while pregnant, it has been shown to decrease the instance of some pregnancy complications.",
      connotation: "risky",
    },
    assessment: {
      score: 2.5,
      level: 1,
      percentile: 70.0,
    },
    coverage: {
      lookup_snps: 12,
      snps_found: 9,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You may need to boost the body's natural production of glutathione by consuming the following food sources: beef, pork, poultry, fish, cheese, tofu, legumes. These foods are high in essential amino acids that provide building blocks for producing glutathione. 9Moons will help you optimize your glutathione consumption by suggesting recipes that containing the ingredients listed above.\nStudies showed that the expression and activity of GSTT1and GSTM1 genes coding for glutathione can be induced by the consumption of cruciferous vegetables (cauliflower, cabbage, garden cress, bok choy, broccoli, Brussels sprouts). Consuming cruciferous vegetables has many benefits during pregnancy; 9Moons will help you meet your personalized nutrition goals by suggesting recipes that contain an abundance of cruciferous vegetables!\nRegular exercise boosts glutathione production, however athletic over-training may result in lower glutathione. 9Moons can help you find exercises that are safe to do while pregnant!\nGlutathione supplements are readily available but as always discuss it with your healthcare provider. Especially if you are pregnant, supplementation is not recommended. If you are considering supplementation always discuss with your OBGYN first.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "gluten-sensitivity",
    trait: {
      code: "gluten-sensitivity",
      name: "Gluten Sensitivity",
      short_description:
        "Gluten is a protein found primarily in grains (wheat, rye, barley). Gluten sensitivity affects up to 6 percent of the population and is highly genetically influenced.",
      description:
        "Gluten is a protein found primarily in grains (wheat, rye, barley). Gluten sensitivity may affect up to 6 percent of the population. It is sometimes called non-celiac gluten sensitivity to distinguish it from celiac disease, which is an autoimmune condition. After eating foods with gluten, people with gluten sensitivity can experience abdominal pain, diarrhea or constipation, and bloating. Around 15% of individuals have gluten sensitivity, mostly influenced by their genetics.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 0,
      percentile: 70.0,
    },
    coverage: {
      lookup_snps: 5,
      snps_found: 5,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Many foods can have hidden gluten, such as artificial coffee creamers, french fries, and chewing gums. Some non-food items also contain gluten, including mouthwash, playdough, and makeup. Be aware of cross-contamination — even using a cutting board that had bread on it can be dangerous.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "grass-allergy-resistance",
    trait: {
      code: "grass-allergy-resistance",
      name: "Grass Allergy Resistance",
      short_description:
        "Grass pollen is known to cause a variety of different allergic reactions. Some individuals have genes that protect against this allergy.",
      description:
        "Grass pollen is known to cause a variety of different allergic reactions. Grass pollen is most present in the air during the late spring and early summer months and can cause allergic rhinitis, allergic conjunctivitis, and asthma. Direct skin contact with grass, from sitting in the grass or mowing the lawn, can cause itching and eczema. A recent study identified advantageous genetic variations associated with lower incidents of grass allergy.",
      connotation: "good",
    },
    assessment: {
      score: 1.5,
      level: 1,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 3,
      snps_found: 3,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Grass loves to cling to clothes - try to avoid bringing it back into your home, and wash your hands liberally when you get back. Consider taking showers after coming home to decrease pollen exposure. Try to wear a mask while mowing the lawn to avoid air-borne clippings. Consider staying inside or shutting windows on days with high pollen counts, as these tend to trigger allergies more frequently or severely. If you experience symptoms of an allergy or hay fever after coming into contact with grass pollen, you may want to consult your doctor for a diagnostic test.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "hay-fever",
    trait: {
      code: "hay-fever",
      name: "Hay Fever",
      short_description:
        "Different substances and pollen cause the allergic symptoms commonly called hay fever or allergic rhinitis, which is influenced by heredity.",
      description:
        "Many different substances and pollen cause the allergic symptoms commonly called hay fever. While hay is not the only allergen that causes problems and it does not cause fever, this popular name appeared from early descriptions of sneezing, nasal congestion, and eye irritation while harvesting hay fields. Symptoms of hay fever include nasal congestion, a runny nose with clear mucus, sneezing, nose, eye itching, excess tears. Large genome-wide association studies identified multiple genetic markers associated with hay fever.",
      connotation: "risky",
    },
    assessment: {
      score: 9.0,
      level: 2,
      percentile: 85.0,
    },
    coverage: {
      lookup_snps: 32,
      snps_found: 32,
      inhibiting_snps: 2,
      contributing_snps: 14,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Hay fever is commonly triggered by pollen, so stay inside or shut your windows on days with high pollen counts. Wash your bedsheets weekly and your hair daily to get rid of any pollen that's accumulated. Avoid grassy areas, especially in the morning or at night, as they tend to have higher pollen counts. This also applies to mowing the lawn - do it in the afternoon instead to avoid pollen. Use an air conditioner and install central air conditioning with a high-efficiency particulate air (HEPA) filter attachment. Wear a mask while cleaning or gardening to help keep out potential irritants or triggers. Talk to a healthcare provider about taking over-the-counter medicine to get relief from hay fever symptoms.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "heartbeat-reduction-difficulty",
    trait: {
      code: "heartbeat-reduction-difficulty",
      name: "Heartbeat Reduction Difficulty",
      short_description: "",
      description:
        "Heart rate response is commonly used as a measurable marker to guide exercise intensity and to monitor progress with the expectation that the heart rate at a given intensity and load will decrease substantially with regular exercise. Exercise training improves cardiac function and several cardiovascular risk factors, including ability to perform physical tasks at a given workload with a lower heart rate. However, the cardiovascular benefits of regular physical activity are not equally distributed among individuals, as some exhibit marked improvements while others may show little or no changes. If you have higher predisposition to reduced heart response to exercise, the expected decrease in exercise heart rate may not occur. You or your fitness specialist may (falsely) assume that the exercise prescription was insufficient and may try to increase the exercise training load. Genetic variation explains large proportion of this difference. It is important to know this to make sure that the exercise does not cause undue heart strain.",
      connotation: "risky",
    },
    assessment: {
      score: 8.0,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 17,
      snps_found: 15,
      inhibiting_snps: 1,
      contributing_snps: 12,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Exercise more, as it will tend to lower your resting heart rate. Work on lowering your stress levels through deep breathing or yoga. As a rule of thumb, you can calculate your maximum heart rate by subtracting your age from 220. Keep in mind that you should keep your heart rate between fifty and seventy percent of your maximum heart rate for moderate exercise, and between seventy to eighty five percent for vigorous exercise. Take walks, preferably away from urban environments, to lower your heart rate. The same can be said about warm, relaxing baths or showers. Before trying new exercises, consult a fitness trainer.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "histamine-intolerance",
    trait: {
      code: "histamine-intolerance",
      name: "Histamine Intolerance",
      short_description:
        "Histamine intolerance is caused by a dysfunction in the DAO and HNMT enzymes, which are affected by an individual's genetics.",
      description:
        "Histamine intolerance is caused by an excess of histamine because of an impaired function of the histamine-degrading enzyme diamine oxidase (DAO) and histamine N-methyltransferase (HNMT) that regulates airway response to histamine. Genetic variants in the key degradation enzyme diamine oxidase DAO and histamine N-methyltransferase (HNMT) are associated with histamine intolerance.",
      connotation: "risky",
    },
    assessment: {
      score: 1.0,
      level: 0,
      percentile: 45.0,
    },
    coverage: {
      lookup_snps: 7,
      snps_found: 7,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Foods high in histamines include dried fruits, eggplants, avocado, and anything fermented - including alcohol. Additionally, some foods can release histamines stored in your body, including chocolate, citrus fruits, nuts, pineapple, shellfish, strawberries, and tomatoes. Water also acts as a natural histamine regulator.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "iron-deficiency",
    trait: {
      code: "iron-deficiency",
      name: "Iron Deficiency",
      short_description: "",
      description:
        "Iron is an extremely important micronutrient that helps ensure that enough oxygen is supplied, to both the mother and the baby, through the production of healthy red blood cells. Pregnant women are at an increased risk of anemia, a condition in which a woman is iron deficient and does not have enough healthy blood cells to carry adequate oxygen to their body tissues. During pregnancy, expecting mothers need double the amount of iron that non pregnant women would, hence it is imperative to keep up with one’s daily iron intake goals. Risk of iron deficiency is more common later in pregnancy, and is especially common when carrying multiples. Typically, prenatal vitamins contain iron, but it is still important to eat a balanced healthy diet to avoid deficiencies on any end. There are genetic variations that contribute to iron processing impairment in the body.",
      connotation: "risky",
    },
    assessment: {
      score: 5.0,
      level: 0,
      percentile: 45.0,
    },
    coverage: {
      lookup_snps: 17,
      snps_found: 17,
      inhibiting_snps: 1,
      contributing_snps: 9,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "We are happy to report that you do not have known predispositions for iron processing issues. Your 9Moons app has your personalized iron intake need laid out for you.\nThere are two types of iron: heme iron from animal sources and non-heme iron from plant sources. Heme iron is absorbed more readily. Foods rich in heme iron include beef, poultry, and salmon. Good sources of non-heme iron include beans, fortified cereals, and dark leafy greens like spinach.\nYour prenatal vitamins usually have more than adequate iron for your needs. If you are already taking them, there is no need to eat more iron-rich food.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "iron-overload",
    trait: {
      code: "iron-overload",
      name: "Iron Overload",
      short_description: "",
      description:
        "In addition to looking at whether you need more iron, we also look at whether you could have iron overload. While iron is an essential mineral, too much iron may damage your body. Genetic variations affect how much iron is absorbed leading to iron overload despite normal iron intake. In [pregnant and nonpregnant] women, approximately 50 percent of dietary heme iron is absorbed, and only 25 percent of non-heme iron is absorbed. When iron is given as a large dose, much of it will remain unabsorbed and continue through the intestinal tract disturbing the gut microbiome. The excess of iron in the body can lead to fatigue, anorexia, dizziness, nausea, vomiting, headache, weight loss, and shortness of breath.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 0,
      percentile: 60.0,
    },
    coverage: {
      lookup_snps: 6,
      snps_found: 6,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Good news! We have not found known genetic predispositions for iron overload potential. Follow the guidelines of your 9Moons app for iron intake and recipes.\nYour prenatal vitamins should have adequate amounts of iron for your needs, if you are taking them you may not need to make an extra effort for iron-rich foods.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "joint-flexibility",
    trait: {
      code: "joint-flexibility",
      name: "Joint Flexibility",
      short_description:
        "People with genetic variants in several collagen genes have a higher range of motion and better joint laxity and flexibility.",
      description:
        "While flexibility and range of motion can be improved with regular stretching, some people have inherent joint flexibility, which is a highly heritable trait. Genetic variants in several collagen genes alter the amino acid sequence and change the amount of collagen proteins being produced, affecting the architecture and biomechanical properties of some tissues, including ligaments. Therefore, people with these genetic variants generally have a higher range of motion and better joint laxity and flexibility.",
      connotation: "good",
    },
    assessment: {
      score: 0.0,
      level: 0,
      percentile: 50.0,
    },
    coverage: {
      lookup_snps: 3,
      snps_found: 3,
      inhibiting_snps: 2,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 0 recommendation",
        content:
          "Yoga and stretching are both great ways to take advantage of better joint flexibility. Just like any other exercise, increasing flexibility takes time - don't be discouraged if you don't start seeing improvements right away. Be sure to stretch before and after an exercise in order to keep your joints limber.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "joint-injury-risk",
    trait: {
      code: "joint-injury-risk",
      name: "Joint Injury Risk",
      short_description:
        "Many sports-related injuries involve damage to the joints. Joint injury risk is associated with a higher genetic risk for osteoarthritis.",
      description:
        "Many sports-related injuries involve damage to the joints. Common joint injuries include a twisted ankle, sprained wrist, overextended elbow, and damaged knee ligaments. When exercising, you can be more at risk for overuse injuries. The risk of joint injury is associated with a higher genetic predisposition to osteoarthritis. Your risk for joint injury is computed based on 13 genetic variations that were found to be associated with joint problems.",
      connotation: "risky",
    },
    assessment: {
      score: 5.0,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 13,
      snps_found: 13,
      inhibiting_snps: 0,
      contributing_snps: 7,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Make sure to use proper technique when doing any workout that involves repeated motions, such as tennis. Cross-train your workouts, as overdoing one workout is likely to cause joint injury. Stretch before and after exercises to reduce the risk of getting injured. Ensure you're using proper equipment - such as footwear - at all times while exercising. Avoid pushing yourself too far in your workouts: increase length or intensity slowly, and never increase both at once. Consult a fitness professional on how to best strengthen the muscles around your joints, and work to design your training program accordingly.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "lactose-intolerance",
    trait: {
      code: "lactose-intolerance",
      name: "Lactose Intolerance",
      short_description: "",
      description:
        "Lactose intolerance means that the body cannot easily digest lactose, which is a natural sugar found in milk and dairy products. Symptoms include diarrhea, nausea, abdominal cramps, bloating and gas. For some people, these symptoms are very severe and their systems can not tolerate any lactose. For others, the symptoms are milder and they just have to limit the amount of dairy products they consume. Lactose intolerance symptoms generally show themselves after ingestion of food containing lactose. These include: milk, milkshakes and other milk-based beverages, whip cream and coffee creamer, ice cream/ice milk/sherbet, cheese of all kinds, butter, puddings and custards, cream soups and cream sauces. Lactose intolerance is a consequence of a deficiency of a lactase enzyme. This deficiency may be genetic or acquired. Interestingly, up to 65% world's population have reduced ability to digest lactose. Two genetic variations have been frequently found in people (of European descent) who are lactose intolerant.",
      connotation: "risky",
    },
    assessment: {
      score: 2.0,
      level: 2,
      percentile: 80.0,
    },
    coverage: {
      lookup_snps: 2,
      snps_found: 2,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 2 recommendation",
        content:
          "Start using dairy milk substitutes, such as rice or soy milk. Avoid milk, cheese, yogurt, and ice cream, which are all high in lactose. Cut back on chocolate, lunch meats, certain kinds of salad dressing, and instant potatoes/soups, as they can all contain some amount of lactose. Talk to your healthcare provider about lactase supplements, which can be taken before meals containing a large amount of lactose to offset some of the symptoms of lactose intolerance. Lactase powder can also be added to milk to make it more easily digestible. Read up on medications to help with some of the symptoms of lactose intolerance, such as Gas-X for gas and bloating, and Imodium AD for diarrhea. Always consult a physician or dietitian before making major changes to your diet.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "lean-body-mass-potential",
    trait: {
      code: "lean-body-mass-potential",
      name: "Lean Body Mass Potential",
      short_description:
        "People with a predisposition to a leaner body mass have a better chance of attaining a muscular body through fitness exercises than others.",
      description:
        "Your lean body mass is the portion of your weight that is not fat. Your lean body mass is actually composed of your muscle and bone tissue as well as the water in your body and your organs, everything except the fat beneath the surface of your skin. Leaner body mass has a strong genetic component. If you are predisposed to having a higher lean body mass, you have a better chance of attaining a muscular body through fitness exercises than the average population.",
      connotation: "good",
    },
    assessment: {
      score: 1.0,
      level: 1,
      percentile: 40.0,
    },
    coverage: {
      lookup_snps: 16,
      snps_found: 16,
      inhibiting_snps: 4,
      contributing_snps: 4,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "Try yoga and meditation to lower your stress levels, as evidence shows that people with less stress tend to have a leaner body mass. Getting more high quality sleep to achieve a leaner body mass. Try to make an exercise routine - more frequent exercise is more effective at reducing fat. Try having four to five smaller meals throughout the day instead of two to three larger ones, as this will help you reduce fat.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "low-carb-diet-effectiveness",
    trait: {
      code: "low-carb-diet-effectiveness",
      name: "Low-carb Diet Effectiveness",
      short_description: "",
      description:
        "Carbohydrates are the main fuel source, and they provide vitamins, minerals, antioxidants and fiber in the diet. While your body uses carbohydrates as its main fuel source, there are substantial health benefits to limiting your carbohydrate intake. There are two major types of carbohydrates, simple (bad) and complex (good). Simple carbohydrates include sugary foods, pasta, bread, and white rice. Complex carbohydrates are whole grains, and legumes, including brown rice, and whole wheat breads. It is beneficial to eat more complex carbohydrates. People with genetic variations in several genes associated with obesity, insulin sensitivity, and high levels of bad cholesterol (LDL) are more sensitive to carbohydrates in their diet. These people may benefit more from low carbohydrate diet that reduces the amount of calories from carbohydrates and consuming nearly equal proportions of fats and proteins. A low-carb diet restricts the type and amount of carbohydrates you eat. In general, a low-carb diet focuses on proteins, including meat, poultry, fish and eggs, and some non starchy vegetables. A low-carb diet generally excludes or limits most grains, legumes, fruits, breads, sweets, pastas and starchy vegetables, and sometimes nuts and seeds. \n\nThe amount of carbohydrates you should eat during pregnancy varies from person to person. and should be based on an individualized nutritional assessment. For most people, carbohydrates account for approximately 50 percent to 60 percent of their daily calories, but if you are experiencing gestational diabetes, or diabetes during pregnancy, you may want to limit your carbohydrate intake to 40 percent to 50 percent of your daily calories. A low carb diet may help you manage your gestational diabetes, or diabetes during pregnancy.",
      connotation: "good",
    },
    assessment: {
      score: 4.5,
      level: 1,
      percentile: 70.0,
    },
    coverage: {
      lookup_snps: 10,
      snps_found: 10,
      inhibiting_snps: 0,
      contributing_snps: 6,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "A low-carb diet is more likely to be effective for you than individuals who have predisposition to carb-sensitivity.\nLimit your daily carb intake to less than 40% of total calories consumed. 9Moons can help you monitor your carb consumption.\nTry 9Moons low carb recipe recommendations to improve and balance your pregnancy diet. \nFor the carbs you do consume, try to eat more complex carbohydrates such as whole grains, and legumes, including brown rice, and whole wheat breads. Including and consuming enough complex carbs through your diet is recommended during pregnancy.\nLow-carb diets may help prevent or improve serious health conditions, such as metabolic syndrome, diabetes, high blood pressure and cardiovascular disease. (Mayo Clinic)\nChoosing a low-carb diet may improve blood cholesterol or blood sugar levels, at least temporarily (Mayo Clinic)\nIt is not recommended that you reduce your carb in-take to less than 60 grams per day of carbs. If you suddenly and drastically cut carbs, you may experience a variety of temporary health effects, including headache, bad breath, weakness, fatigue, constipation, or diarrhea.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "lower-exercise-benefits-heart-pump",
    trait: {
      code: "lower-exercise-benefits-heart-pump",
      name: "Lower Exercise Benefits for Heart Pump Function",
      short_description:
        "Cardio exercises are often key in an exercise program. People with genetic risks benefit less from cardio exercises than others.",
      description:
        "Cardiac output is the volume of blood being pumped by the heart in a minute. It is equal to the heart rate multiplied by the stroke volume. When you exercise, the cardiac output is usually improved, and the heart needs to beat less to provide the same amount of blood. Carriers of a genetic variant in the kinesin heavy chain (KIF5B) gene had a lower positive cardiac outcome response than carriers of the more common allele.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 1,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 1,
      snps_found: 1,
      inhibiting_snps: 0,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "Level 1 recommendation",
        content:
          "You should consider including aerobic exercises - such as running, rowing, interval training, and swimming - into your routines to boost your cardiac output. Try to get at least thirty minutes of aerobic activity in a day. Instead of going on leisurely walks, try brisk walks or hikes. Both of these will do far more to increase your cardiac output. Try cycling! A 40/20 interval (where you spend 40 seconds with high resistance and speed up for 20 seconds with no resistance before switching back) is a great way to work out your cardio.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "low-fat-diet-effectiveness",
    trait: {
      code: "low-fat-diet-effectiveness",
      name: "Low-fat Diet Effectiveness",
      short_description: "",
      description:
        "General recommendation is to limit fat intake to 35% of total calories and to control saturated fat intake. A low-fat diet restricts fat intake to 20%, while increases protein and complex carbohydrate intake. Monitoring saturated fats is particularly important: at least 12% of daily calories should come from poly- and monounsaturated fats. The other 80% of calories in the diet come from carbohydrates and protein with general recommendations to consume about 55-60% of carbs and 20-25% of proteins. Low fat high protein diet is based on boosting protein intake to 40%, while decreasing carbohydrate intake to 40%. Numerous large-scale studies on weight loss found that people with variations in genes associated with sensitivity to fat (such as FTO, PPARG, APOA2, LIPC) are more responsive to low fat diets if their goal is weight loss. However, during pregnancy your body needs more fat. Roughly 25 percent to 35 percent of your daily calories should come from fat, depending on your carbohydrate goals. Eating monounsaturated and polyunsaturated fat is preferred over saturated varieties and will not interfere with your weight loss goals.",
      connotation: "good",
    },
    assessment: {
      score: 7.5,
      level: 2,
      percentile: 95.0,
    },
    coverage: {
      lookup_snps: 17,
      snps_found: 17,
      inhibiting_snps: 1,
      contributing_snps: 12,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Your genetic predisposition score would indicate a slightly higher effectiveness of low-fat diet for weight loss and healthy weight maintenance,\nTo try a low-fat diet, reduce your fat intake to less than 20% and increase your protein and complex carbs intake. Do not eliminate all sources of fat from your diet as many types of monounsaturated fat and polyunsaturated fats are extremely beneficial for positive pregnancy outcomes.\nIt is important to include polyunsaturated and monounsaturated (good) fats in your diet. Good fats are contained in fish, olive oils, avocados, and nuts.\nTry the following 9Moons low-fat recipes recommendations(with 3 grams of fat or less per serving) to achieve a healthy weight loss.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "low-protein-intake-risk",
    trait: {
      code: "low-protein-intake-risk",
      name: "Low Protein Intake Risk",
      short_description: "",
      description:
        "Protein is the major structural component of all cells in the body, and functions as enzymes, transport carriers and as some hormones. Large study identified genetic predispositions associated with protein intake. Some genetic variations are associated with a tendency for lower protein intake, while others are found to be associated with higher consumption of protein. As your caloric needs increase during pregnancy, so will your protein requirements. 9Moons will help you be mindful of your extra protein requirements.",
      connotation: "risky",
    },
    assessment: {
      score: -1.0,
      level: 0,
      percentile: 5.0,
    },
    coverage: {
      lookup_snps: 5,
      snps_found: 4,
      inhibiting_snps: 2,
      contributing_snps: 1,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Congratulations! You do not have any known predispositions that would indicate an impulse for lower protein intake. During pregnancy, you should get a minimum of 60 grams of protein a day, which will account for approximately 20 percent to 25 percent of your calorie intake.\nYou should be fine consuming proteins up to the levels prescribed by the FDA for the average population. If you are a vegan, you need to make sure to combine your sources of protein to ensure you are consuming all your essential amino acids. 9Moons will automatically adjust to your dietary preferences and recommend recipes that fit your specific protein needs.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "low-resting-metabolic-rate",
    trait: {
      code: "low-resting-metabolic-rate",
      name: "Low Resting Metabolic Rate",
      short_description: "",
      description:
        "Body weight depends on the balance between energy intake and energy expenditure. Energy intake comes from calories consumed, and energy expenditure is the energy that body uses to maintain normal body temperature and essential processes such as metabolism, breathing, brain functioning. Individual differences in the energy cost of self-maintenance (resting metabolic rate, RMR) are substantial, and depend on age, weight, environment, and genetics. The heritability of RMR is 40-50% of the variance remaining after adjustment for age, gender, and fat-free mass. Uncoupling proteins, UCP1 and UCP3, play important roles in regulating body temperature and energy expenditure: these proteins are found in brown fats and they are involved in metabolic process by which energy is dissipated as heat in response to excess of caloric intake and cold stress. People with genetic variations in the UCP genes tend to have lower RMR.",
      connotation: "risky",
    },
    assessment: {
      score: 3.0,
      level: 1,
      percentile: 85.0,
    },
    coverage: {
      lookup_snps: 10,
      snps_found: 10,
      inhibiting_snps: 0,
      contributing_snps: 5,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Given your potentially lower resting metabolic rate (RMR) you may need additional exercises to burn your calories efficiently. 9Moons can assist you in finding ways to exercise safely during pregnancy.\nYou also need to make sure you are on a healthy diet and do not have issues with fat or carb processing.\nCalculate your RMR/BMR and the calories you need eat for a particular weight using the USDA Supertracker Calcuator. Multiply the number of calories by 0.9 to get your DNA-adjusted RMR/BMR.\nAs the RMR tends to decrease with age, most people need to monitor their calorie intake and incorporate physical activities in their daily lives to maintain healthy energy balance.\nTo ensure you are eating the right kind of foods, check your NutriNome report to see if you have carb or fat processing predispositions.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "low-vegetable-intake",
    trait: {
      code: "low-vegetable-intake",
      name: "Low Vegetable Intake",
      short_description: "",
      description:
        "It is a well-known fact that consumption of fruits and vegetables adds important under-consumed nutrients to diets. Fruits and vegetables reduce the risk of heart disease, stroke, and some cancers, and help manage weight. Most U.S. residents consume too few fruits and vegetables. It is important to ensure you have adequate amounts of fruits and vegetables in your diet. A foundational aspect of helping to ensure a healthy pregnancy for you and your baby is focusing on your prenatal nutrition and making sure you’re eating a balanced diet that includes a healthy serving of fruits and vegetables. It’s known that a healthy diet during pregnancy is essential for the normal growth and development of the fetus and the mother. Not only does an unbalanced and nutrient deficient diet pose a great risk to the mother and her child, in some cases it acts as a barrier to successful conception. Although finding the right diet for you may seem like a daunting task, with the guidance of 9moons, our prenatal personalized precision nutrition platform, the stress of figuring out if you are consuming adequate amounts of each macro, and micronutrient dissipates.",
      connotation: "risky",
    },
    assessment: {
      score: 1.5,
      level: 0,
      percentile: 75.0,
    },
    coverage: {
      lookup_snps: 3,
      snps_found: 3,
      inhibiting_snps: 0,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Good news! You do not have any known predispositions that would indicate an impulse for lower vegetable intake.\nYou should be fine consuming vegetables as prescribed by the FDA for the average population.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "lutein-zeaxanthin-deficiency",
    trait: {
      code: "lutein-zeaxanthin-deficiency",
      name: "Lutein and Zeaxanthin Deficiency",
      short_description: "",
      description:
        "Lutein and zeaxanthin (LZ) are yellow to red xanthophylls, a type of naturally occurring carotenoids. They are found in high concentrations in the macula of the human eye. In fact, the small yellowish area of the eye retina near the optic disk that provides central vision, is called the  macula lutea, from the Latin macula (spot) and lutea (yellow). Lutein and zeaxanthin block blue light from reaching the underlying structures in the retina, reducing the risk of light-induced oxidative damage that could lead to macular degeneration and cataracts, which are the leading causes of visual impairment and acquired blindness. Lutein and zeaxanthin also have important general antioxidant functions in the body. Along with other natural antioxidants, including vitamin C, beta carotene and vitamin E, they guard the body from damaging effects of free radicals. Lutein may also contribute to protection against atherosclerosis (buildup of fatty deposits in arteries), the disease that leads to most heart attacks. Currently there is no global health organization recommendation for lutein and zeaxanthin intake during pregnancy, so it is best to stay with in your normal range of consumption.",
      connotation: "risky",
    },
    assessment: {
      score: 0.5,
      level: 0,
      percentile: 30.0,
    },
    coverage: {
      lookup_snps: 9,
      snps_found: 9,
      inhibiting_snps: 2,
      contributing_snps: 3,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Happy to report you do not seem to have known predispositions that would suggest any impairments for processing lutein and zeaxanthin. Aim at intake of at least 6 milligrams of LZ. According to a study from Harvard University leads to over 40% lower risk for macular degeneration. This is needed to maintain your healthy vision.\nEat the leafy and cruciferous vegetables of yellow, orange and green colors to get lutein and zeaxanthin (LZ). Lutein is absorbed better when ingested with a small amount of fat, such as olive oil.\nConsume all varieties of cooked summer and winter squash, peas, yellow corn, beet greens, pumpkin, Brussels sprouts, broccoli, romaine and iceberg lettuce, asparagus and carrots. These are good food sources of LZ and contain between 1 to 4 milligrams of LZ per serving.\nInclude other sources of LZ: eggs, orange juice, corn, and other foods with yellow color.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "lycopene-deficiency",
    trait: {
      code: "lycopene-deficiency",
      name: "Lycopene Deficiency",
      short_description: "",
      description:
        "Lycopene is a bright red carotene, and it gives many fruits and vegetables (tomatoes, watermelons, papayas, pink grapefruits, red carrots) their red color. Lycopene is the most powerful antioxidant that is commonly found in the diet: it has a high capacity to remove free radicals. It also helps to protect the skin from the damage by sunlight. Lycopene is present at higher levels in skin, liver, lungs, prostate, colon and adrenal glands. Scientific studies have linked higher consumption of lycopene rich foods with reduced risk of various diseases, including cardiovascular disease, asthma, some cancers, and age-related vision problems. Lycopene has not got an official status of an essential nutrient. Nevertheless, it is widely considered beneficial for immune system, skin and eye health. Your lycopene intake needs do not increase during pregnancy, so it best to stay within the normal intake range.",
      connotation: "risky",
    },
    assessment: {
      score: 2.5,
      level: 0,
      percentile: 50.0,
    },
    coverage: {
      lookup_snps: 11,
      snps_found: 11,
      inhibiting_snps: 0,
      contributing_snps: 4,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "Good news! You do not seem to have known predispositions that would suggest any impairments for lycopene processing. Meet your lycopene intake requirements from your diet: think cooked tomatoes (fresh tomato pasta sauce, tomato juice, tomato soup, paste). Tomatoes account for more than 85% of the dietary intake of lycopene in North American diets.\nCook tomatoes with olive oil or other good fats as lycopene from cooked tomatoes is absorbed 2.5 times better than from fresh tomatoes. To protect skin from sun damage, the Mayo clinic recommends taking 55 grams of tomato paste in olive oil. This provides 16 milligrams of lycopene\nInclude watermelons, guava, papaya, pink grapefruit in your diet, in particular if you happen to not like tomatoes, or are allergic to them. 9Moons will provide you with many food options if you have any food or dietary preferences and restrictions.\nThe lycopene found in supplements is about as easy for the body to absorb as dietary lycopene. The Mayo clinic recommends taking lycopene supplementation for various conditions and concerns, including heart diseases, blood pressure, infertility, and as a general antioxidant. Lycopene is considered possibly unsafe when used during pregnancy and breastfeeding, so please consult your healthcare provider if you are planning to supplement.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "magnesium-deficiency",
    trait: {
      code: "magnesium-deficiency",
      name: "Magnesium Deficiency",
      short_description: "",
      description:
        "Magnesium is an essential mineral involved in numerous physiological pathways, including energy metabolism, nerve control, neurotransmitter release, and blood pressure regulation. Magnesium is an important electrolyte needed for proper muscle function, strong bones and good heart health. Magnesium imbalances have been linked to various complications such as muscle cramps, constipation, poor sleep, tension or migraine headaches, anxiety and depression, chronic fatigue and premenstrual syndrome. In a long term, magnesium deficiency has been associated with higher risk of osteoporosis, hypertension, cardiovascular disease, insulin sensitivity. Magnesium is also needed for normal embryonic and fetal development for pregnant women.  Making sure to keep one's magnesium levels in range is important as some evidence suggests that it helps prevent the uterus from contracting prematurely. Several genetic variants have been associated with magnesium levels: some variants contribute to lower levels of magnesium, while others are associated with higher magnesium.",
      connotation: "risky",
    },
    assessment: {
      score: 2.5,
      level: 1,
      percentile: 75.0,
    },
    coverage: {
      lookup_snps: 11,
      snps_found: 10,
      inhibiting_snps: 0,
      contributing_snps: 4,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "You may want to consider a slightly higher intake of magnesium than the recommended daily allowance through food sources.\nFoods rich in magnesium include: Nuts (brazil, almonds, cashews), seeds (pumpkin, sesame), avocados, oats and legumes are some of the best sources of magnesium. In addition, bananas and dark chocolate are rich in magnesium. 9Moons will help you maximize your magnesium consumption through your diet.\nIf you are an athlete, and sweat a lot, or if you notice muscle cramps, weakness, or twitches, make sure your magnesium levels are adequate.\nSevere magnesium deficiency is rare, but signs include nausea, vomiting, loss of appetite, fatigue, insomnia, muscle twitching, poor memory, irregular heartbeat, and weakness. Other signs of low magnesium include poor sleep, irritability, and headaches.\nMany diuretics, including caffeine, alcohol, as well prescription diuretic medicines, affect magnesium levels. While pregnant you should limit your consumption of caffeine and completely eliminate your consumption of alcohol.\nIf you consider taking magnesium supplementation, talk to your health care provider to discuss potential side effects, and to determine optimal dose. Although magnesium is critical for many aspects of staying healthy, 56% of Americans consume less than adequate magnesium levels; interestingly enough, many prenatal vitamins do not even have the recommended amount of magnesium for women.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
  {
    trait_code: "mediterranean-diet-effectiveness",
    trait: {
      code: "mediterranean-diet-effectiveness",
      name: "Mediterranean Diet Effectiveness",
      short_description: "",
      description:
        "Mediterranean diet is known to have many health benefits. It is rich in monounsaturated (good) fats that are important in reducing risks of heart disease, increasing good cholesterol, delaying cognitive decline. It is also associated with longevity. When in comes to incorporating the Mediterranean diet into your pregnancy, many studies have pointed to its inclusion being beneficial to material and fetal outcomes. Research by Mayo Clinic has shown that the traditional Mediterranean diet reduces the risk of heart disease. The diet has been associated with a lower level of oxidized low-density lipoprotein (LDL) cholesterol the badcholesterol that's more likely to build up deposits in your arteries. According to the Mayo Clinic, a meta-analysis of more than 1.5 million healthy adults demonstrated that following a Mediterranean diet was associated with a reduced risk of cardiovascular mortality as well as overall mortality. The Mediterranean diet is also associated with a reduced incidence of cancer, and Parkinson&'s and Alzheimer's diseases. Women who eat a Mediterranean diet supplemented with extra-virgin olive oil and mixed nuts may have a reduced risk of breast cancer (<a href=\"http://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/mediterranean-diet/art-20047801\">Mayo Clinic</a>). In people with genetic variations in the ADIPOQ, gene and Mediterranean diet also helps to facilitate fat loss and to improve metabolic health. Genetic variations in several other genes, including PPARG, MTHFR, TCF7L2, LPL and MLXIPL, were found to be associated with increased advantage of following the Mediterranean diets.",
      connotation: "good",
    },
    assessment: {
      score: 1.5,
      level: 1,
      percentile: 80.0,
    },
    coverage: {
      lookup_snps: 4,
      snps_found: 4,
      inhibiting_snps: 0,
      contributing_snps: 2,
    },
    recommendations: [
      {
        type: "general",
        title: "",
        content:
          "If your goal is weight loss and you are considering which diet to choose, the healthy Mediterranean diet full of good fats is likely to be the right choice for you.\nTo leverage your advantaged predisposition for the Mediterranean diet, you can increase your intake of olive oil and nuts that are important in reducing risks of heart disease, increase good cholesterol, and are associated with longevity.\nOverall, Mediterranean diet is based on consuming 35% of daily calories from fats, including up to 22% from monounsaturated fats, 20% of good quality proteins from fish and meats, and up to 40% of carbohydrates.\nCheck out the Mediterranean diet recipes of Mayo Clinic for inspiration. 9Moons recipe and meal plan generator tends to align with a lot of the guidelines of the traditional Mediterranean diet.",
      },
    ],
    axengine: {
      engine: "default",
      module: "lfn-wellness-grch37",
    },
  },
];
