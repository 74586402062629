import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { timeValidator } from "../timeValidator";

import {
  create,
  deleteAll,
  deleteById,
  getAll,
  update,
} from "./exercises-service";

import {
  getModelById,
  updateModels,
  getAllModels,
} from "../models/model-service";

function AddExercises() {
  const notify = (message) =>
    toast(message, {
      style: {
        border: "1px solid #badbcc",
        padding: "10px 15px",
        color: "#0f5132",
        backgroundColor: "#d1e7dd",
      },
    });
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: location.state != null ? { ...location.state } : {},
  });
  const [videoToggle, setVideoToggle] = useState(
    location.state != null ? 1 : 2
  );
  const [thumbToggle, setThumbToggle] = useState(
    location.state != null ? 1 : 2
  );

  useEffect(() => {
    if (location.state != null) {
      if (getValues().videoUrl == "[object FileList]") {
        setValue("videoUrl", "");
      }
      location.state = getValues();
    } else setValue("videoUrl", "");
  }, [videoToggle]);
  useEffect(() => {
    if (location.state != null) {
      if (getValues().thumbUrl == "[object FileList]") {
        setValue("thumbUrl", "");
      }
      location.state = getValues();
    } else {
      setValue("thumbUrl", "");
    }
  }, [thumbToggle]);
  const onSubmit = async (values) => {
    if (location.state != null) {
      const up = await update(location.state, values);
      if (up) {
        reset(up);
        setThumbToggle(1);
        setVideoToggle(1);
        getAllModels()
          .then((x) => {
            if (Array.isArray(x)) {
              x.forEach((y) => updateAllModel(y, up));
            }
          })
          .catch((err) => console.log(err));

        notify("Exercise has been updated successfully.");
      }
    } else {
      create(values);
      notify("Exercise has been added successfully.");
    }
  };

  const updateAllModel = async (Obj, values) => {
    for (let i = 0; i < Obj.trimesters.length; i++) {
      for (let j = 0; j < Obj.trimesters[i].weeks.length; j++) {
        for (let k = 0; k < Obj.trimesters[i].weeks[j].days.length; k++) {
          for (
            let l = 0;
            l < Obj.trimesters[i].weeks[j].days[k].exercises.length;
            l++
          ) {
            for (
              let m = 0;
              m <
              Obj.trimesters[i].weeks[j].days[k].exercises[l].videoList.length;
              m++
            ) {
              if (
                Obj.trimesters[i].weeks[j].days[k].exercises[l].videoList[m]
                  .videoId == values.id
              ) {
                Obj.trimesters[i].weeks[j].days[k].exercises[l].videoList[
                  m
                ].video = values;
              }
            }
          }
        }
      }
    }
    try {
      await updateModels(Obj.id, Obj);
      notify(Obj.id + "Model has been updated successfully.");
    } catch (e) {
      notify(`Some Error when Update ${Obj.id} model`);
    }
  };
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Exercise Management</a>
            </li>
            <li className="breadcrumb-item active">Add New Exercise</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-xl-10">
            <Toaster />
            <div className="card">
              <div className="card-header">
                <h2>Add Exercise</h2>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label htmlFor="txtExerciseTitle" className="form-label">
                        Exercise Title
                      </label>
                      <input
                        {...register("title", {
                          required: "Title is required",
                        })}
                        type="text"
                        className="form-control"
                        name="title"
                        id="txtExerciseTitle"
                        placeholder="Enter Exercise Title"
                      />
                      <p className="text-danger">{errors.title?.message}</p>
                    </div>

                    {/* <div className="col-12 mb-4">
                      <label className="form-label d-block">
                        Exercise Thumbnail
                      </label>
                      <div className="form-check form-check-inline radio-video">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="ExerciseThumbnail"
                          id="ExerciseThumbnail"
                          value="1"
                          defaultChecked={thumbToggle == 1}
                          onClick={(e) => {
                            setThumbToggle(1);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ExerciseThumbnail"
                        >
                          Exercise Thumbnail URL
                        </label>
                      </div>
                      <div className="form-check form-check-inline radio-video">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="ExerciseThumbnail"
                          id="ExerciseThumbnailFile"
                          value="2"
                          defaultChecked={thumbToggle == 2}
                          onClick={(e) => {
                            setThumbToggle(2);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ExerciseThumbnailFile"
                        >
                          Thumbnail File
                        </label>
                      </div>
                      {thumbToggle == 1 ? (
                        <>
                          <input
                            {...register("thumbUrl", {
                              required: "This is required",
                            })}
                            type="url"
                            className="form-control"
                            name="thumbUrl"
                            id="thumbUrl"
                            placeholder="Enter Thumbnail URL"
                          />
                        </>
                      ) : (
                        <>
                          <div className="input-group custom-file-button">
                            <label
                              className="input-group-text"
                              htmlFor="thumbUrl"
                            >
                              Choose Thumbnail
                            </label>
                            <input
                              {...register("thumbUrl", {
                                required: "This is required",
                              })}
                              type={"file"}
                              className="form-control"
                              name="thumbUrl"
                              id="thumbUrl"
                            />
                          </div>
                        </>
                      )}
                      <p className="text-danger">{errors.thumbUrl?.message}</p>
                    </div> */}
                    <div className="col-6 mb-4">
                      <label
                        htmlFor="txtExerciseDuration"
                        className="form-label"
                      >
                        Duration (In Minutes)
                      </label>
                      <input
                        {...register("time", {
                          required: "Duration is required",
                          pattern: {
                            value: /^([0-5]?[0-9]):([0-5]?[0-9])$/,
                            message: "Duration is required",
                          },
                        })}
                        type="text"
                        className="form-control"
                        name="time"
                        id="txtExerciseDuration"
                        placeholder="--:--"
                        maxLength={5}
                        onKeyUp={timeValidator}
                      />
                      <p className="text-danger">{errors.time?.message}</p>
                    </div>
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="txtExerciseDescription"
                        className="form-label"
                      >
                        Description
                      </label>
                      <textarea
                        {...register("description")}
                        className="form-control"
                        name="description"
                        id="txtExerciseDescription"
                        rows="3"
                        placeholder="About Exercise"
                      ></textarea>
                      <p className="text-danger">
                        {errors.description?.message}
                      </p>
                    </div>

                    <div className="col-12 mb-4">
                      <label className="form-label d-block">
                        Exercise Video
                      </label>
                      <div className="form-check form-check-inline radio-video">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radExerciseVideo"
                          id="radVideoURL"
                          value="1"
                          defaultChecked={videoToggle == 1}
                          onClick={(e) => {
                            setVideoToggle(1);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="radVideoURL"
                        >
                          Video URL
                        </label>
                      </div>
                      <div className="form-check form-check-inline radio-video">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radExerciseVideo"
                          id="radVideoFile"
                          value="2"
                          defaultChecked={videoToggle == 2}
                          onClick={(e) => {
                            setVideoToggle(2);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="radVideoFile"
                        >
                          Video File
                        </label>
                      </div>
                      {videoToggle == 1 ? (
                        <div
                          className={
                            videoToggle == 1
                              ? "video-url mt-3 d-block"
                              : "video-url mt-3 d-none"
                          }
                        >
                          <label htmlFor="txtVideoUrl" className="form-label">
                            Video URL
                          </label>
                          <input
                            {...register("videoUrl", {
                              required: false,
                            })}
                            type="url"
                            className="form-control"
                            name="videoUrl"
                            id="txtVideoUrl"
                            placeholder="Enter Video URL"
                          />
                        </div>
                      ) : (
                        <div
                          className={
                            videoToggle == 2
                              ? "video-file mt-3 d-block"
                              : "video-file mt-3 d-none"
                          }
                        >
                          <label className="form-label">Video File</label>
                          <div className="input-group custom-file-button">
                            <label
                              className="input-group-text"
                              htmlFor="txtVideoFile"
                            >
                              Choose Video
                            </label>
                            <input
                              {...register("videoUrl", {
                                required: false,
                              })}
                              type="file"
                              className="form-control"
                              name="videoUrl"
                              id="txtVideoFile"
                            />
                          </div>
                        </div>
                      )}
                      <p className="text-danger">{errors.videoUrl?.message}</p>
                    </div>
                    <div className="col-12 text-center mt-5 mb-3">
                      <button className="btn btn-primary" type="submit">
                        {location.state != null ? "Update" : "Add"} Exercise
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AddExercises;
