import React, { useEffect, useState } from "react";
import { getAll } from "./exercises/exercises-service";
import { getAllModels, getAllUsers } from "./models/model-service";
import { Link } from "react-router-dom";
function Dashboard() {
  let [exerciseCount, setExerciseCount] = useState(null);
  let [modelCount, setModelCount] = useState(null);
  let [userCount, setUserCount] = useState(null);
  useEffect(() => {
    getAll()
      .then((x) => {
        if (Array.isArray(x)) {
          setExerciseCount(x.length);
        }
      })
      .catch((err) => console.log(err));
    getAllModels()
      .then((x) => {
        if (Array.isArray(x)) {
          setModelCount(x.length);
        }
      })
      .catch((err) => console.log(err));
    getAllUsers()
      .then((x) => {
        if (Array.isArray(x.data.users)) {
          setUserCount(x.data.users.length);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="Dashboard">
      <main id="main">
        <div className="page-title">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-xxl-4 col-md-4">
              <div className="card info-card exercise-state">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-3">
                      <p>Exercises</p>
                      <h2>{exerciseCount}</h2>
                    </div>
                    <div className="card-icon">
                      <i className="fas fa-running"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-md-4">
              <div className="card info-card models-state">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-3">
                      <p>Models</p>
                      <h2>{modelCount}</h2>
                    </div>
                    <div className="card-icon">
                      <i className="fas fa-layer-group"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-md-4">
              <div className="card info-card users-state">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-3">
                      <p>Users</p>
                      <h2>{userCount}</h2>
                    </div>
                    <div className="card-icon">
                      <i className="fas fa-user-friends"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Dashboard;
