import { app, db, storage, functions } from "../firebase-config";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import {
  collection,
  addDoc,
  Timestamp,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import {
  httpsCallable,
  httpsCallableFromURL,
  connectFunctionsEmulator,
} from "firebase/functions";
import axios from "axios";
if (localStorage.getItem("ninemoon")) {
  axios.defaults.headers["authorization"] =
    "Bearer " + JSON.parse(localStorage.getItem("ninemoon")).access;
  axios.defaults.headers.common["authorization"] =
    "Bearer " + JSON.parse(localStorage.getItem("ninemoon")).access;
}
export const getAllUsers = async (id) => {
  const callable = httpsCallable(functions, "getAllUser");
  const obs = callable({ data: "c" });
  return await obs;
};

export const getUserById = async (id) => {
  try {
    const docRef = doc(db, "userData", id);
    const docSnap = await getDoc(docRef);
    console.log(docSnap.data());
    return { ...docSnap.data(), id: id };
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getUserVitalsDataById = async (id) => {
  try {
    const docRef = doc(db, `userData`, id);
    const colRef = collection(docRef, "vitalsData");
    const docSnap = await getDocs(colRef);
    console.log(docSnap);
    let vitals = [];
    docSnap.forEach((doc) => {
      vitals.push({ ...doc.data(), id: doc.id });
    });
    console.log(vitals);
    return vitals;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getServingEatenDataById = async (id) => {
  try {
    const docRef = doc(db, `userData`, id);
    const colRef = collection(docRef, "servingEatenData");
    const docSnap = await getDocs(colRef);
    console.log(docSnap);
    let vitals = [];
    docSnap.forEach((doc) => {
      vitals.push({ ...doc.data(), id: doc.id });
    });
    console.log(vitals);
    return vitals;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getUserWeightDataById = async (id) => {
  try {
    const docRef = doc(db, `userData`, id);
    const colRef = collection(docRef, "weightTrackerData");
    const docSnap = await getDocs(colRef);
    console.log(docSnap);
    let vitals = [];
    docSnap.forEach((doc) => {
      vitals.push({ ...doc.data(), id: doc.id });
    });
    console.log(vitals);
    return vitals;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const nineMoonsLogin = async () => {
  try {
    const ninemoon = await axios.post(
      `https://se1.lifenome.com/platform-api/api/auth-api/token/`,
      {
        username: "ninemoons-admin",
        password: "ebJs2ARnFVQ^",
      }
    );
    if (ninemoon && typeof ninemoon == "object" && ninemoon.data.access) {
      axios.defaults.headers["authorization"] =
        "Bearer " + ninemoon.data.access;
      localStorage.setItem("ninemoon", JSON.stringify(ninemoon.data));
    }
    return ninemoon;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getFileByUrl = async (url) => {
  try {
    return await axios.get(url);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getTratis = async (id, url = null) => {
  try {
    console.log(url)
    if (!url)
      url = `https://se1.lifenome.com/platform-api/api/samples-api/samples/${id}/traits/`;

    return await axios.get(url);
  } catch (error) {
    console.log(error);
    return error;
  }
};
