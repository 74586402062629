import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import foodGroupApi from "./service/foodGroups";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Loader from "react-js-loader";
import { async } from "@firebase/util";
function FoodGroups() {
  const [startLoader, setStartLoader] = useState(true);
  const [foodGroupList, setFoodGroupList] = useState([]);
  useEffect(() => {
    get();
  }, []);
  const notify = (message) =>
    toast(message, {
      style: {
        border: "1px solid #badbcc",
        padding: "10px 15px",
        color: "#0f5132",
        backgroundColor: "#d1e7dd",
      },
    });
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const add = async (values) => {
    try {
      if (values.id) {
        await foodGroupApi.update(values);
        notify("foodGroups has been update successfully.");
        await get();
      } else {
        await foodGroupApi.create(values);
        notify("foodGroups has been added successfully.");
        await get();
      }
    } catch (err) {
      if (values.id) notify("foodGroups not add.");
      else notify("foodGroups not update.");
    }
  };
  const get = async () => {
    try {
      setStartLoader(true)
      const getA = await foodGroupApi.getAll();
      setFoodGroupList(getA);
      setStartLoader(false)
    } catch (err) {
      setStartLoader(false)
    }
  };
  const del = () => {};
  return (
    <main id="main">
      <div className="page-title">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Diet Management</a>
            </li>
            <li className="breadcrumb-item active">Food Groups</li>
          </ol>
        </nav>
      </div>

      <section>
        <div className="row">
          <div className="col-lg-6">
            <Toaster />
            <div className="card">
              <div className="card-header">
                <h2>Add Food Group</h2>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(add)}>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label htmlFor="txtFoodGroupName" className="form-label">
                        Food Group Name
                      </label>
                      <input
                        {...register("name", {
                          required: "Name is required",
                        })}
                        type="text"
                        className="form-control"
                        name="name"
                        id="txtFoodGroupName"
                        placeholder="Enter Food Group Name"
                      />
                      <p className="text-danger">{errors.name?.message}</p>
                    </div>

                    <div className="col-12 mb-4">
                      <label htmlFor="txtFoodGroupDesc" className="form-label">
                        Short Description
                      </label>
                      <textarea
                        {...register("description")}
                        className="form-control"
                        name="description"
                        id="txtFoodGroupDesc"
                        rows="2"
                        placeholder="Description"
                      ></textarea>
                      <p className="text-danger">
                        {errors.description?.message}
                      </p>
                    </div>

                    <div className="col-12 text-center mt-5 mb-3">
                      <button className="btn btn-primary" type="submit">
                        {getValues("id") ? "Update" : "Add"} Food Group
                      </button>
                      <button
                        className="btn btn-primary ms-2"
                        type="reset"
                        onClick={() => reset({ id: null })}
                      >
                        reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h2>All Food Groups</h2>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Food Group Name</th>
                        <th scope="col">Description</th>
                        <th scope="col" className="text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      foodGroupList && foodGroupList.length > 0 &&
                      Array.isArray(foodGroupList) ? (
                        foodGroupList.map((x, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <h2 className="tdUserName">{x.name}</h2>
                              </td>
                              <td>{x.description}</td>
                              <td>
                                <div className="tdAction">
                                  <a
                                    onClick={() => reset(x)}
                                    href="javascript:void(0)"
                                    className="btn-round btn-edit"
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={3} className="p-5 text-center">
                            {startLoader ? <Loader
                              type="spinner-circle"
                              bgColor={"#D98669"}
                              color={"#D98669"}
                              size={100}
                            />:<>No Data</>}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default FoodGroups;
