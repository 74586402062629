import { db } from "../../firebase-config";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
const getAll = async () => {
  const getAll1 = await getDocs(collection(db, "dietSetup"));
  let dietSetup = [];
  getAll1.forEach((doc) => {
    dietSetup.push({ ...doc.data(), id: doc.id });
  });

  return dietSetup;
};
const getDietById = async (id) => {
  try {
    const docRef = doc(db, "dietSetup", id);
    const docSnap = await getDoc(docRef);
    console.log(docSnap.data());
    return docSnap.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};
const create = async (value) => {
  const id = value.id;
  delete value.id;
  try {
    const docRef = doc(db, "dietSetup", id);
    setDoc(docRef, value)
      .then(() => {})
      .catch((error) => {});
  } catch (err) {
    console.log(err);
  }
};
const update = async (value) => {
  try {
    let tempValue = { ...value };
    delete tempValue.id;
    await updateDoc(doc(db, "dietSetup", value.id), {
      ...tempValue,
    });
    return { ...tempValue, id: value.id };
  } catch (err) {
    console.log(err);
  }
};
const deleteById = (key) => {
  return db.child(key).remove();
};
const deleteAll = () => {
  return db.remove();
};

export default {
  getAll,
  create,
  update,
  deleteById,
  deleteAll,
  getDietById,
};
