import React, { useEffect, useState } from "react";
import planServiceApi from "./planService";

function Plans() {
  const [startLoader, setStartLoader] = useState(true);
  const [producIndex, setProductIndex] = useState(null);
  const [planList, setPlanList] = useState([]);
  useEffect(() => {
    get();
  }, []);
  const get = async () => {
    try {
      setStartLoader(true);
      const getA = await planServiceApi.getAll();
      console.log(getA);
      setPlanList(getA);
      setStartLoader(false);
    } catch (err) {
      setStartLoader(false);
    }
  };
  return (
    <main className="frontend-user">
      <div className="container">
        <div className="row">
          {/* <div className="col-12">
            <a
              className="dropdown-item d-flex align-items-center"
              href="#"
              onClick={(e) => {
                localStorage.removeItem("authUser");
                window.location.reload();
              }}
            >
              <i className="fa-solid fa-arrow-right-from-bracket"></i>
              <span>Sign Out</span>
            </a>
          </div> */}
        </div>
        <section className="min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 frontend-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 d-flex flex-column align-items-center justify-content-center">
                <div className="forntend-card">
                  <div className="d-flex justify-content-center py-4">
                    <a
                      href="javascript:void(0)"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="assets/images/nine.moons.svg" alt="" />
                    </a>
                  </div>

                  <div className="title-box">
                    <h1>Choose subscription membership plan</h1>
                    <p>We need to make sure we are licensed in your state.</p>
                  </div>

                  <form>
                    <div className="row">
                      {planList.map((x, index) => (
                        <div
                          className="col-md-4"
                          onClick={() => setProductIndex(index)}
                        >
                          <div className="card-plan">
                            <input
                              type="radio"
                              name="nmplans"
                              id={"nmplan0" + index}
                              value="1"
                            />
                            <label htmlFor={"nmplan0" + index}>
                              <i className="fas fa-check"></i>
                              <h2>{x.name}</h2>
                              <h4>
                                ${x.price}
                                <span>/for {x.duration}</span>
                              </h4>
                            </label>
                          </div>
                        </div>
                      ))}

                      <div className="col-12 mt-5">
                        <button className="btn btn-secondary" type="button">
                          {producIndex != null &&
                          planList[producIndex].url.length > 20 ? (
                            <a href={planList[producIndex].url} target="_blank">
                              <span className="text-white">Checkout</span>
                            </a>
                          ) : (
                            "Checkout"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Plans;
